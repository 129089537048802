import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { _ } from 'Services/I18n'

import { globeGlyph } from 'Assets/Svg'
import { MapPinIcon } from 'Assets/Svg/General'

import {
  Location as LocationWrapper,
  LocationIcon,
  LocationText,
} from '../styles'

function Location({ locations, online }) {
  if (!locations.length) return null

  const multipleLocations = locations.length > 1

  const getLocationText = () => {
    const bucketName = multipleLocations
      ? 'Multiple locations'
      : `${get(locations, '0.suburb')}, ${get(locations, '0.state')}`

    return online ? _('common.onlineService') : bucketName
  }

  return (
    <LocationWrapper>
      <LocationIcon color="#52525B" glyph={online ? globeGlyph : MapPinIcon} />
      <LocationText>{getLocationText()}</LocationText>
    </LocationWrapper>
  )
}

Location.defaultProps = {
  online: false,
}

Location.propTypes = {
  locations: PropTypes.array.isRequired,
  online: PropTypes.bool,
}

export default Location
