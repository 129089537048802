import get from 'lodash/get'
import map from 'lodash/map'

import * as Utils from 'Services/Utils'

import { createReducer } from 'Store/utils'

import {
  LOAD_FEATURED_MERCHANTS,
  SEARCH_NEAR_MERCHANTS,
  SEARCH_MERCHANTS,
  SEARCH_SERVICES,
  LOAD_MERCHANT,
  LOAD_SERVICE,
  WIDGET_AUTHENTICATE,
  LOAD_CATEGORIES,
  LOAD_CATEGORY,
  LOAD_ALL_CATEGORIES,
  LOAD_CATEGORIES_TAGS,
  LOAD_FEATURED_CATEGORIES,
  SET_CATEGORY_VALUE,
  SET_SEARCH_VALUE,
  SET_SEARCH_DATE,
  SET_SEARCH_DATE_PICKER_OPEN_STATUS,
  LOAD_FEATURED_LOCATIONS,
  LOAD_SERVICES_AVAILABILITY,
  LOAD_SERVICES_NEXT_AVAILABLE_DATE,
  LOAD_SERVICE_SURROUNDING_DATES,
} from 'Store/Actions/marketplace'

const initialState = {
  featuredMerchants: [],
  isFeaturedMerchantsLoading: false,
  featuredMerchantsError: null,

  searchResults: {},
  isSearchLoading: false,
  searchError: null,

  merchant: null,
  isMerchantLoading: false,
  merchantError: null,
  ssrMerchantPreloaded: false,

  service: null,
  isLoadingService: true,
  serviceError: null,

  nearBucket: null,

  widgetToken: null,
  isWidgetTokenLoading: false,
  widgetTokenError: null,

  featuredCategories: [],
  isFeaturedCategoriesLoading: false,
  featuredCategoriesError: null,

  featuredLocations: [],
  isFeaturedLocationsLoading: false,
  featuredLocationsError: null,

  categoryValue: null,
  searchValue: null,
  searchDate: null,
  isSearchDatePickerOpen: false,

  categories: [],
  isLoadingCategories: false,
  categoriesError: null,

  category: {},
  isLoadingCategory: false,
  categoryError: null,
  ssrPreloadedCategory: false,

  allCategories: {},
  isLoadingAllCategories: false,
  allCategoriesError: null,
  ssrPreloadedAllCategories: false,

  categoriesTags: [],
  isLoadingCategoriesTags: false,
  categoriesTagsError: null,

  servicesAvailability: null,
  isLoadingServicesAvailability: false,
  servicesAvailabilityError: null,

  servicesNextAvailableDate: null,
  isLoadingServicesNextAvailableDate: false,
  servicesNextAvailableDateError: null,

  serviceSurroundingDates: [],
  isLoadingServiceSurroundingDates: false,
  serviceSurroundingDatesError: null,
}

const loadFeaturedMerchantsRequest = state =>
  state.merge({ isFeaturedMerchantsLoading: true })

const loadFeaturedMerchantsSuccess = (state, { data }) =>
  state.merge({
    featuredMerchants: data,
    isFeaturedMerchantsLoading: false,
    featuredMerchantsError: null,
  })

const loadFeaturedMerchantsFailure = (state, { error }) =>
  state.merge({
    isFeaturedMerchantsLoading: false,
    featuredMerchantsError: error,
  })

const searchRequest = state => state.merge({ isSearchLoading: true })

const searchSuccess = (state, { data }) =>
  state.merge({
    isSearchLoading: false,
    searchResults: data || {},
    searchError: null,
  })

const searchFailure = (state, { error }) =>
  state.merge({ isSearchLoading: false, searchError: error })

const loadMerchantRequest = state => state.merge({ isMerchantLoading: true })

const loadMerchantSuccess = (state, { data, ssr }) =>
  state.merge({
    isMerchantLoading: false,
    merchant: data,
    merchantError: null,
    ssrMerchantPreloaded: ssr,
  })

const loadMerchantFailure = (state, { error }) =>
  state.merge({ isMerchantLoading: false, merchantError: error })

const loadServiceRequest = state => state.merge({ isLoadingService: true })

const loadServiceSuccess = (state, { data }) =>
  state.merge({
    isLoadingService: false,
    service: data?.data,
    serviceError: null,
  })

const loadServiceFailure = (state, { error }) =>
  state.merge({ isLoadingService: false, serviceError: error })

const searchNearMerchantsRequest = state =>
  state.merge({ isSearchLoading: true })

const searchNearMerchantsSuccess = (state, { data }) =>
  state.merge({
    isSearchLoading: false,
    nearBucket: data,
    searchError: null,
  })

const searchNearMerchantsFailure = (state, { error }) =>
  state.merge({ isSearchLoading: false, searchError: error })

const widgetAuthenticateRequest = state =>
  state.merge({
    isWidgetTokenLoading: true,
  })

const widgetAuthenticateSuccess = (state, { data }) =>
  state.merge({
    widgetToken: get(data, 'widget_auth_token', null),
    isWidgetTokenLoading: false,
    widgetTokenError: null,
  })

const widgetAuthenticateFailure = (state, error) =>
  state.merge({
    isWidgetTokenLoading: false,
    widgetTokenError: error,
  })

const loadFeaturedCategoriesRequest = state =>
  state.merge({ isFeaturedCategoriesLoading: true })

const loadFeaturedCategoriesSuccess = (state, { data }) =>
  state.merge({
    featuredCategories: data,
    isFeaturedCategoriesLoading: false,
    featuredCategoriesError: null,
  })

const loadFeaturedCategoriesFailure = (state, { error }) =>
  state.merge({
    isFeaturedCategoriesLoading: false,
    featuredCategoriesError: error,
  })

const loadFeaturedLocationsRequest = state =>
  state.merge({ isFeaturedLocationsLoading: true })

const loadFeaturedLocationsSuccess = (state, { data }) =>
  state.merge({
    featuredLocations: data,
    isFeaturedLocationsLoading: false,
    featuredLocationsError: null,
  })

const loadFeaturedLocationsFailure = (state, { error }) =>
  state.merge({
    isFeaturedLocationsLoading: false,
    featuredLocationsError: error,
  })

const loadCategoriesRequest = state =>
  state.merge({ isLoadingCategories: true })

const loadCategoriesSuccess = (state, { data }) =>
  state.merge({
    categories: data,
    isLoadingCategories: false,
    categoriesError: null,
  })

const loadCategoriesFailure = (state, { error }) =>
  state.merge({
    isLoadingCategories: false,
    categoriesError: error,
  })

const loadCategoryRequest = (state, { ssr }) =>
  state.merge({ isLoadingCategory: true, ssrPreloadedCategory: ssr })

const loadCategorySuccess = (state, { data }) =>
  state.merge({
    category: data,
    isLoadingCategory: false,
    categoryError: null,
  })

const loadCategoryFailure = (state, { error }) =>
  state.merge({
    isLoadingCategory: false,
    categoryError: error,
  })

const loadAllCategoriesRequest = (state, { ssr }) =>
  state.merge({ isLoadingAllCategories: true, ssrPreloadedAllCategories: ssr })

const loadAllCategoriesSuccess = (state, { data }) =>
  state.merge({
    allCategories: data,
    isLoadingAllCategories: false,
    allCategoriesError: null,
  })

const loadAllCategoriesFailure = (state, { error }) =>
  state.merge({
    isLoadingAllCategories: false,
    allCategoriesError: error,
  })

const loadCategoriesTagsRequest = state =>
  state.merge({ isLoadingCategoriesTags: true })

const loadCategoriesTagsSuccess = (state, { data }) =>
  state.merge({
    categoriesTags: map(data, tag => ({
      ...tag,
      slug: Utils.slugify(tag.name),
    })),
    isLoadingCategoriesTags: false,
    categoriesTagsError: null,
  })

const loadCategoriesTagsFailure = (state, { error }) =>
  state.merge({
    isLoadingCategoriesTags: false,
    categoriesTagsError: error,
  })

const setCategoryValue = (state, { categoryValue }) =>
  state.merge({
    categoryValue,
  })

const setSearchValue = (state, { searchValue }) =>
  state.merge({
    searchValue,
  })

const setSearchDate = (state, { searchDate }) =>
  state.merge({
    searchDate,
    servicesAvailability: null,
  })

const setSearchDatePickerOpenStatus = (state, { isSearchDatePickerOpen }) =>
  state.merge({
    isSearchDatePickerOpen,
  })

const loadServicesAvailabilityRequest = state =>
  state.merge({ isLoadingServicesAvailability: true })

const loadServicesAvailabilitySuccess = (state, { data }) =>
  state.merge({
    servicesAvailability: { ...state.servicesAvailability, ...data },
    isLoadingServicesAvailability: false,
    servicesAvailabilityError: null,
  })

const loadServicesAvailabilityFailure = (state, { error }) =>
  state.merge({
    isLoadingServicesAvailability: false,
    servicesAvailabilityError: error,
  })

const loadServicesNextAvailableDateRequest = state =>
  state.merge({ isLoadingServicesNextAvailableDate: true })

const loadServicesNextAvailableDateSuccess = (state, { data }) =>
  state.merge({
    servicesNextAvailableDate: { ...state.servicesNextAvailableDate, ...data },
    isLoadingServicesNextAvailableDate: false,
    servicesNextAvailableDateError: null,
  })

const loadServicesNextAvailableDateFailure = (state, { error }) =>
  state.merge({
    isLoadingServicesNextAvailableDate: false,
    servicesNextAvailableDateError: error,
  })

const loadServiceSurroundingDatesRequest = state =>
  state.merge({ isLoadingServiceSurroundingDates: true })

const loadServiceSurroundingDatesSuccess = (state, { data }) =>
  state.merge({
    isLoadingServiceSurroundingDates: false,
    serviceSurroundingDates: data?.data,
    serviceSurroundingDatesError: null,
  })

const loadServiceSurroundingDatesFailure = (state, { error }) =>
  state.merge({
    isLoadingServiceSurroundingDates: false,
    serviceSurroundingDatesError: error,
  })

const handlers = {
  [LOAD_FEATURED_MERCHANTS.REQUEST]: loadFeaturedMerchantsRequest,
  [LOAD_FEATURED_MERCHANTS.SUCCESS]: loadFeaturedMerchantsSuccess,
  [LOAD_FEATURED_MERCHANTS.FAILURE]: loadFeaturedMerchantsFailure,

  [SEARCH_MERCHANTS.REQUEST]: searchRequest,
  [SEARCH_MERCHANTS.SUCCESS]: searchSuccess,
  [SEARCH_MERCHANTS.FAILURE]: searchFailure,

  [SEARCH_SERVICES.REQUEST]: searchRequest,
  [SEARCH_SERVICES.SUCCESS]: searchSuccess,
  [SEARCH_SERVICES.FAILURE]: searchFailure,

  [SEARCH_NEAR_MERCHANTS.REQUEST]: searchNearMerchantsRequest,
  [SEARCH_NEAR_MERCHANTS.SUCCESS]: searchNearMerchantsSuccess,
  [SEARCH_NEAR_MERCHANTS.FAILURE]: searchNearMerchantsFailure,

  [LOAD_MERCHANT.REQUEST]: loadMerchantRequest,
  [LOAD_MERCHANT.SUCCESS]: loadMerchantSuccess,
  [LOAD_MERCHANT.FAILURE]: loadMerchantFailure,

  [LOAD_SERVICE.REQUEST]: loadServiceRequest,
  [LOAD_SERVICE.SUCCESS]: loadServiceSuccess,
  [LOAD_SERVICE.FAILURE]: loadServiceFailure,

  [WIDGET_AUTHENTICATE.REQUEST]: widgetAuthenticateRequest,
  [WIDGET_AUTHENTICATE.SUCCESS]: widgetAuthenticateSuccess,
  [WIDGET_AUTHENTICATE.FAILURE]: widgetAuthenticateFailure,

  [LOAD_FEATURED_CATEGORIES.REQUEST]: loadFeaturedCategoriesRequest,
  [LOAD_FEATURED_CATEGORIES.SUCCESS]: loadFeaturedCategoriesSuccess,
  [LOAD_FEATURED_CATEGORIES.FAILURE]: loadFeaturedCategoriesFailure,

  [LOAD_FEATURED_LOCATIONS.REQUEST]: loadFeaturedLocationsRequest,
  [LOAD_FEATURED_LOCATIONS.SUCCESS]: loadFeaturedLocationsSuccess,
  [LOAD_FEATURED_LOCATIONS.FAILURE]: loadFeaturedLocationsFailure,

  [LOAD_CATEGORIES.REQUEST]: loadCategoriesRequest,
  [LOAD_CATEGORIES.SUCCESS]: loadCategoriesSuccess,
  [LOAD_CATEGORIES.FAILURE]: loadCategoriesFailure,

  [LOAD_CATEGORY.REQUEST]: loadCategoryRequest,
  [LOAD_CATEGORY.SUCCESS]: loadCategorySuccess,
  [LOAD_CATEGORY.FAILURE]: loadCategoryFailure,

  [LOAD_ALL_CATEGORIES.REQUEST]: loadAllCategoriesRequest,
  [LOAD_ALL_CATEGORIES.SUCCESS]: loadAllCategoriesSuccess,
  [LOAD_ALL_CATEGORIES.FAILURE]: loadAllCategoriesFailure,

  [LOAD_CATEGORIES_TAGS.REQUEST]: loadCategoriesTagsRequest,
  [LOAD_CATEGORIES_TAGS.SUCCESS]: loadCategoriesTagsSuccess,
  [LOAD_CATEGORIES_TAGS.FAILURE]: loadCategoriesTagsFailure,

  [SET_CATEGORY_VALUE]: setCategoryValue,
  [SET_SEARCH_VALUE]: setSearchValue,
  [SET_SEARCH_DATE]: setSearchDate,
  [SET_SEARCH_DATE_PICKER_OPEN_STATUS]: setSearchDatePickerOpenStatus,

  [LOAD_SERVICES_AVAILABILITY.REQUEST]: loadServicesAvailabilityRequest,
  [LOAD_SERVICES_AVAILABILITY.SUCCESS]: loadServicesAvailabilitySuccess,
  [LOAD_SERVICES_AVAILABILITY.FAILURE]: loadServicesAvailabilityFailure,

  [LOAD_SERVICES_NEXT_AVAILABLE_DATE.REQUEST]:
    loadServicesNextAvailableDateRequest,
  [LOAD_SERVICES_NEXT_AVAILABLE_DATE.SUCCESS]:
    loadServicesNextAvailableDateSuccess,
  [LOAD_SERVICES_NEXT_AVAILABLE_DATE.FAILURE]:
    loadServicesNextAvailableDateFailure,

  [LOAD_SERVICE_SURROUNDING_DATES.REQUEST]: loadServiceSurroundingDatesRequest,
  [LOAD_SERVICE_SURROUNDING_DATES.SUCCESS]: loadServiceSurroundingDatesSuccess,
  [LOAD_SERVICE_SURROUNDING_DATES.FAILURE]: loadServiceSurroundingDatesFailure,
}

export default createReducer(initialState, handlers)
