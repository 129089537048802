import React, { useMemo, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { forceCheck } from 'react-lazyload'

import map from 'lodash/map'
import get from 'lodash/get'
import findIndex from 'lodash/findIndex'

import { useBaseTheme } from 'Hooks'
import { SEARCH_TYPE, SEARCH_VIEW } from 'Constants/ids'

import { Box, Flex } from 'Components/UI'
import MerchantCard from '../MerchantCard'
import MerchantGridCard from '../MerchantGridCard'
import ServiceCard from '../ServiceCard'

import { Container, MobileSliderContainer } from './styles'
import SearchResultMap from '../SearchResultMap'

const SearchResult = ({
  display,
  results,
  searchView,
  withShowMap,
  ...rest
}) => {
  const [activeMapResult, setActiveMapResult] = useState(results[0])
  const mobileSliderRef = useRef()
  const resultsContainerRef = useRef()
  const { isLargeScreen, isMobileDevice } = useBaseTheme()

  const searchType = get(results, '0.token')
    ? SEARCH_TYPE.BUSINESSES
    : SEARCH_TYPE.SERVICES

  const compact = searchView === SEARCH_VIEW.GRID

  const showOnMap = searchView === SEARCH_VIEW.MAP && isLargeScreen

  const isMobileMapView = searchView === SEARCH_VIEW.MAP && !isLargeScreen

  useEffect(() => {
    // Reset the active map result when the results change
    setActiveMapResult(results[0])

    // Reset mobile slider to first slide
    if (isMobileMapView) {
      mobileSliderRef.current?.slickGoTo(0)
    }

    // Scroll to top of search results list container when on desktop map view
    if (showOnMap) {
      resultsContainerRef.current?.scrollTo({ top: 0 })
    }

    // Ensure any lazy loaded components in view are rendered
    forceCheck()
  }, [results])

  const getResultKey = result => {
    return searchType === SEARCH_TYPE.BUSINESSES
      ? result.token
      : result.service.id
  }

  const scrollSliderToResult = result => {
    const activeMarkerIndex =
      findIndex(results, item => {
        return searchType === SEARCH_TYPE.BUSINESSES
          ? item.token === result.token
          : item.service.id === result.service.id
      }) || 0

    mobileSliderRef.current?.slickGoTo(activeMarkerIndex)
  }

  const refs = results.reduce((acc, value) => {
    acc[getResultKey(value)] = React.createRef()
    return acc
  }, {})

  const handleChangeActiveMapResult = result => {
    setActiveMapResult(result)

    if (!isLargeScreen) {
      scrollSliderToResult(result)

      return
    }

    const ref = refs[getResultKey(result)]

    if (!ref || !ref.current) {
      return
    }

    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    })
  }

  const renderMerchants = showAsGrid =>
    searchView === SEARCH_VIEW.MAP || (showAsGrid && !isMobileDevice)
      ? map(results, result => (
          <MerchantGridCard
            className={
              showOnMap && activeMapResult?.token === result.token
                ? 'active-map-result'
                : ''
            }
            innerRef={refs[result.token]}
            key={result.token}
            landscape={isMobileMapView}
            merchant={result}
            scrollContainerClass={
              searchView === SEARCH_VIEW.MAP ? '.search-results-container' : ''
            }
            withShowMap={showOnMap}
            onShowOnMap={handleChangeActiveMapResult}
          />
        ))
      : map(results, result => (
          <MerchantCard
            ignoreRoundedBorder
            key={result.token}
            lazyLoad
            merchant={result}
            withLink
            withLogo
            withServices={get(result, 'services.length', 0) > 0}
          />
        ))

  const renderServices = showAsGrid =>
    map(results, result => (
      <ServiceCard
        className={
          showOnMap && activeMapResult?.service?.id === result.service.id
            ? 'active-map-result'
            : ''
        }
        compact={showAsGrid}
        innerRef={refs[result.service.id]}
        key={result.service.id}
        landscape={isMobileMapView && !isLargeScreen}
        scrollContainerClass={
          searchView === SEARCH_VIEW.MAP ? '.search-results-container' : ''
        }
        service={result}
        withShowMap={showOnMap}
        onShowOnMap={handleChangeActiveMapResult}
      />
    ))

  const renderMobileMapResults = () => {
    const settings = {
      centerMode: true,
      infinite: true,
      centerPadding: '15px',
      slidesToShow: 1,
      speed: 500,
      lazyLoad: 'ondemand',
      afterChange: current => {
        setActiveMapResult(results[current])
      },
    }

    const cards =
      searchType === SEARCH_TYPE.BUSINESSES
        ? renderMerchants(true)
        : renderServices(true)

    return (
      <MobileSliderContainer ref={mobileSliderRef} {...settings}>
        {map(results, (result, index) => (
          <Box key={getResultKey(result)} px="4px" py="2px">
            {cards[index]}
          </Box>
        ))}
      </MobileSliderContainer>
    )
  }

  const renderResults = useMemo(() => {
    if (!results?.length) {
      return null
    }

    return (
      <>
        {searchView === SEARCH_VIEW.MAP ? (
          <SearchResultMap
            activeMapResult={activeMapResult || results[0]}
            innerRef={resultsContainerRef}
            results={results}
            onActiveMarkerChange={handleChangeActiveMapResult}
            {...rest}
          >
            {isLargeScreen ? (
              <Container compact={1}>
                {searchType === SEARCH_TYPE.BUSINESSES
                  ? renderMerchants(true)
                  : renderServices(true)}
              </Container>
            ) : (
              renderMobileMapResults()
            )}
          </SearchResultMap>
        ) : (
          <Container
            className="search-results-container"
            compact={compact ? 1 : 0}
            {...rest}
          >
            {searchType === SEARCH_TYPE.BUSINESSES
              ? renderMerchants(compact)
              : renderServices(compact)}
          </Container>
        )}
      </>
    )
  }, [results, activeMapResult, searchView, isLargeScreen])

  return (
    <Flex display={display} mt={[0, 0, 0, 3]} mx={[0, 0, 0, 3]} width="100%">
      {renderResults}
    </Flex>
  )
}

SearchResult.defaultProps = {
  display: 'flex',
}

SearchResult.propTypes = {
  display: PropTypes.string,
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchView: PropTypes.string.isRequired,
  withShowMap: PropTypes.bool.isRequired,
}

export default SearchResult
