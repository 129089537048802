import styled from 'styled-components'
import { width } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { Box, Flex } from 'Components/UI'

export const Container = styled(Flex)`
  position: relative;
  .react-select {
    svg {
      fill: color: ${({ color }) =>
        color || themeGet('colors.tealBlue')} !important;
    }

    &__container {
      border: none;
      box-shadow: none;
      text-align: left;
      height: 40px;
      width: 100%;
    }

    &__control {
      border: none;
      box-shadow: none;
      text-align: left;
      height: 40px;
      display: flex;
      flex-wrap: nowrap;
    }

    &__placeholder {
      display: none;
    }
    &__placeholder:first-child {
      display: block;
      color: ${({ color }) => color || themeGet('colors.tealBlue')} !important;
      font-family: Inter, sans-serif;
      font-size: 15px;
      font-weight: 300 !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      opacity: 1 !important;
      position: sticky;
    }

    &__dropdown-indicator {
      color: ${({ color }) => color || themeGet('colors.tealBlue')} !important;
    }

    &__value-container {
      height: 100%;
      padding-left: 0;
      padding-right: 8px;
    }

    &__single-value {
      color: ${themeGet('colors.tealBlue')};
      font-weight: 300;
      margin-left: 0;
    }

    &__indicators {
      margin-right: 0;
      fill: ${({ color }) => color || themeGet('colors.tealBlue')} !important;
    }

    &__clear-indicator {
      padding: 0;
      > svg {
        fill: ${themeGet('colors.tealBlue')};
      }
    }
    &__dropdown-indicator {
      padding-left: 0;
      fill: ${({ color }) => color || themeGet('colors.tealBlue')} !important;
    }

    &__group {
      padding: 0;
    }
    &__group-heading {
      margin: 0;
    }
  }

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    .react-select {
      &__value-container {
        padding-left: 23px;
      }

      &__placeholder {
        font-size: 14px;
      }

      &__multi-value {
        font-size: 14px;
        width: auto !important;
      }

      &__indicators {
        font-size: 14px;
        margin-right: 10px;
      }

      &__indicator-separator {
        margin-left: 5px;
      }

      &__container,
      &__control {
        height: unset;
      }
    }
  }

  ${width};
`

export const DropdownContainer = styled(Box).attrs({ m: 0, p: 0 })`
  background-color: ${themeGet('colors.white')};
  border: none;
  border-radius: ${themeGet('borderRadius.1')};
  position: absolute;
  width: 270px;
  z-index: 3;

  .react-select__menu {
    border-radius: ${themeGet('borderRadius.1')} !important;
    width: auto;
  }
`

export const BadgeContent = styled(Box)`
  display: inline;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  line-height: normal;
  padding: 1px;
`

export const Badge = styled(Flex).attrs({
  ml: 2,
})`
  position: relative;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 900;
  height: 18px;
  width: 18px;
  color: white;
  flex: 0 0 18px;
  background-color: ${themeGet('colors.red')};
`

export const PlaceholderContainer = styled(Flex)``
