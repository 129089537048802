import get from 'lodash/get'
import moment from 'moment'

export const getRRP = service => {
  const rrp = get(service, 'recommended_retail_price')
  const priceMinimum = get(service, 'price_minimum')

  return rrp === priceMinimum ? null : (rrp || '').replace(/\.00$/, '')
}

export const isFreeService = service => {
  const basePrice = get(service, 'base_price', 0)
  const hasOptions = get(service, 'has_options')
  const hasPricingCategories = get(service, 'has_pricing_categories')
  const recommendedRetailPrice = getRRP(service)

  return (
    basePrice === 0 &&
    !(hasOptions || hasPricingCategories || recommendedRetailPrice)
  )
}

export const isPriceFromVisible = service => {
  const hasOptions = get(service, 'has_options')
  const hasPricingCategories = get(service, 'has_pricing_categories')

  return hasOptions || hasPricingCategories
}

export const getPrice = service => {
  const price = get(service, 'price_minimum')

  return (price || '').replace(/\.00$/, '')
}

export const getFormattedNextAvailableDate = (
  nextAvailableDate,
  startDate,
  endDate,
) => {
  if (!nextAvailableDate && !endDate) {
    return null
  }

  const format = 'ddd D MMM'

  // If there is no next available date, fall back to the start and end date
  // of the service, as long as an end date is present.
  if (!nextAvailableDate) {
    const start = moment(startDate).format(format)
    const end = moment(endDate).format(format)

    return `${start} - ${end}`
  }

  return moment(nextAvailableDate).format(format)
}
