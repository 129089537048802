import get from 'lodash/get'
import find from 'lodash/find'

export function getIsSubCategoryChecked(value, item) {
  return (
    get(value, `value.${item.bucket_type}.bucket_id`) === item.bucket_id &&
    get(value, `value.${item.bucket_type}.bucket_type`) === item.bucket_type
  )
}

export function getIsChecked(value, item) {
  const selectedBucketId =
    get(value, 'value.category_2.bucket_id') || get(value, 'value.bucket_id')
  const selectedBucketType =
    get(value, 'value.category_2.bucket_type') ||
    get(value, 'value.bucket_type')
  const bucketId =
    get(item, 'value.category_2.bucket_id') || get(item, 'value.bucket_id')
  const bucketType =
    get(item, 'value.category_2.bucket_type') || get(item, 'value.bucket_type')

  const subCategories = get(item, 'value.category_2', [])

  if (
    subCategories &&
    find(subCategories, subItem => getIsSubCategoryChecked(value, subItem))
  ) {
    return true
  }

  return selectedBucketId === bucketId && selectedBucketType === bucketType
}
