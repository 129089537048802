import styled from 'styled-components'

import { calendarGlyph } from 'Assets/Svg'

import { Icon } from 'Components/UI'

export default styled(Icon).attrs({
  glyph: calendarGlyph,
  size: 16,
})`
  vertical-align: unset;
`
