import { createSelector } from 'reselect'

export const getState = state => state.app

export const getIsStoreReady = createSelector(
  getState,
  state => state.storeReady,
)

export const getIsReady = createSelector(getState, state => state.ready)

export const getBaseTheme = createSelector(getState, state => state.baseTheme)

export const getAuthentication = createSelector(
  getState,
  state => state.authentication,
)

export const getBannerInfo = createSelector(getState, state => {
  const { banner } = state.baseTheme
  return {
    title: banner.copy,
    imageUrl: banner.banner_image_url,
  }
})

export const getGtmId = createSelector(getState, state => state.gtmId)

export const getClientName = createSelector(getState, state => state.clientName)

export const getLoadingClient = createSelector(
  getState,
  state => state.isLoadingClient,
)

export const getConfig = createSelector(getState, state => state.config)

export const getLocale = createSelector(
  getState,
  state => state.clientLanguageCode,
)
