import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useRouteMatch } from 'react-router'
import { withAppContext } from 'Services/Context'

import get from 'lodash/get'
import map from 'lodash/map'

import { PUBLIC_PATHS } from 'Constants/paths'

import SubCategory from './SubCategory'

import { Container, Grid } from './styles'

function SubCategories({ baseTheme, category }) {
  const match = useRouteMatch()
  const history = useHistory()

  const subCategories = get(category, 'sub_categories', [])
  const primaryColor = get(baseTheme, 'colors.primary_background')

  const handleCategory = useCallback(
    subCategory => () => {
      const primaryCategorySlug = get(match, 'params.primaryCategorySlug')
      const secondaryCategorySlug = get(subCategory, 'slug')

      history.push(
        PUBLIC_PATHS.SECONDARY_CATEGORY(
          primaryCategorySlug,
          secondaryCategorySlug,
        ),
      )
    },
    [category],
  )

  return (
    <Container>
      <Grid>
        {map(subCategories, subCategory => (
          <SubCategory
            category={subCategory}
            key={subCategory.id}
            primaryColor={primaryColor}
            onCategory={handleCategory}
          />
        ))}
      </Grid>
    </Container>
  )
}

SubCategories.defaultProps = {
  baseTheme: {},
  category: {},
}

SubCategories.propTypes = {
  baseTheme: PropTypes.object,
  category: PropTypes.object,
}

export default withAppContext(SubCategories)
