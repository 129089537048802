import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getCategory } from 'Store/Selectors/marketplace'

import SubCategories from './SubCategories'

const selector = createStructuredSelector({
  category: getCategory,
})

export default connect(selector, null)(SubCategories)
