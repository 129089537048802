import React from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'

const VideoPlayer = React.forwardRef(({ playing, url, ...rest }, ref) => {
  if (!url) {
    return null
  }

  const videoPlayerConfig = {
    youtube: {
      playerVars: {
        rel: 0,
      },
    },
  }

  return (
    <ReactPlayer
      config={videoPlayerConfig}
      controls
      height="100%"
      light
      pip={false}
      playing={playing}
      ref={ref}
      url={url}
      width="100%"
      {...rest}
    />
  )
})

VideoPlayer.defaultProps = {
  playing: true,
  url: null,
}

VideoPlayer.propTypes = {
  playing: PropTypes.bool,
  url: PropTypes.string,
}

export default VideoPlayer
