import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import { withAppContext } from 'Services/Context'

import { Container, Tip } from './styles'

class Tooltip extends React.Component {
  state = {
    hasOverflowingChildren: false,
    text: undefined,
  }

  handleUpdateOverflow = event => {
    const element = event.target
    const { hasOverflowingChildren, text } = this.state

    const canOverflow =
      !hasOverflowingChildren && element.scrollWidth > element.clientWidth + 1

    if (!canOverflow) {
      this.setState({ hasOverflowingChildren: false })
      return
    }

    this.setState({ hasOverflowingChildren: true })
    if (element.textContent !== text) {
      this.setState({ text: element.textContent })
    }
  }

  render() {
    const { hasOverflowingChildren, text } = this.state
    const { baseTheme, children } = this.props
    const roundedBorder = get(baseTheme, 'colors.rounded_border') ? 1 : 0

    return hasOverflowingChildren ? (
      <Container showtip="true">
        <Tip roundedborder={roundedBorder}>{text}</Tip>
        {children}
      </Container>
    ) : (
      <Container onMouseEnter={this.handleUpdateOverflow}>{children}</Container>
    )
  }
}

Tooltip.propTypes = {
  baseTheme: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default withAppContext(Tooltip)
