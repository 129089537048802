import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import omit from 'lodash/omit'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'

import Item from './Item'

import { Container, Pill, Title } from './styles'

class Pills extends PureComponent {
  input = null

  handleChangeIndex = index => {
    const { activeIndexes, onChangeIndex } = this.props
    if (!onChangeIndex) return

    if (activeIndexes.includes(index)) {
      const items = filter([...activeIndexes, index], i => i !== index)
      onChangeIndex(sortBy(items))
      return
    }

    onChangeIndex(sortBy([...activeIndexes, index]))
  }

  handleUnsetActiveIndex = index => () => {
    const { activeIndexes, onChangeIndex } = this.props
    if (activeIndexes.includes(index)) {
      const data = filter([...activeIndexes, index], i => i !== index)
      onChangeIndex(sortBy(data))
    }
  }

  renderItems = () => {
    const { bg, hoverBg, items, activeIndexes, width } = this.props

    return items.map((item, index) => {
      const key = `${item}-${index}`
      return (
        <Item
          active={activeIndexes.includes(index)}
          bg={bg}
          hoverBg={hoverBg}
          index={index}
          key={key}
          text={item}
          width={width}
          onChangeIndex={this.handleChangeIndex}
        />
      )
    })
  }

  render() {
    const { items, title, activeIndexes, width, hoverBg, ...rest } = this.props

    return (
      <Container
        items={items.length}
        title={title}
        {...omit(rest, ['onChangeIndex'])}
      >
        {title && <Title>{title}</Title>}
        {this.renderItems()}
      </Container>
    )
  }
}

Pills.displayName = 'Pills'

Pills.defaultProps = {
  activeIndexes: [],
  bg: '',
  hoverBg: '',
  title: '',
  onChangeIndex: null,
  width: 80,
}

Pills.propTypes = {
  activeIndexes: PropTypes.arrayOf(PropTypes.number),
  bg: PropTypes.string,
  hoverBg: PropTypes.string,
  items: PropTypes.array.isRequired,
  title: PropTypes.string,
  width: PropTypes.number,
  onChangeIndex: PropTypes.func,
}

Pills.Item = Pill

export default Pills
