import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import { withAppContext } from 'Services/Context'
import { _ } from 'Services/I18n'

import { Paginate, PaginateButton, NextIcon, PrevIcon } from './styles'

const MAX_LENGTH = 7
const BEFORE_CURRENT = 2
const AFTER_CURRENT = 2

class Pagination extends PureComponent {
  get lastPage() {
    const { total, perPage } = this.props
    return Math.ceil(total / perPage)
  }

  get nextDisabled() {
    const { current } = this.props
    return current === this.lastPage
  }

  get prevDisabled() {
    const { current } = this.props
    return current === 1
  }

  getPages() {
    const { baseTheme, current, maxLength, afterCurrent, beforeCurrent } =
      this.props

    const pages = []
    const currentPage = current

    if (currentPage <= maxLength - afterCurrent || this.lastPage <= maxLength) {
      const start = 1
      const end = this.lastPage <= maxLength ? this.lastPage : maxLength

      for (let i = start; i <= end; i += 1) {
        pages.push({
          title: i,
          value: i,
        })
      }
    } else {
      pages.push({
        title: 1,
        value: 1,
      })
      let start = currentPage - beforeCurrent
      let end = currentPage + afterCurrent

      if (currentPage > this.lastPage - (afterCurrent + beforeCurrent)) {
        start = this.lastPage - (afterCurrent + beforeCurrent)
        end = this.lastPage
      }

      for (let i = start; i <= end && maxLength !== 1; i += 1) {
        pages.push({
          title: i,
          value: i,
        })
      }

      if (maxLength !== 1) {
        pages.splice(1, 0, {
          title: '...',
          value: start - beforeCurrent,
        })
      }
    }

    const renderPages = []
    pages.forEach(item => {
      const isInRange = currentPage > item.value || currentPage < item.value
      if (isInRange && maxLength !== 1) {
        renderPages.push(
          <PaginateButton
            active={item.value === currentPage}
            color={get(baseTheme, 'colors.secondary_background')}
            key={`page-${item.value}`}
            onClick={this.handlePageChange(item.value)}
          >
            <span>{item.title}</span>
          </PaginateButton>,
        )
      } else {
        renderPages.push(
          <PaginateButton
            active
            color={get(baseTheme, 'colors.secondary_background')}
            key={`page-button-${item.value}`}
            width={200}
          >
            <span>
              {_('common.page')} {currentPage}/{this.lastPage}
            </span>
          </PaginateButton>,
        )
      }
    })

    return renderPages
  }

  handlePageChange = page => () => {
    const { onChange } = this.props
    onChange(page)
  }

  render() {
    const { baseTheme, current, className, ...rest } = this.props
    const roundedBorder = get(baseTheme, 'colors.rounded_border') ? 1 : 0

    return (
      <Paginate
        className={className}
        mt={rest.mt}
        roundedborder={roundedBorder}
      >
        <PaginateButton
          arrow={1}
          disabled={this.prevDisabled}
          onClick={this.handlePageChange(current - 1)}
        >
          <span>
            <PrevIcon fill={get(baseTheme, 'colors.secondary_background')} />
          </span>
        </PaginateButton>
        {this.getPages()}
        <PaginateButton
          arrow={1}
          disabled={this.nextDisabled}
          onClick={this.handlePageChange(current + 1)}
        >
          <span>
            <NextIcon fill={get(baseTheme, 'colors.secondary_background')} />
          </span>
        </PaginateButton>
      </Paginate>
    )
  }
}

Pagination.defaultProps = {
  afterCurrent: AFTER_CURRENT,
  beforeCurrent: BEFORE_CURRENT,
  className: '',
  maxLength: MAX_LENGTH,
}

Pagination.propTypes = {
  afterCurrent: PropTypes.number,
  baseTheme: PropTypes.object.isRequired,
  beforeCurrent: PropTypes.number,
  className: PropTypes.string,
  current: PropTypes.number.isRequired,
  maxLength: PropTypes.number,
  perPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default withAppContext(Pagination)
