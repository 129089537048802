import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getServicesAvailability } from 'Store/Selectors/marketplace'

import ServiceList from './ServiceList'

const selector = createStructuredSelector({
  servicesAvailability: getServicesAvailability,
})

const actions = {}

export default connect(selector, actions)(ServiceList)
