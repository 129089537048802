import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getCategory } from 'Store/Selectors/marketplace'

import CategoryDetail from './CategoryDetail'

const selector = createStructuredSelector({
  category: getCategory,
})

export default connect(selector, null)(CategoryDetail)
