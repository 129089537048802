import React, { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'

import map from 'lodash/map'
import size from 'lodash/size'
import noop from 'lodash/noop'
import slice from 'lodash/slice'

import { useBaseTheme } from 'Hooks'

import Pill from './Pill'

import { Container, PlusButton, Plus } from './styles'

function Pills({
  big,
  collapse,
  pills,
  selectable,
  selectedName,
  maxCollapsedCount,
  onActive,
  ...rest
}) {
  const [collapsed, setCollapsed] = useState(collapse)
  const { secondaryColor } = useBaseTheme()

  const handleShow = useCallback(() => setCollapsed(false), [collapsed])

  const handleActive = useCallback(pillName => () => onActive(pillName), [])

  const allPills = useMemo(() => {
    return collapsed ? slice(pills, 0, maxCollapsedCount) : pills
  }, [collapsed, pills])

  const renderPills = useMemo(
    () =>
      map(allPills, pill => {
        return (
          <Pill
            active={pill?.label === selectedName}
            big={big}
            key={pill?.label}
            label={pill?.label}
            pillcolor={secondaryColor}
            selectable={selectable}
            onClick={handleActive}
          />
        )
      }),
    [collapsed, pills],
  )

  const renderCountLeft = useMemo(() => {
    return size(pills) > maxCollapsedCount ? (
      <PlusButton
        pb="1px"
        pillcolor={secondaryColor}
        pt="3px"
        px="5px"
        onClick={handleShow}
      >
        <Plus>+</Plus>
      </PlusButton>
    ) : null
  }, [collapsed, pills])

  return (
    <Container {...rest}>
      {renderPills}
      {collapsed && renderCountLeft}
    </Container>
  )
}

Pills.defaultProps = {
  big: false,
  collapse: true,
  maxCollapsedCount: 5,
  pills: [],
  selectable: false,
  selectedName: null,
  onActive: noop,
}

Pills.propTypes = {
  big: PropTypes.bool,
  collapse: PropTypes.bool,
  maxCollapsedCount: PropTypes.number,
  pills: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  selectable: PropTypes.bool,
  selectedName: PropTypes.string,
  onActive: PropTypes.func,
}

export default Pills
