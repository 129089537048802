import styled, { css } from 'styled-components'
import { Flex, Icon } from 'Components/UI'

import { chevronDownGlyph, tagGlyph } from 'Assets/Svg'

export const Container = styled(Flex).attrs({
  flexDirection: 'column',
})``

export const Header = styled(Flex).attrs({})`
  gap: 12px;
  cursor: pointer;
  align-items: center;
  flex: 1;
`

export const Content = styled(Flex).attrs({})`
  font-size: 14px;

  ul {
    margin-block-start: 10px;
    margin-block-end: 10px;
  }
`

export const FilterLabel = styled(Flex).attrs({})`
  flex: 1;
  font-weight: 700;
  font-size: 14px;
`

export const TagIcon = styled(Icon).attrs({
  glyph: tagGlyph,
  size: 16,
})`
  color: #000;
`

const isOpenCss = props =>
  props.open &&
  css`
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  `

export const StatusIcon = styled(Icon).attrs({
  glyph: chevronDownGlyph,
  size: 14,
})`
  fill: #000;

  ${isOpenCss}
`
