import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { withAppContext } from 'Services/Context'

import get from 'lodash/get'

import { Container, Title, Description } from './styles'

function CategoryLineDescription({ baseTheme, category, isLoadingCategory }) {
  const data = useMemo(() => {
    const primaryTextColor = get(baseTheme, 'colors.primary_text', null)
    const primaryColor = get(baseTheme, 'colors.primary_background', null)
    const title = get(category, 'name')
    const description = get(category, 'short_description')

    return {
      primaryTextColor,
      primaryColor,
      title,
      description,
    }
  }, [isLoadingCategory])

  const { primaryTextColor, primaryColor, title, description } = data

  if (isLoadingCategory) {
    return <Container bg={primaryColor} />
  }

  return (
    <Container bg={primaryColor}>
      <Title color={primaryTextColor}>{title}</Title>
      {description && (
        <Description color={primaryTextColor}>{description}</Description>
      )}
    </Container>
  )
}

CategoryLineDescription.defaultProps = {
  baseTheme: {},
  category: null,
  isLoadingCategory: false,
}

CategoryLineDescription.propTypes = {
  baseTheme: PropTypes.object,
  category: PropTypes.object,
  isLoadingCategory: PropTypes.bool,
}

export default withAppContext(CategoryLineDescription)
