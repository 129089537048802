import React from 'react'
import PropTypes from 'prop-types'
import Interweave from 'interweave'
import { polyfill } from 'interweave-ssr'

import { Container, Title, Description } from './styles'

polyfill()

function CategorySection({
  title,
  description,
  primaryColor,
  secondaryColor,
  ...rest
}) {
  return (
    <Container {...rest}>
      <Title color={primaryColor}>{title}</Title>
      <Description linkcolor={secondaryColor}>
        <Interweave content={description} />
      </Description>
    </Container>
  )
}

CategorySection.defaultProps = {
  description: '',
  primaryColor: null,
  secondaryColor: null,
  title: '',
}

CategorySection.propTypes = {
  description: PropTypes.string,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  title: PropTypes.string,
}

export default CategorySection
