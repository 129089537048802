import get from 'lodash/get'

import { createReducer } from 'Store/utils'

import { STORE_READY, APP_READY, INITIALIZE_CONFIG } from 'Store/Actions/app'

import { LOAD_CLIENT_DETAILS } from 'Store/Actions/marketplace'

import { LANGUAGE } from 'Constants/ids'

const initialState = {
  storeReady: false,
  ready: false,
  gtmId: null,
  baseTheme: {},
  authentication: {},
  clientName: '',
  isLoadingClient: false,
  clientLanguageCode: LANGUAGE.EN,
}

const setStoreReady = state =>
  state.merge({
    storeReady: true,
  })

const setAppReady = state =>
  state.merge({
    ready: true,
  })

const setConfig = (state, { config }) =>
  state.merge({
    config,
  })

const processBaseThemeResponse = ({
  customisation,
  images,
  marketplace,
  // eslint-disable-next-line
  service_category,
  // eslint-disable-next-line
  custom_filters,
  // eslint-disable-next-line
  banner_tabs,
  // eslint-disable-next-line
  is_voucher_required,
  // eslint-disable-next-line
  support_email,
}) => ({
  colors: customisation,
  images,
  ...marketplace,
  service_category,
  custom_filters,
  banner_tabs,
  is_voucher_required,
  support_email,
})

const handlers = {
  [INITIALIZE_CONFIG]: setConfig,
  [STORE_READY]: setStoreReady,
  [APP_READY]: setAppReady,
  [LOAD_CLIENT_DETAILS.REQUEST]: state =>
    state.merge({ isLoadingClient: true }),
  [LOAD_CLIENT_DETAILS.FAILURE]: state =>
    state.merge({ isLoadingClient: false }),
  [LOAD_CLIENT_DETAILS.SUCCESS]: (state, { data }) => {
    return state.merge({
      gtmId: get(data, 'google_tag_manager_id', null),
      clientName: get(data, 'client_name', ''),
      authentication: get(data, 'authentication', {}),
      clientLanguageCode: get(data, 'client_language.code', LANGUAGE.EN),
      baseTheme: processBaseThemeResponse(data || {}),
      isLoadingClient: false,
    })
  },
}

export default createReducer(initialState, handlers)
