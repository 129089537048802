import React from 'react'
import styled from 'styled-components'
import {
  flex,
  flexBasis,
  flexDirection,
  flexWrap,
  justifyContent,
  alignItems,
  order,
  display,
} from 'styled-system'

import omit from 'lodash/omit'

import Box from '../Box'

const blacklist = [
  'justifyContent',
  'flexBasis',
  'flexDirection',
  'flexWrap',
  'flexGrow',
  'alignItems',
  'zIndex',
  'minWidth',
  'maxWidth',
  'fontSize',
  'active',
  'isNextType',
]

const Flex = styled(
  React.forwardRef((props, ref) => (
    <Box ref={ref} {...omit(props, blacklist)} />
  )),
).attrs({
  blacklist,
})`
  display: flex;

  ${flex}
  ${flexBasis}
  ${flexDirection}
  ${flexWrap}
  ${justifyContent}
  ${alignItems}
  ${order}
  ${display}
`

Flex.displayName = 'Flex'

export default Flex
