import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import { withAppContext } from 'Services/Context'

import get from 'lodash/get'
import map from 'lodash/map'
import size from 'lodash/size'
import first from 'lodash/first'
import find from 'lodash/find'

import * as Utils from 'Services/Utils'

import { PUBLIC_PATHS } from 'Constants/paths'

import CategorySection from './CategorySection'

import { Container, Content, Grid, Title, NoResult } from './styles'

function MatchCategories({ baseTheme, categories, categoriesTags }) {
  const history = useHistory()

  const tagSlug = Utils.getTagSlugFromLocation(history.location)
  const categoryTag = useMemo(
    () => find(categoriesTags, ['slug', first(tagSlug)]),
    [tagSlug],
  )

  const primaryFont = get(baseTheme, 'colors.primary_font')
  const primaryColor = get(baseTheme, 'colors.primary_background')
  const secondaryColor = get(baseTheme, 'colors.secondary_background')
  const roundedBorder = get(baseTheme, 'colors.rounded_border')

  const handleSecondaryCategory = useCallback(
    ({ primaryCategory, secondaryCategory }) =>
      () => {
        const primaryCategorySlug = primaryCategory.bucket_slug
        const secondaryCategorySlug = secondaryCategory.bucket_slug

        history.push(
          PUBLIC_PATHS.CATEGORY({ primaryCategorySlug, secondaryCategorySlug }),
        )
      },
    [categories],
  )

  const renderMatchCategories = useMemo(() => {
    const result = map(categories, primaryCategory => {
      const secondaryCategories = get(primaryCategory, 'category_2', [])

      return map(secondaryCategories, secondaryCategory => (
        <CategorySection
          bc={secondaryColor}
          description={get(secondaryCategory, 'short_description')}
          key={get(secondaryCategory, 'bucket_id')}
          primaryColor={primaryColor}
          roundedBorder={roundedBorder}
          secondaryColor={secondaryColor}
          title={get(secondaryCategory, 'bucket_name')}
          onCategory={handleSecondaryCategory({
            primaryCategory,
            secondaryCategory,
          })}
        />
      ))
    })

    if (size(result)) {
      return <Grid>{result}</Grid>
    }

    return <NoResult>No result found.</NoResult>
  }, [categories])

  if (!categories) {
    return null
  }

  return (
    <Container>
      <Title color={primaryColor} font={primaryFont}>
        Matching categories {categoryTag && `for "${categoryTag.name}"`}
      </Title>

      <Content>{renderMatchCategories}</Content>
    </Container>
  )
}

MatchCategories.defaultProps = {
  baseTheme: {},
  categories: [],
  categoriesTags: [],
}

MatchCategories.propTypes = {
  baseTheme: PropTypes.object,
  categories: PropTypes.array,
  categoriesTags: PropTypes.array,
}

export default withAppContext(MatchCategories)
