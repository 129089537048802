import React, { useState } from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import { useBaseTheme } from 'Hooks'

import { Loader } from 'Components/UI'

import { WidgetModal, LoaderHolder } from './styles'

const BookingWidgetModal = ({ url, onClose }) => {
  const { secondaryColor } = useBaseTheme()
  const [iFrameLoading, setIFrameLoading] = useState(true)

  return (
    <WidgetModal isLoaded={!iFrameLoading} isOpen onCallback={onClose}>
      {iFrameLoading && (
        <LoaderHolder>
          <Loader color={secondaryColor} />
        </LoaderHolder>
      )}

      <iframe
        frameBorder="0"
        src={url}
        title="Booking widget"
        onLoad={() => setIFrameLoading(false)}
      />
    </WidgetModal>
  )
}

BookingWidgetModal.defaultProps = {
  onClose: noop,
}

BookingWidgetModal.propTypes = {
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}

export default BookingWidgetModal
