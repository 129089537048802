import { createReducer } from 'Store/utils'

import { LOAD_WISHLIST, SET_WISHLIST_COUNT } from 'Store/Actions/wishlist'

const initialState = {
  wishlist: {
    merchants: [],
    services: [],
  },
  count: 0,
  isLoading: false,
  error: null,
}

const handleLoadWishlistRequest = state =>
  state.merge({ isLoading: true, error: null })

const handleLoadWishlistSuccess = (state, { data }) =>
  state.merge({ isLoading: false, wishlist: data })

const handleLoadWishlistFailure = state =>
  state.merge({ isLoading: false, error: null })

const setWishlistCount = (state, { count }) =>
  state.merge({
    count,
  })

const handlers = {
  [LOAD_WISHLIST.REQUEST]: handleLoadWishlistRequest,
  [LOAD_WISHLIST.SUCCESS]: handleLoadWishlistSuccess,
  [LOAD_WISHLIST.FAILURE]: handleLoadWishlistFailure,

  [SET_WISHLIST_COUNT]: setWishlistCount,
}

export default createReducer(initialState, handlers)
