import { darken, lighten } from 'polished'

const palette = {
  tealBlue: '#00a3ad',
  goldenrod: '#fdcc08',
  darkishPurple: '#6d1668',
  mineShaft: '#323232',
  boulder: '#777777',
  gray: '#868788',
  pearl: '#FAFAFA',
  lightGrayBlue: '#acafb2',
  alto: '#d9d9d9',
  gallery: '#ebebeb',
  seashell: '#f1f1f1',
  black: '#000000',
  white: '#ffffff',
  salmonPink: '#ff6e7e',
  deepSeaBlue: '#006280',
  lightGrey: '#D4D4D8',
  porcelain: '#f1f2f3',
  mercury: '#e6e6e6',
  concrete: '#f3f3f3',
  frenchGray: '#c3c0c4',
  mountainMist: '#9c9b9f',
  blackOpacity: 'rgba(0, 0, 0, 0.5)',
  wildSand: '#f6f6f6',
  lapalma: '#31a510',
  red: '#df2130',
}

const colors = {
  clear: 'rgba(255, 255, 255, 0)',
  clearFromBlack: 'rgba(0, 0, 0, 0)',

  bg: palette.pearl,

  text: '#52525B',

  header: '#18181B',

  // Colors
  primary: palette.tealBlue,
  secondary: palette.goldenrod,
  accent: palette.deepSeaBlue,

  danger: palette.salmonPink,
  success: palette.deepSeaBlue,

  ...palette,
}

colors.whiteHover = darken(0.05, colors.white)
colors.whiteActive = darken(0.1, colors.white)

colors.primaryHover = ''
colors.primaryActive = darken(0.1, colors.primary)

// Fonts
colors.font = {
  primary: colors.black,
  secondary: palette.black,
  light: palette.gray,
  link: palette.deepSeaBlue,
  disabled: darken(0.1, palette.deepSeaBlue),
}

// Inputs
colors.input = {
  font: {
    color: palette.black,
    placeholder: palette.gray,
  },
  bg: {
    color: palette.white,
    active: palette.white,
    disabled: palette.lightGray,
    error: palette.white,
  },
  border: {
    color: palette.lightGray,
    active: colors.primary,
    disabled: palette.lightGray,
    error: palette.salmonPink,
  },
  select: {
    active: colors.primary,
    hover: lighten(0.8, colors.primary),
  },
}

colors.gradients = {
  white: {
    toLeft: `linear-gradient(to left, ${colors.white}, ${colors.clear})`,
    toRight: `linear-gradient(to right, ${colors.white}, ${colors.clear})`,
    toTop: `linear-gradient(to top, ${colors.white}, ${colors.clear})`,
    toBottom: `linear-gradient(to bottom, ${colors.white}, ${colors.clear})`,
  },
}

export default colors
