import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'

import map from 'lodash/map'
import size from 'lodash/size'
import slice from 'lodash/slice'
import noop from 'lodash/noop'

import { useBaseTheme } from 'Hooks'

import { Container, Category, Label, Count } from './styles'

function PromotionCategories({ categories, onSelect, ...rest }) {
  if (!categories.length) return null

  const widths = ['100%', '50%']

  const { primaryColor, secondaryColor } = useBaseTheme()

  const handleSelect = useCallback(() => {
    onSelect()
  }, [])

  const renderCategories = useMemo(
    () =>
      map(slice(categories, 0, 2), (category, index) => {
        const categoryColor = category.supplementary
          ? primaryColor
          : secondaryColor

        return (
          <Category
            color={categoryColor}
            key={index}
            maxWidth={
              size(categories) < 3 ? widths[size(categories) - 1] : '45%'
            }
            onClick={handleSelect}
          >
            <Label>{category.name}</Label>
          </Category>
        )
      }),
    [categories],
  )

  const renderCountLeft = useMemo(() => {
    const count = size(categories) - 2
    return count > 0 ? (
      <Count color={primaryColor} onClick={handleSelect}>
        +{count}
      </Count>
    ) : null
  }, [categories])

  return (
    <Container alignItems="center" {...rest}>
      {renderCategories}
      {renderCountLeft}
    </Container>
  )
}

PromotionCategories.defaultProps = {
  categories: [],
  onSelect: noop,
}

PromotionCategories.propTypes = {
  categories: PropTypes.array,
  onSelect: PropTypes.func,
}

export default PromotionCategories
