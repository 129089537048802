import React from 'react'
import PropTypes from 'prop-types'

import { Container } from './styles'

const Dot = ({ color, size, ...rest }) => (
  <Container color={color} size={size} {...rest} />
)

Dot.defaultProps = {
  color: 'boulder',
  size: 6,
}

Dot.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

export default Dot
