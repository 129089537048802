import i18n from 'i18n-js'

import { LANGUAGE } from 'Constants/ids'

import localeEn from './translations/en.json'
import localeFr from './translations/fr.json'

const validLanguages = [LANGUAGE.EN, LANGUAGE.FR]

const translationsSet = { en: localeEn, fr: localeFr }

function initTranslations({ locale }) {
  let lang

  if (locale) {
    lang = locale
  } else {
    lang = window.navigator.language || window.navigator.userLanguage
    lang = lang ? lang.substring(0, 2) : ''
  }

  if (!validLanguages.includes(lang)) {
    lang = LANGUAGE.EN
  }

  i18n.locale = lang

  i18n.translations = translationsSet
  i18n.missingTranslation = key => `![${key}]`

  return i18n.locale
}

function _(key, options) {
  return i18n.t(key, options)
}

function I18n({ t, options }) {
  return _(t, { ...options })
}

export { initTranslations, _, validLanguages }

export default I18n
