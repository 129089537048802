import get from 'lodash/get'

import { all, takeLatest, put } from 'redux-saga/effects'

import { LOAD_CLIENT_DETAILS } from 'Store/Actions/marketplace'
import { loadLocations } from 'Store/Actions/locations'

function* watchLoadClient({ data }) {
  const canShowLocations = get(data, 'marketplace.show_locations_popover')

  if (canShowLocations) {
    yield put(loadLocations())
  }
}

export default function* locations() {
  yield all([takeLatest(LOAD_CLIENT_DETAILS.SUCCESS, watchLoadClient)])
}
