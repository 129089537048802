import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { searchGlyph } from 'Assets/Svg'

import { Flex, Button, Icon } from 'Components/UI'

export const SearchContainer = styled(Flex).attrs(() => ({
  flexDirection: 'row',
  alignItems: 'end',
}))`
  width: 100%;
  max-width: ${({ singleinput }) => (singleinput ? '920px' : '1000px')};
`

const buttonBorderRadiusCss = ({ borderradius }) => css`
  border-radius: ${borderradius
    ? themeGet('borderRadius.2')
    : themeGet('borderRadius.1')};
`

export const InputContainer = styled(Flex).attrs(() => ({
  width: 1,
  flexDirection: 'row',
  mr: '12px',
}))`
  position: relative;
`

export const FindButton = styled(Button).attrs(() => ({
  mt: 0,
  width: [40, 40, 55],
  height: [40, 40, 55],
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.bgcolor};
  border-width: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  color: ${props => props.textcolor};
  text-transform: uppercase;
  position: relative;
  opacity: 1 !important;
  font-size: 18px;
  &:hover {
    > svg {
      fill: white;
    }
  }
  svg {
    fill: ${props => props.textcolor};
    position: relative;
    top: unset;
    left: unset;
  }
  ${buttonBorderRadiusCss};
`

const searchIconCss = ({ withlabel }) =>
  withlabel &&
  css`
    display: block !important;
  `
export const SearchIcon = styled(Icon).attrs({
  glyph: searchGlyph,
  size: 16,
  display: 'block',
})`
  fill: ${themeGet('colors.black')};
  @media screen and (min-width: ${themeGet('breakpoints.1')}) {
    height: 20px;
    width: 20px;
  }
  ${searchIconCss};
`
