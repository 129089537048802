import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { setCategoryValue } from 'Store/Actions/marketplace'

import { getCategoryValue } from 'Store/Selectors/marketplace'

import FilterCategories from './FilterCategories'

const selector = createStructuredSelector({
  categoryValue: getCategoryValue,
})

const actions = {
  onSetCategoryValue: setCategoryValue,
}

export default connect(selector, actions)(FilterCategories)
