import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import { withAppContext } from 'Services/Context'

import { Container, Content, StyledButton, Title, LinkExternal } from './styles'

class CookiesPolicy extends PureComponent {
  handleAccept = () => {
    const { onAcceptCookiePolicy } = this.props
    onAcceptCookiePolicy()
  }

  render() {
    const { baseTheme } = this.props

    const primaryBackground = get(baseTheme, 'colors.primary_background')
    const primaryColor = get(baseTheme, 'colors.primary_text')

    const privacyPolicyUrl = get(
      baseTheme,
      'footer.privacy_url',
      'https://www.nabooki.com/privacy-policy/',
    )

    return (
      <Container background={primaryBackground}>
        <Content>
          <StyledButton
            background={primaryBackground}
            color={primaryColor}
            onClick={this.handleAccept}
          >
            OK
          </StyledButton>
          <Title color={primaryColor}>
            Our website uses cookies to enhance your experience and to show you
            more relevant content and adverts when browsing the web. By
            continuing to use our website, you consent to cookies being used.
            See our
            <LinkExternal color={primaryColor} href={privacyPolicyUrl} mx={1}>
              Privacy Policy
            </LinkExternal>
            to find out more.
          </Title>
        </Content>
      </Container>
    )
  }
}

CookiesPolicy.propTypes = {
  baseTheme: PropTypes.object.isRequired,
  onAcceptCookiePolicy: PropTypes.func.isRequired,
}

export default withAppContext(CookiesPolicy)
