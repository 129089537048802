import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import thunkMiddleware from 'redux-thunk'

import { createBrowserHistory, createMemoryHistory } from 'history'

import mapValues from 'lodash/mapValues'

import CookiePersist from 'Services/CookiePersist'
import WishlistPersist from 'Services/WishlistPersist'

import { storeReady } from 'Store/Actions/app'
import createRootReducer from 'Store/Reducers'
import rootSaga from 'Store/Sagas'
import { transformToImmutable } from 'Store/utils'

import { IS_PRODUCTION } from 'Config/app'

const restoreInitialImmutable = state => mapValues(state, transformToImmutable)

export default ({
  initialState = {},
  isSSR = false,
  path = '/',
  cookies = null,
}) => {
  const history = isSSR
    ? createMemoryHistory({ initialEntries: [path] })
    : createBrowserHistory()

  const middleware = [thunkMiddleware, routerMiddleware(history)]

  const sagaMiddleware = createSagaMiddleware()
  middleware.push(sagaMiddleware)

  const cookiePersist = new CookiePersist(cookies)
  const persist = cookiePersist.get()

  let enhancer = null

  // eslint-disable-next-line no-underscore-dangle
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (!IS_PRODUCTION) {
    enhancer = compose(
      applyMiddleware(...middleware),
      devToolsExtension ? devToolsExtension() : a => a,
    )
  } else if (devToolsExtension && window.location.hash === '#magic.exe') {
    enhancer = compose(
      applyMiddleware(...middleware),
      devToolsExtension ? devToolsExtension() : a => a,
    )
  } else {
    enhancer = applyMiddleware(...middleware)
  }

  const wishlistPersist = new WishlistPersist()
  const wishlist = {
    wishlist: {
      merchants: [],
      services: [],
    },
    count: wishlistPersist.count(),
    isLoading: false,
    error: null,
  }

  const store = createStore(
    createRootReducer(history),
    restoreInitialImmutable({ ...initialState, persist, wishlist }),
    enhancer,
  )

  if (!isSSR) sagaMiddleware.run(rootSaga, isSSR)

  store.dispatch(storeReady())

  if (module.hot) {
    module.hot.accept('./Reducers', () =>
      store.replaceReducer(createRootReducer(history)),
    )
  }

  return { store, history }
}
