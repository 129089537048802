import styled from 'styled-components'

import { Flex, Box } from 'Components/UI'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled(Flex).attrs({
  width: 1,
  justifyContent: 'center',
})`
  height: fit-content;
`

export const Content = styled(Box).attrs({
  width: 1,
  p: 24,
})`
  height: fit-content;
  border: 1px solid ${themeGet('colors.lightGrey')};
  border-radius: ${themeGet('borderRadius.1')};
  background-color: ${themeGet('colors.white')};
`
