import get from 'lodash/get'

const { REACT_APP_CI, REACT_APP_CI_BRANCH, REACT_APP_CI_BUILD } = process.env

if (REACT_APP_CI_BUILD) {
  const styles = [
    'width: 100%',
    'background: #060e41',
    'border-radius: 8px',
    'padding: 12px 16px',
    'color: white',
    'display: block',
    'text-align: center',
    'font-weight: bold',
  ].join(';')

  // eslint-disable-next-line no-console
  console.log(`%cBuild number: ${REACT_APP_CI_BUILD}`, styles)
}

const SERVER = 'APP_'
const REACT = 'REACT_APP_'

const GET_PREFIX = type =>
  REACT_APP_CI === 'true'
    ? {
        development: `${type}DEVELOPMENT_`,
        master: `${type}STAGING_`,
        production: `${type}PRODUCTION_`,
      }[REACT_APP_CI_BRANCH] || type
    : type

const REACT_PREFIX = GET_PREFIX(REACT)
const SERVER_PREFIX = GET_PREFIX(SERVER)

export const getReactEnv = (env, defaultValue = '') =>
  get(process.env, `${REACT_PREFIX}${env}`, defaultValue)

export const getServerEnv = (env, defaultValue = '') =>
  get(process.env, `${SERVER_PREFIX}${env}`, defaultValue)
