import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { space, flexbox, layout } from 'styled-system'

export const Container = styled.span`
  display: inline-block;
  background-color: ${props =>
    props.color ? themeGet(`colors.${props.color}`) : 'gray'};
  border-radius: 9999px;
  flex: 0 0 auto;

  ${layout};
  ${space};
  ${flexbox};
`

export default {}
