import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Icon, Text, Flex, Box } from 'Components/UI'

import { longArrowRightGlyph } from 'Assets/Svg'
import { CARD_IMAGE_HEIGHT } from 'Theme/constants'

export const Container = styled(Flex).attrs(({ landscape }) => ({
  flexDirection: landscape ? 'row' : 'column',
  justifyContent: 'flex-start',
  p: landscape ? 0 : '12px',
}))`
  gap: 10px;
  cursor: pointer;
  background-color: ${themeGet('colors.white')};
  border: 1px solid ${themeGet('colors.lightGrey')};
  width: 100%;
  min-width: 0;

  border-radius: ${themeGet('borderRadius.1')};

  transform: none;
  transition:
    all 0.2s ease 0s,
    -webkit-transform 0.2s ease 0s;

  @media (hover: hover) {
    &:hover {
      transform: ${({ landscape }) => (landscape ? 'none' : 'scale(1.02)')};
      box-shadow: ${({ landscape }) =>
        landscape ? 'none' : 'rgba(0, 0, 0, 0.15) 0px 2px 9px 2px'};
    }
  }
`

export const ImageHolder = styled(Box).attrs(({ landscape }) => ({
  height: landscape ? '107px' : 'unset',
}))`
  min-width: 215px;
  min-height: ${({ landscape }) =>
    landscape ? 'unset' : CARD_IMAGE_HEIGHT.LIST};
  position: relative;

  @media screen and (max-width: ${themeGet('breakpoints.2')}) {
    min-width: ${({ landscape }) => (landscape ? '125px' : '215px')};
    width: ${({ landscape }) => (landscape ? '125px' : '100%')};
  }
`

export const Image = styled(Box).attrs(({ src }) => ({
  is: 'img',
  src: src || 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=',
}))`
  width: 100%;
  height: ${({ landscape }) =>
    landscape ? CARD_IMAGE_HEIGHT.LANDSCAPE : CARD_IMAGE_HEIGHT.GRID};
  object-fit: cover;
  object-position: center;

  border-radius: ${({ landscape }) => (landscape ? `4px 0 0 4px` : '4px')};
`

export const Content = styled(Flex).attrs({
  flexDirection: 'column',
})`
  width: 100%;
  min-height: 100px;
  gap: 8px;
  padding: ${({ landscape }) => (landscape ? '6px 6px 8px 0' : '0')};
  overflow: hidden;
`

export const Title = styled(Text).attrs({})`
  font-size: ${({ landscape }) => (landscape ? '14px' : '16px')};
  font-weight: bold;
  line-height: ${({ landscape }) => (landscape ? '16px' : '1.25')};
  letter-spacing: 0.6px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${({ landscape }) => (landscape ? '1' : '2')};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  width: 100%;
  color: ${themeGet('colors.header')};
`

export const Location = styled(Flex).attrs({
  justifyContent: 'flex-start',
  alignItems: 'center',
})`
  flex-wrap: nowrap;
  white-space: nowrap;
`

export const Overlay = styled(Flex).attrs({
  justifyContent: 'center',
  alignItems: 'center',
  display: ['none', 'none', 'flex'],
  id: 'overlayShowOnMap',
})`
  background-color: ${themeGet('colors.blackOpacity')};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  height: 26px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`

export const ArrowIcon = styled(Icon).attrs({
  glyph: longArrowRightGlyph,
  size: 16,
  ml: 2,
})`
  fill: ${themeGet('colors.white')};
`

export const Show = styled(Box)`
  color: ${themeGet('colors.white')};
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
`
