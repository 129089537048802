import React from 'react'
import PropTypes from 'prop-types'

import noImage from './noImage.png'

import { Wrapper, ImageHolder } from './styles'

const Avatar = ({ image, team, advisor, ...rest }) => (
  <Wrapper advisor={advisor ? 1 : 0} team={team ? 1 : 0} {...rest}>
    <ImageHolder bg={image || noImage} {...rest} />
  </Wrapper>
)

Avatar.defaultProps = {
  advisor: false,
  image: null,
  team: false,
}

Avatar.propTypes = {
  advisor: PropTypes.bool,
  image: PropTypes.string,
  team: PropTypes.bool,
}

Avatar.displayName = 'Avatar'

export default Avatar
