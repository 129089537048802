import { useMemo } from 'react'

import get from 'lodash/get'

import useAppContext from './useAppContext'

export default function useBaseTheme() {
  const { baseTheme, breakpoint, theme } = useAppContext()

  const colors = get(baseTheme, 'colors')
  const images = get(baseTheme, 'images.logo_url')
  const labels = get(baseTheme, 'labels')

  return useMemo(() => {
    const primaryColor = get(baseTheme, 'colors.primary_background')
    const primaryTextColor = get(baseTheme, 'colors.primary_text')
    const secondaryColor = get(baseTheme, 'colors.secondary_background')
    const secondaryTextColor = get(baseTheme, 'colors.secondary_text')
    const roundedBorder = get(baseTheme, 'colors.rounded_border') ? 1 : 0
    const marketplaceType = get(baseTheme, 'marketplace_type', 'restaurant')
    const marketplaceServiceType = get(baseTheme, 'marketplace_service_type')
    const showLocationsPopover = get(baseTheme, 'show_locations_popover')
    const header = get(baseTheme, 'header', [])
    const headerPrimaryColor = get(baseTheme, 'colors.header_text', '')
    const headerBackground = get(baseTheme, 'colors.header_background')
    const logoUrl = get(baseTheme, 'images.logo_url')
    const breakpointNames = get(theme, 'breakpointNames')
    const isPortableDevice =
      breakpoint === breakpointNames.MOBILE ||
      breakpoint === breakpointNames.TABLET
    const isMobileDevice = breakpoint === breakpointNames.MOBILE
    const isLargeScreen = breakpoint === breakpointNames.LARGE
    const bookingPanelTitle = get(baseTheme, 'booking_panel_title')
    const bookingPanelCopy = get(baseTheme, 'booking_panel_copy')
    const showAvailability = get(baseTheme, 'show_availability', true)
    const isVoucherRequired = get(baseTheme, 'is_voucher_required', false)
    const isSearchByServiceEnabled = get(baseTheme, 'search_by_service', false)
    const serviceCategory = get(baseTheme, 'service_category')
    const merchantPageLayout = get(baseTheme, 'merchant_page_layout', 0)
    const showReviews = get(baseTheme, 'show_reviews', false)
    const websitePopupMessage = get(baseTheme, 'website_popup_msg', '')
    const supportEmail = get(baseTheme, 'support_email', 'support@nabooki.com')

    return {
      primaryColor,
      primaryTextColor,
      secondaryColor,
      secondaryTextColor,
      roundedBorder,
      marketplaceType,
      marketplaceServiceType,
      showLocationsPopover,
      header,
      headerPrimaryColor,
      headerBackground,
      logoUrl,
      breakpoint,
      isPortableDevice,
      isLargeScreen,
      bookingPanelTitle,
      bookingPanelCopy,
      labels,
      showAvailability,
      isVoucherRequired,
      isSearchByServiceEnabled,
      serviceCategory,
      merchantPageLayout,
      showReviews,
      isMobileDevice,
      websitePopupMessage,
      supportEmail,
    }
  }, [baseTheme, breakpoint, colors, images])
}
