import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getCategory, getLoadingCategory } from 'Store/Selectors/marketplace'

import CategoryLineDescription from './CategoryLineDescription'

const selector = createStructuredSelector({
  category: getCategory,
  isLoadingCategory: getLoadingCategory,
})

const actions = null

export default connect(selector, actions)(CategoryLineDescription)
