import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { searchGlyph } from 'Assets/Svg'

import { Flex, Box, Button, NavLink, Icon } from 'Components/UI'

export const SearchContainer = styled(Flex).attrs(({ singleinput }) => ({
  flexDirection: singleinput ? 'row' : ['column', 'column', 'row'],
  alignItems: singleinput ? 'end' : ['center', 'center', 'space-between'],
}))`
  width: 100%;
  max-width: ${({ singleinput }) => (singleinput ? '920px' : '1000px')};
`

const inputBorderRadiusCss = ({ borderradius }) =>
  borderradius
    ? css`
        border-radius: ${themeGet('borderRadius.2')};
      `
    : `
    border-radius: ${themeGet('borderRadius.1')} !important;
  `
const buttonBorderRadiusCss = ({ borderradius }) => css`
  border-radius: ${borderradius
    ? themeGet('borderRadius.2')
    : themeGet('borderRadius.1')};
`

export const InputContainer = styled(Flex).attrs(() => ({
  width: 1,
  flexDirection: ['column', 'column', 'row'],
  mr: [0, 0, '16px'],
}))`
  position: relative;

  .react-select__control {
    height: 55px !important;

    ${inputBorderRadiusCss};
  }

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    margin-right: ${props => (props.singleinput ? '16px' : 0)};

    .react-select__control,
    .react-select__value-container,
    .react-select__value-indicators {
      height: 36px !important;
      min-height: unset !important;
    }

    input {
      margin: 0 !important;
    }

    .react-select__placeholder {
      font-size: 14px;
    }
  }
`

export const FindButton = styled(Button).attrs(
  ({ withlabel, singleinput }) => ({
    mt: singleinput ? 0 : [3, 3, 0],
    width: withlabel ? ['100%', '100%', '55px'] : [36, 36, '20%'],
    height: [36, 36, '55px'],
  }),
)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.bgcolor};
  border-width: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  color: ${props => props.textcolor};
  text-transform: uppercase;
  position: relative;
  opacity: 1 !important;
  font-size: 18px;
  &:hover {
    > svg {
      fill: white;
    }
  }
  svg {
    fill: ${props => props.textcolor};
    position: relative;
    top: unset;
    left: unset;
  }
  ${buttonBorderRadiusCss};
`

export const MarkedLink = styled(NavLink)`
  color: ${themeGet('colors.font.secondary')};
  text-transform: uppercase;
`

export const FindButtonLabel = styled(Box).attrs(({ withlabel }) => ({
  display: withlabel
    ? ['block', 'block', 'none']
    : ['none', 'none', 'none', 'block'],
  pt: [1, 1, 0],
  pl: withlabel ? '8px' : '0',
  width: 'auto',
}))`
  font-size: 16px;
`

const searchIconCss = ({ withlabel }) =>
  withlabel &&
  css`
    display: block !important;
  `
export const SearchIcon = styled(Icon).attrs({
  glyph: searchGlyph,
  size: 16,
  display: ['block', 'block', 'block', 'none'],
})`
  fill: ${themeGet('colors.black')};
  @media screen and (min-width: ${themeGet('breakpoints.1')}) {
    height: 20px;
    width: 20px;
  }
  ${searchIconCss};
`
