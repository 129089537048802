import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'

import map from 'lodash/map'
import slice from 'lodash/slice'
import size from 'lodash/size'

import { Box } from 'Components/UI'

import DesktopSlider from '../DesktopSlider'

import Location from './Location'

import {
  Container,
  FeaturedLocationsContainer,
  FeaturedLocationsMobileContainer,
  FeaturedLocationsMobileSlider,
} from './styles'

class FeaturedLocations extends PureComponent {
  render() {
    const { locations, roundedBorder, sliderSettings, onLocationClick } =
      this.props

    return (
      <>
        <FeaturedLocationsContainer>
          {size(locations) === 4 || size(locations) === 8 ? (
            <Container px="16px">
              {map(locations, location => (
                <LazyLoad height="280px" key={location.id} once>
                  <Location
                    location={location}
                    roundedBorder={roundedBorder}
                    onClick={onLocationClick}
                  />
                </LazyLoad>
              ))}
            </Container>
          ) : (
            <DesktopSlider>
              {map(locations, location => (
                <LazyLoad height="280px" key={location.id} once>
                  <Box px="16px">
                    <Location
                      location={location}
                      roundedBorder={roundedBorder}
                      onClick={onLocationClick}
                    />
                  </Box>
                </LazyLoad>
              ))}
            </DesktopSlider>
          )}
        </FeaturedLocationsContainer>

        <FeaturedLocationsMobileContainer>
          <LazyLoad height="300px" once>
            <FeaturedLocationsMobileSlider {...sliderSettings}>
              {map(slice(locations, 0, 12), location => (
                <Box key={location.id} px="5px">
                  <Location
                    location={location}
                    roundedBorder={roundedBorder}
                    onClick={onLocationClick}
                  />
                </Box>
              ))}
            </FeaturedLocationsMobileSlider>
          </LazyLoad>
        </FeaturedLocationsMobileContainer>
      </>
    )
  }
}

FeaturedLocations.defaultProps = {
  locations: [],
  roundedBorder: 0,
  sliderSettings: {},
}

FeaturedLocations.propTypes = {
  history: PropTypes.object.isRequired,
  locations: PropTypes.array,
  marketplaceServiceType: PropTypes.number.isRequired,
  roundedBorder: PropTypes.number,
  sliderSettings: PropTypes.object,
  onLocationClick: PropTypes.func.isRequired,
}

export default FeaturedLocations
