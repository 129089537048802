import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Flex } from 'Components/UI'

export const Container = styled(Flex).attrs({
  width: 1,
  justifyContent: 'center',
})`
  height: fit-content;
`

export const Grid = styled(Flex).attrs({
  justifyContent: 'center',
  width: 1,
})`
  height: fit-content;
  display: grid;
  grid-gap: 60px 90px;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    grid-template-columns: repeat(2, minmax(120px, 1fr));
    grid-gap: 32px 8px;
  }
`

export const LoaderHolder = styled(Flex).attrs({
  width: 1,
  py: 3,
})`
  position: relative;
`
