import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  loadSuggestions,
  loadCategorySuggestions,
  loadFeaturedCategories,
  setCategoryValue,
  setSearchValue,
} from 'Store/Actions/marketplace'

import {
  getFeaturedCategories,
  getFeaturedCategoriesLoading,
  getFeaturedLocations,
  getFeaturedLocationsLoading,
  getCategoryValue,
  getSearchValue,
} from 'Store/Selectors/marketplace'

import MerchantsSearch from './MerchantsSearch'

const selector = createStructuredSelector({
  featuredCategories: getFeaturedCategories,
  featuredLocations: getFeaturedLocations,
  isFeaturedCategoriesLoading: getFeaturedCategoriesLoading,
  isFeaturedLocationsLoading: getFeaturedLocationsLoading,
  categoryValue: getCategoryValue,
  searchValue: getSearchValue,
})

const actions = {
  onLoadSuggestions: loadSuggestions,
  onLoadCategorySuggestions: loadCategorySuggestions,
  onLoadFeaturedCategories: loadFeaturedCategories,
  onSetCategoryValue: setCategoryValue,
  onSetSearchValue: setSearchValue,
}

export default connect(selector, actions)(MerchantsSearch)
