import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Flex, Icon } from 'Components/UI'

import { heartGlyph } from 'Assets/Svg'

export const WishlistOverlay = styled(Flex).attrs({
  justifyContent: 'center',
  alignItems: 'center',
})`
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  height: 32px;
  width: 32px;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 40%);
  padding-top: 1px;
  position: absolute;
  right: 7px;
  top: 7px;
  z-index: 1;
`

export const WishlistIcon = styled(Icon).attrs({
  glyph: heartGlyph,
})`
  fill: ${({ active }) =>
    active ? themeGet('colors.red') : themeGet('colors.black')};

  cursor: pointer;
  &:hover {
    animation: pulse 1s infinite;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.08);
    }
    100% {
      transform: scale(1);
    }
  }
`
