import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'

import map from 'lodash/map'
import get from 'lodash/get'
import find from 'lodash/find'
import slice from 'lodash/slice'
import size from 'lodash/size'

import {
  processFeaturedCategories,
  processBucketForSearchPathParams,
} from 'Services/Utils/merchants'

import { PUBLIC_PATHS } from 'Constants/paths'

import { Box } from 'Components/UI'

import DesktopSlider from '../DesktopSlider'

import Category from './Category'

import {
  Container,
  FeaturedCategoriesContainer,
  FeaturedCategoriesMobileContainer,
  FeaturedCategoriesMobileSlider,
} from './styles'

class FeaturedCategories extends PureComponent {
  state = {}

  handleCategory = category => async () => {
    const { categories, history, showAllCategoriesPage, onSetCategoryValue } =
      this.props

    if (showAllCategoriesPage) {
      const primaryCategorySlug = category.bucket_slug

      // Send popular search (supplementary) category to the categories search by tag page
      // Otherwise go to directly to the category page
      const path = category.supplementary
        ? `${PUBLIC_PATHS.CATEGORIES}#tags=${primaryCategorySlug}`
        : PUBLIC_PATHS.PRIMARY_CATEGORY(primaryCategorySlug)

      history.push(path)
    } else {
      const queryParams = processBucketForSearchPathParams({
        ...category,
        bucket_type: 'category',
      })
      history.push(PUBLIC_PATHS.SEARCH_QUERY(queryParams))
    }

    const categoryOptions = processFeaturedCategories({
      categories,
      type: 'categories',
    })
    const options = get(categoryOptions, 'options')
    const categoryValue = find(options, ['value.bucket_id', category.bucket_id])

    await onSetCategoryValue(categoryValue)
  }

  getUniqueCategoryKey = category => {
    return `${category.bucket_type}_${category.bucket_id}`
  }

  render() {
    const { categories, circleBorder, roundedBorder, sliderSettings } =
      this.props

    return (
      <>
        <FeaturedCategoriesContainer>
          {size(categories) === 4 || size(categories) === 8 ? (
            <Container px="16px">
              {map(categories, category => (
                <LazyLoad
                  height="280px"
                  key={this.getUniqueCategoryKey(category)}
                  once
                >
                  <Category
                    category={category}
                    circleBorder={circleBorder}
                    roundedBorder={roundedBorder}
                    onCategory={this.handleCategory}
                  />
                </LazyLoad>
              ))}
            </Container>
          ) : (
            <DesktopSlider>
              {map(categories, category => (
                <LazyLoad
                  height="280px"
                  key={this.getUniqueCategoryKey(category)}
                  once
                >
                  <Box px="16px">
                    <Category
                      category={category}
                      circleBorder={circleBorder}
                      roundedBorder={roundedBorder}
                      onCategory={this.handleCategory}
                    />
                  </Box>
                </LazyLoad>
              ))}
            </DesktopSlider>
          )}
        </FeaturedCategoriesContainer>

        <FeaturedCategoriesMobileContainer>
          <LazyLoad height="300px" once>
            <FeaturedCategoriesMobileSlider {...sliderSettings}>
              {map(slice(categories, 0, 8), category => (
                <Box key={this.getUniqueCategoryKey(category)} px="5px">
                  <Category
                    category={category}
                    roundedBorder={roundedBorder}
                    onCategory={this.handleCategory}
                  />
                </Box>
              ))}
            </FeaturedCategoriesMobileSlider>
          </LazyLoad>
        </FeaturedCategoriesMobileContainer>
      </>
    )
  }
}

FeaturedCategories.defaultProps = {
  categories: [],
  circleBorder: 0,
  roundedBorder: 0,
  showAllCategoriesPage: false,
  sliderSettings: {},
}

FeaturedCategories.propTypes = {
  categories: PropTypes.array,
  circleBorder: PropTypes.number,
  history: PropTypes.object.isRequired,
  marketplaceServiceType: PropTypes.number.isRequired,
  roundedBorder: PropTypes.number,
  showAllCategoriesPage: PropTypes.bool,
  sliderSettings: PropTypes.object,
  onSetCategoryValue: PropTypes.func.isRequired,
}

export default FeaturedCategories
