import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import { withAppContext } from 'Services/Context'
import { _ } from 'Services/I18n'
import { PUBLIC_PATHS } from 'Constants/paths'

import get from 'lodash/get'
import map from 'lodash/map'

import Category from './Category'

import { Container, Content, Title } from './styles'

function Categories({ baseTheme, categories, isDetailPage }) {
  const history = useHistory()
  const handlePrimaryCategory = useCallback(
    ({ category }) =>
      () => {
        const primaryCategorySlug = category.bucket_slug

        history.push(PUBLIC_PATHS.PRIMARY_CATEGORY(primaryCategorySlug))
      },
    [categories],
  )
  const handleSecondaryCategory = useCallback(
    ({ category, secondaryCategory }) =>
      () => {
        const primaryCategorySlug = category.bucket_slug
        const secondaryCategorySlug = secondaryCategory.bucket_slug

        history.push(
          PUBLIC_PATHS.SECONDARY_CATEGORY(
            primaryCategorySlug,
            secondaryCategorySlug,
          ),
        )
      },
    [categories],
  )
  const primaryFont = get(baseTheme, 'colors.primary_font')
  const secondaryColor = get(baseTheme, 'colors.secondary_background')

  return (
    <Container>
      {!isDetailPage && (
        <Title font={primaryFont}>{_('common.browseAllCategories')}</Title>
      )}

      <Content>
        {map(categories, (category, index) => (
          <Category
            category={category}
            color={secondaryColor}
            isDetailPage={isDetailPage}
            key={index}
            onPrimaryCategory={handlePrimaryCategory}
            onSecondaryCategory={handleSecondaryCategory}
          />
        ))}
      </Content>
    </Container>
  )
}

Categories.defaultProps = {
  baseTheme: {},
  categories: [],
  isDetailPage: false,
}

Categories.propTypes = {
  baseTheme: PropTypes.object,
  categories: PropTypes.array,
  isDetailPage: PropTypes.bool,
}

export default withAppContext(Categories)
