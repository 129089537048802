import StorageKeys from 'Constants/storageKeys'
import { WISHLIST_TYPE } from 'Constants/ids'
import { SET_WISHLIST_COUNT } from 'Store/Actions/wishlist'

const emptyWishlist = {
  merchants: [],
  services: [],
}

class WishlistPersist {
  static instance = null

  constructor() {
    if (WishlistPersist.instance) {
      return WishlistPersist.instance
    }

    try {
      this.wishlist =
        JSON.parse(localStorage.getItem(StorageKeys.WISHLIST)) || emptyWishlist
    } catch {
      this.wishlist = emptyWishlist
    }

    // Avoid loading incompatible wishlist structure
    if (
      !Array.isArray(this.wishlist[WISHLIST_TYPE.MERCHANT]) ||
      !Array.isArray(this.wishlist[WISHLIST_TYPE.SERVICE])
    ) {
      this.wishlist = emptyWishlist
    }

    WishlistPersist.instance = this
  }

  set() {
    try {
      localStorage.setItem(StorageKeys.WISHLIST, JSON.stringify(this.wishlist))
    } catch {
      // eslint-disable-next-line
      console.error(
        'Unable to save wishlist due to local storage not accessible.',
      )
    }
  }

  get() {
    if (typeof window === 'undefined') {
      return emptyWishlist
    }

    return this.wishlist
  }

  exists(token, wishlistType) {
    return this.wishlist[wishlistType].includes(token)
  }

  merchantExists(token) {
    return this.exists(token, WISHLIST_TYPE.MERCHANT)
  }

  serviceExists(token) {
    return this.exists(token, WISHLIST_TYPE.SERVICE)
  }

  count() {
    return this.wishlist.merchants.length + this.wishlist.services.length
  }

  toggle(token, dispatch, wishlistType) {
    const exists = this.exists(token, wishlistType)

    if (exists) {
      this.wishlist[wishlistType] = this.wishlist[wishlistType].filter(
        item => item !== token,
      )
    } else {
      this.wishlist[wishlistType].push(token)
    }

    this.set()

    dispatch({
      type: SET_WISHLIST_COUNT,
      count: this.count(),
    })

    return !exists
  }

  toggleMerchant(token, dispatch) {
    return this.toggle(token, dispatch, WISHLIST_TYPE.MERCHANT)
  }

  toggleService(token, dispatch) {
    return this.toggle(token, dispatch, WISHLIST_TYPE.SERVICE)
  }

  syncTokens(tokens, dispatch) {
    this.wishlist = tokens

    this.set()

    dispatch({
      type: SET_WISHLIST_COUNT,
      count: this.count(),
    })
  }
}

export default WishlistPersist
