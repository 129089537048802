import { takeLatest, select } from 'redux-saga/effects'
import TagManager from '@sooro-io/react-gtm-module'

import get from 'lodash/get'

import { GA } from 'Config/app'

import { LOAD_CLIENT_DETAILS } from 'Store/Actions/marketplace'
import { getGtmId, getConfig } from 'Store/Selectors/app'

function watchLoadClient({ data }) {
  const gtmId = get(data, 'google_tag_manager_id')

  // Load client GTM if present (but not same as env value)
  if (gtmId && gtmId !== GA.KEY) {
    TagManager.initialize({ gtmId })
  }
}

export default function* analytics() {
  // gaId comes from subdomain config (normally Nabooki GTM)
  const config = yield select(getConfig)
  const gaId = get(config, 'gaId')

  if (gaId) {
    TagManager.initialize({ gtmId: gaId })
  }

  // gtmId comes from client details
  const gtmId = yield select(getGtmId)

  // (SSR) Load client GTM if present (but not same as gaId in subdomain config)
  if (gtmId && gtmId !== gaId) {
    TagManager.initialize({ gtmId })
  } else {
    // If not SSR then wait for client details to load to give us access to google_tag_manager_id
    yield takeLatest(LOAD_CLIENT_DETAILS.SUCCESS, watchLoadClient)
  }
}
