import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Text, Flex } from 'Components/UI'

export const Container = styled(Flex).attrs({
  flexDirection: 'column',
  width: 1,
  p: [3, 20],
})`
  cursor: pointer;
  border: 1px solid ${themeGet('colors.lightGrey')};
  border-radius: ${themeGet('borderRadius.1')};

  background-color: ${themeGet('colors.white')};

  transform: none;
  transition:
    all 0.2s ease 0s,
    -webkit-transform 0.2s ease 0s;

  &:hover {
    transform: scale(1.01);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 9px 2px;
    transition:
      all 0.2s ease 0s,
      -webkit-transform 0.2s ease 0s;
  }
`

export const Title = styled(Text).attrs({
  fontSize: [2, 3, 20],
})`
  font-weight: bold;
  letter-spacing: 0.5px;
  color: ${props => (props.color ? props.color : themeGet('colors.black'))};
`

export const Description = styled(Text).attrs({
  fontSize: ['14px', 2, 2],
  mt: 2,
})`
  letter-spacing: 0.5px;
  line-height: 1.2;
  color: rgb(134, 135, 136);
  a {
    color: ${props => (props.color ? props.color : themeGet('colors.black'))};
  }
`
