import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { getColorFromBg } from 'Theme'

import Box from '../Box'

export default styled(Box).attrs({})`
  height: 7px;
  background-color: rgba(0, 0, 0, 0.15);
  overflow: hidden;
  position: relative;
  width: ${({ width }) => width || '100%'};

  :before {
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    width: ${({ value }) => `${value}%`};
    background-color: ${props =>
      props.bg ? getColorFromBg(props.bg)(props) : themeGet('colors.tealBlue')};
    transition: width 0.3s;
  }
`
