import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Text, Flex, Box } from 'Components/UI'

export const Container = styled(Flex).attrs({
  flexDirection: 'column',
  width: 1,
  mb: 48,
})``

export const Grid = styled(Box).attrs({
  width: 1,
  mt: 24,
})`
  display: grid;
  grid-gap: 20px 30px;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
`

export const PrimaryCategory = styled(Text).attrs({
  fontSize: 24,
})`
  color: ${themeGet('colors.header')};
  font-weight: bold;
  letter-spacing: 0.5px;
  cursor: pointer;

  :hover {
    color: ${props => (props.color ? props.color : themeGet('colors.black'))};
  }
`

export const SecondaryCategory = styled(Text).attrs({
  fontSize: 14,
})`
  letter-spacing: 0.5px;
  cursor: pointer;
  color: ${themeGet('colors.text')};

  :hover {
    color: ${props => (props.color ? props.color : themeGet('colors.black'))};
  }
`
