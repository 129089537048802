import styled from 'styled-components'

import { Text, Flex, Box } from 'Components/UI'

export const Container = styled(Flex).attrs({
  flexDirection: 'column',
  justifyContent: 'center',
  width: 1,
  pt: 32,
})`
  height: fit-content;
`

export const Content = styled(Box).attrs({
  width: 1,
})``

export const Title = styled(Text).attrs({
  fontSize: 30,
  mb: 40,
})`
  color: black;
  font-family: ${({ font }) => font || 'Inter, MarkPro, sans-serif'};
  font-weight: bold;
  letter-spacing: 0.5px;
`
