import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import map from 'lodash/map'
import omit from 'lodash/omit'
import isEqual from 'lodash/isEqual'

import { Box, Radio } from 'Components/UI'

import {
  getIsChecked,
  getIsSubCategoryChecked,
} from 'Services/Utils/categoryOptions'
import {
  LabelHolder,
  Label,
  RadioHolder,
  RadioOptions,
  SubcategoryHolder,
  ClearIcon,
  ChevronDown,
} from './styles'

class RadioCategory extends PureComponent {
  state = {}

  componentDidMount() {
    const { isOpen, item, value } = this.props
    const isParentActive =
      get(value, 'value.bucket_id') === get(item, 'value.bucket_id')

    if (isParentActive && !isOpen) {
      this.handleCollapse()
    }
  }

  componentDidUpdate(nextProps) {
    const { value: newValue } = nextProps
    const { isOpen, value: oldValue } = this.props

    const valueChanged = !isEqual(newValue, oldValue)

    if (valueChanged && this.getIsChecked() && !isOpen) {
      this.handleCollapse()
    }
  }

  handleCollapse = () => {
    const { item, onToggleSubGroup } = this.props

    onToggleSubGroup(item)
  }

  handleRadioSelect = () => {
    const { item, onRadioSelect } = this.props
    const nextValue = omit(item, ['value.category_2', 'value.category_3'])

    onRadioSelect(nextValue)
  }

  handleResetSelected = () => {
    const { onResetSelected } = this.props
    onResetSelected()
  }

  handleSelectSubcategory =
    ({ selectedSubCategory }) =>
    () => {
      const { item, onRadioSelect } = this.props
      const bucketType = get(selectedSubCategory, 'bucket_type') // category_2

      const nextItem = {
        ...item,
        value: {
          ...item.value,
          [bucketType]: selectedSubCategory,
        },
      }

      onRadioSelect(nextItem)
    }

  getIsChecked = () => {
    const { item, value } = this.props
    return getIsChecked(value, item)
  }

  getIsSubCategoryChecked = option => {
    const { value } = this.props
    return getIsSubCategoryChecked(value, option)
  }

  renderCategory = () => {
    const { color, isOpen, item, showClear } = this.props
    const isChecked = this.getIsChecked()
    const subCategories = get(item, 'value.category_2', [])

    return (
      <RadioHolder>
        <Radio
          checked={isChecked}
          color={color}
          input={{
            value: item,
            onChange: this.handleRadioSelect,
          }}
          name="category_1"
        />
        <Label onClick={this.handleRadioSelect}>
          {item.label.length > 60
            ? `${item.label.slice(0, 60)}...`
            : item.label}
        </Label>
        {subCategories.length ? (
          <ChevronDown checked={isOpen ? 1 : 0} onClick={this.handleCollapse} />
        ) : null}
        {isChecked && showClear && (
          <Box ml={4}>
            <ClearIcon color={color} onClick={this.handleResetSelected} />
          </Box>
        )}
      </RadioHolder>
    )
  }

  renderSubcategories = () => {
    const { color, isOpen, item, showClear } = this.props
    const subCategories = get(item, 'value.category_2', [])

    if (!subCategories.length || !isOpen) return null

    return (
      <SubcategoryHolder pl={20}>
        {map(subCategories, option => {
          const isChecked = this.getIsSubCategoryChecked(option)
          return (
            <RadioHolder key={get(option, 'bucket_id')} mt={12}>
              <Radio
                checked={isChecked}
                color={color}
                input={{
                  value: option,
                  onChange: this.handleSelectSubcategory({
                    selectedSubCategory: option,
                  }),
                }}
                mt={1}
                name="category_2"
              />
              <LabelHolder
                onClick={this.handleSelectSubcategory({
                  item,
                  selectedSubCategory: option,
                })}
              >
                <Label>
                  {get(option, 'bucket_name').length > 60
                    ? `${get(option, 'bucket_name').slice(0, 60)}...`
                    : get(option, 'bucket_name')}
                </Label>
              </LabelHolder>
              {isChecked && showClear && (
                <Box ml={4}>
                  <ClearIcon color={color} onClick={this.handleRadioSelect} />
                </Box>
              )}
            </RadioHolder>
          )
        })}
      </SubcategoryHolder>
    )
  }

  render() {
    return (
      <RadioOptions>
        {this.renderCategory()}
        {this.renderSubcategories()}
      </RadioOptions>
    )
  }
}

RadioCategory.defaultProps = {
  isOpen: false,
  value: {},
  showClear: true,
}

RadioCategory.propTypes = {
  color: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  item: PropTypes.object.isRequired,
  showClear: PropTypes.bool,
  value: PropTypes.object,
  onRadioSelect: PropTypes.func.isRequired,
  onResetSelected: PropTypes.func.isRequired,
  onToggleSubGroup: PropTypes.func.isRequired,
}

export default RadioCategory
