import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Text, Flex } from 'Components/UI'

export const Container = styled(Flex).attrs({
  flexDirection: 'column',
  width: 1,
  alignItems: 'center',
})`
  cursor: pointer;
`

export const Title = styled(Text).attrs({
  fontSize: 2,
  mt: 3,
})`
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: ${props => (props.color ? props.color : themeGet('colors.black'))};
`

export const Image = styled(Flex)`
  width: 160px;
  height: 160px;
  flex-shrink: 0;
  border-radius: 50%;

  background: ${({ src }) => `url(${src})`} center center;
  background-repeat: no-repeat;
  object-fit: cover;

  -webkit-transform: none;
  transform: none;

  transition:
    transform 0.2s,
    -webkit-transform 0.2s;
  transition:
    all 0.2s,
    -webkit-transform 0.2s;

  &:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    box-shadow: 0 2px 9px 2px rgba(0, 0, 0, 0.15);

    transition:
      transform 0.2s,
      -webkit-transform 0.2s;
    transition:
      all 0.2s,
      -webkit-transform 0.2s;
  }

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    width: 120px;
    height: 120px;
  }
`
