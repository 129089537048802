import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'
import map from 'lodash/map'

import { useBaseTheme } from 'Hooks'

import { _ } from 'Services/I18n'
import { PUBLIC_PATHS } from 'Constants/paths'

import {
  Container,
  Content,
  LeftSide,
  RightSide,
  WishlistCountBadge,
} from './styles'

import { LinkLabel, StyledButton, WishlistIcon } from '../styles'

function MobileMenu({ open, wishlistCount, onClose }) {
  const {
    primaryColor,
    primaryTextColor,
    roundedBorder,
    header,
    headerPrimaryColor,
    headerBackground,
  } = useBaseTheme()

  const history = useHistory()

  const handleExternalLink = useCallback(link => () => {
    const a = document.createElement('a')
    a.target = link.target
    a.href = link.url
    a.click()

    onClose()
  })

  const handleWishlistLink = () => {
    history.push(PUBLIC_PATHS.WISHLIST())

    onClose()
  }

  return (
    <Container background={headerBackground} open={open ? 1 : 0}>
      <LeftSide onClick={onClose} />
      <RightSide>
        <Content>
          {map(header, (link, index) => {
            const isButton = link.is_button
            const isPrimary = link.is_primary

            if (isButton) {
              return (
                <StyledButton
                  bc={isPrimary ? primaryColor : primaryTextColor}
                  bg={isPrimary ? primaryColor : 'transparent'}
                  color={primaryTextColor}
                  key={index}
                  my={8}
                  rounded={roundedBorder}
                  onClick={handleExternalLink(link)}
                >
                  {link.label}
                </StyledButton>
              )
            }
            return (
              <LinkLabel
                color={isPrimary ? primaryColor : headerPrimaryColor}
                justifyContent="center"
                key={index}
                my={8}
                py={8}
                onClick={handleExternalLink(link)}
              >
                {link.label}
              </LinkLabel>
            )
          })}
          <LinkLabel
            color={headerPrimaryColor}
            justifyContent="center"
            my={8}
            py={8}
            onClick={handleWishlistLink}
          >
            <WishlistIcon fill={headerPrimaryColor} mr={2} size={16} />
            {_('wishlist.wishlist')}
            {wishlistCount ? (
              <WishlistCountBadge>{wishlistCount}</WishlistCountBadge>
            ) : null}
          </LinkLabel>
        </Content>
      </RightSide>
    </Container>
  )
}

MobileMenu.defaultProps = {
  open: false,
  onClose: noop,
}

MobileMenu.propTypes = {
  open: PropTypes.bool,
  wishlistCount: PropTypes.number.isRequired,
  onClose: PropTypes.func,
}

export default MobileMenu
