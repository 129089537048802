import { connect } from 'react-redux'

import { setCategoryValue } from 'Store/Actions/marketplace'

import Categories from './Categories'

const actions = {
  onSetCategoryValue: setCategoryValue,
}

export default connect(null, actions)(Categories)
