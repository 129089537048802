import React from 'react'
import { get } from 'lodash'

import { _ } from 'Services/I18n'

import { compassGlyph, globeGlyph } from 'Assets/Svg'

import { PUBLIC_PATHS } from 'Constants/paths'
import labels from 'Constants/labels'
import {
  MARKETPLACE_DROPDOWN_TEXT,
  MARKETPLACE_SERVICE_TYPE,
} from 'Constants/ids'

import { LabelOption, LabelOptionIcon } from '../SearchSelect/styles'

const searchOnlineOption = label => ({
  label: (
    <LabelOption>
      <LabelOptionIcon glyph={globeGlyph} mr={8} size={16} />
      {label}
    </LabelOption>
  ),
  value: 'online',
  metadata: {
    displayType: 'h1',
    path: PUBLIC_PATHS.SEARCH_QUERY({ bucketType: 'online' }),
  },
})

export const initLocationOptions = ({
  baseTheme,
  marketplaceType,
  marketplaceServiceType,
  featureLocationOptions,
}) => {
  const options = [
    {
      label: (
        <LabelOption>
          <LabelOptionIcon glyph={compassGlyph} mr={8} size={16} />
          {_(MARKETPLACE_DROPDOWN_TEXT[marketplaceType])}
        </LabelOption>
      ),
      value: 'near',
      metadata: {
        displayType: 'h1',
        path: PUBLIC_PATHS.SEARCH_QUERY('near'),
      },
    },
  ]

  if (marketplaceServiceType === MARKETPLACE_SERVICE_TYPE.onlineAndPhysical) {
    const label = get(baseTheme, `labels.${labels.PLACEHOLDER_ONLINE_SERVICES}`)

    options.push(searchOnlineOption(label))
  }

  if (featureLocationOptions) {
    options.push(featureLocationOptions)
  }

  return options
}

export default {}
