import React from 'react'
import styled, { css } from 'styled-components'
import { space, size, width, height, display } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import tag from 'clean-tag'

import omit from 'lodash/omit'

const strokeCss = props =>
  props.stroke &&
  css`
    stroke: ${themeGet(`colors.${props.stroke}`)};
    fill: ${themeGet(`colors.${props.fill}`)};
  `

const Svg = tag.svg

const blacklist = ['isNextType']

export default styled(props => <Svg {...omit(props, blacklist)} />).attrs({
  blacklist,
})`
  vertical-align: middle;
  flex-shrink: 0;

  ${strokeCss};

  ${width};
  ${height};
  ${size};
  ${space};
  ${display};
`
