import { PUBLIC_PATHS } from 'Constants/paths'

import { NotFound } from 'Containers/Layout'
import * as PublicPages from 'Containers/Pages/Public'
import * as CategoriesPages from 'Containers/Pages/Public/Categories'

const routes = () => [
  /* Public */
  {
    path: PUBLIC_PATHS.ROOT,
    exact: true,
    component: PublicPages.Home,
  },
  {
    path: PUBLIC_PATHS.SEARCH_QUERY(),
    exact: true,
    component: PublicPages.Search,
  },
  {
    path: PUBLIC_PATHS.MERCHANT(),
    exact: true,
    component: PublicPages.Merchant,
  },
  {
    path: PUBLIC_PATHS.SERVICE(),
    exact: true,
    component: PublicPages.Service,
  },
  {
    path: PUBLIC_PATHS.WISHLIST(),
    exact: true,
    component: PublicPages.Wishlist,
  },
  {
    path: PUBLIC_PATHS.CATEGORIES,
    exact: false,
    component: CategoriesPages.Main,
    routes: [
      {
        path: PUBLIC_PATHS.CATEGORIES,
        exact: true,
        component: CategoriesPages.List,
      },
      {
        path: PUBLIC_PATHS.SECONDARY_CATEGORY(),
        component: CategoriesPages.Category,
      },
    ],
  },
  {
    component: NotFound,
  },
]

export default routes
