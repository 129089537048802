import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { longArrowLeftGlyph, longArrowRightGlyph } from 'Assets/Svg'

import Flex from '../Flex'
import Icon from '../Icon'

const activeCss = props =>
  props.active &&
  css`
    color: ${props.color || themeGet('colors.tealBlue')};
    border: 1px solid ${props.color || themeGet('colors.tealBlue')};
    display: flex !important;
  `

const disabledCss = props =>
  props.disabled &&
  css`
    background-color: ${themeGet('colors.concrete')};
    background-image: none;
    color: ${themeGet('colors.dustyGray')};
    > span > svg {
      fill: ${themeGet('colors.alto')};
    }
  `

export const Paginate = styled(Flex).attrs({
  is: 'ul',
  p: 0,
  mb: 0,
})`
  justify-content: center;
  white-space: nowrap;
  > li {
    border-radius: ${({ roundedborder }) =>
      roundedborder ? themeGet('borderRadius.2') : themeGet('borderRadius.1')};
  }
`

export const PaginateButton = styled(Flex).attrs({
  is: 'li',
  justifyContent: 'center',
  alignItems: 'center',
  height: '28px',
})`
  font-size: 12px;
  white-space: nowrap;
  width: ${props => (props.width ? props.width : 28)}px;
  text-transform: uppercase;
  pointer-events: ${props => (props.disabled ? ' none' : 'auto')};
  text-decoration: none;
  border: 1px solid ${themeGet('colors.alto')};
  color: ${themeGet('colors.boulder')};
  margin: 0 8px;
  cursor: pointer;
  background-color: ${themeGet('colors.white')};
  &:hover {
    background-color: ${({ arrow, color }) =>
      arrow ? 'transparent' : color || themeGet('colors.tealBlue')} !important;
    border: 1px solid ${({ color }) => color || themeGet('colors.tealBlue')};
    color: ${themeGet('colors.white')};
  }
  ${activeCss}
  ${disabledCss}
  :first-child {
    border: none;
    background: none;
  }
  :last-child {
    border: none;
    background: none;
  }
  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    display: none;
    &:first-child,
    &:last-child {
      display: flex;
    }
  }
`

export const NextIcon = styled(Icon).attrs({
  glyph: longArrowRightGlyph,
  size: 22,
})``

export const PrevIcon = styled(Icon).attrs({
  glyph: longArrowLeftGlyph,
  size: 22,
})``
