import styled, { css } from 'styled-components'
import { size } from 'styled-system'

import Box from '../Box'

const sizeCss = props =>
  props.size &&
  css`
    width: ${props.size}px;
    height: ${props.size}px;
  `

export const Wrapper = styled(Box).attrs({})`
  overflow: hidden;
  border-radius: 50%;
  object-fit: contain;
  width: 180px;
  height: 180px;

  ${sizeCss};

  ${size};
`

export const ImageHolder = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: ${props => `url(${props.bg}) no-repeat center center`};
  background-size: contain;
`
