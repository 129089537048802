import { APPEARANCE } from 'Config/app'

import CookiePersist from 'Services/CookiePersist'

import { createReducer } from 'Store/utils'
import { CHANGE_THEME } from 'Store/Actions/ui'
import { ACCEPT_COOKIE_POLICY } from 'Store/Actions/app'

const cookiePersist = new CookiePersist()

const initialState = {
  theme: APPEARANCE.THEME,
  cookiesAccepted: false,
}

const updatePersist = (state, key, value) => {
  const nextState = state.merge({ [key]: value })
  cookiePersist.set(nextState)

  return nextState
}

const handlers = {
  [CHANGE_THEME]: (state, { theme }) => updatePersist(state, 'theme', theme),
  [ACCEPT_COOKIE_POLICY]: state =>
    updatePersist(state, 'cookiesAccepted', true),
}

export default createReducer(initialState, handlers)
