import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import map from 'lodash/map'

import { Flex } from 'Components/UI'

import {
  Container,
  Content,
  FilterLabel,
  Header,
  StatusIcon,
  TagIcon,
} from './styles'

const ServiceCustomFilterOptions = ({ customFilter, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOnClick = useCallback(() => {
    setIsOpen(!isOpen)
  })

  const options = get(customFilter, 'options', [])

  return (
    <Container {...rest} onClick={handleOnClick}>
      <Header>
        <Flex>
          <TagIcon />
        </Flex>
        <FilterLabel>{customFilter.label}</FilterLabel>
        <Flex>
          <StatusIcon open={isOpen} />
        </Flex>
      </Header>
      {isOpen && (
        <Content>
          <ul>
            {map(options, option => {
              return <li key={option.id}>{option.value}</li>
            })}
          </ul>
        </Content>
      )}
    </Container>
  )
}

ServiceCustomFilterOptions.defaultProps = {}

ServiceCustomFilterOptions.propTypes = {
  customFilter: PropTypes.object.isRequired,
}

export default ServiceCustomFilterOptions
