import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import noop from 'lodash/noop'

import { withRouter } from 'react-router'
import LazyLoad from 'react-lazyload'

import { withAppContext } from 'Services/Context'

import { _ } from 'Services/I18n'

import { CARD_IMAGE_HEIGHT } from 'Theme/constants'
import { PUBLIC_PATHS } from 'Constants/paths'
import { globeGlyph } from 'Assets/Svg'
import { MapPinIcon } from 'Assets/Svg/General'

import ServiceList from '../ServiceList'
import DefaultCategories from '../DefaultCategories'
import RatingStars from '../RatingStars'
import WishlistToggle from '../WishlistToggle'

import {
  Container,
  ContentHolder,
  ImageHolder,
  MainContentHolder,
  MainContent,
  ImageBg,
  Content,
  Title,
  Location,
  LocationText,
  LocationIcon,
  MobileButtonHolder,
  StyledButton,
  ContentWrapper,
  ContentHolderWrapper,
  MerchantLogo,
} from './styles'

class MerchantCard extends PureComponent {
  get = type => {
    const { merchant } = this.props
    return get(merchant, type)
  }

  handleGoToMerchant = () => {
    const { baseTheme, history, merchant } = this.props
    const marketplaceType = get(baseTheme, 'marketplace_type')
    history.push(
      PUBLIC_PATHS.MERCHANT({
        id: get(merchant, 'token'),
        slug: get(merchant, 'slug'),
        marketplaceType,
      }),
      {
        ...history.location.state,
        prevPath: history.location.pathname,
      },
    )
  }

  handleClick = () => {
    const { merchant, onClick } = this.props
    onClick(merchant)
  }

  render() {
    const {
      baseTheme,
      breakpoint,
      ignoreRoundedBorder,
      lazyLoad,
      merchant,
      history,
      withBook,
      withBookPosition,
      withDefaultCategories,
      withImage,
      withLink,
      withLogo,
      withServices,
      withWishlist,
      onClick,
      ...rest
    } = this.props

    const defaultCategories = get(merchant, 'default_categories', [])

    const primaryColor = get(baseTheme, 'colors.primary_background')
    const secondaryColor = get(baseTheme, 'colors.secondary_background')
    const handleLinkClick = withLink ? this.handleGoToMerchant : noop
    const roundedBorder = get(baseTheme, 'colors.rounded_border') ? 1 : 0
    const multipleLocations = get(merchant, 'multiple_locations', false)
    const services = get(merchant, 'services', [])
    const logoUrl = get(merchant, 'logo')
    const showLogo =
      get(baseTheme, 'display_business_logo') && logoUrl && withLogo
    const appended = get(merchant, 'appended', false)

    const LazyLoadImageWrapper = ({ isLazyLoaded, children }) =>
      isLazyLoaded ? (
        <LazyLoad
          height={CARD_IMAGE_HEIGHT.LIST}
          offset={100}
          once
          style={{ height: CARD_IMAGE_HEIGHT.LIST }}
        >
          {children}
        </LazyLoad>
      ) : (
        children
      )

    const bucketName = multipleLocations
      ? 'Multiple locations'
      : `${this.get('geographical_buckets.suburb.bucket_name')}, ${this.get(
          'geographical_buckets.state.bucket_name',
        )}`
    const locationText = appended ? _('common.onlineService') : bucketName
    const merchantReviews = get(merchant, 'reviews')

    return (
      <Container>
        <ContentHolderWrapper
          background={secondaryColor}
          roundedborder={roundedBorder && !ignoreRoundedBorder ? 1 : 0}
          {...rest}
        >
          <ContentHolder background={secondaryColor} onClick={this.handleClick}>
            {withImage && (
              <ImageHolder>
                {withWishlist && <WishlistToggle token={merchant.token} />}
                <LazyLoadImageWrapper isLazyLoaded={lazyLoad}>
                  <ImageBg
                    src={this.get('images.orig.main_url')}
                    onClick={handleLinkClick}
                  />
                </LazyLoadImageWrapper>
              </ImageHolder>
            )}
            <MainContentHolder onClick={handleLinkClick}>
              <>
                <Title withlogo={withLogo ? 1 : 0}>{this.get('name')}</Title>
                <RatingStars
                  businessName={this.get('name')}
                  color="#52525b"
                  display
                  mt="6px"
                  reviews={merchantReviews}
                  size={14}
                />
              </>

              {showLogo && <MerchantLogo src={logoUrl} />}
              <MainContent mt={2}>
                <ContentWrapper>
                  <Content>
                    <Location>
                      <LocationIcon
                        color={primaryColor}
                        glyph={appended ? globeGlyph : MapPinIcon}
                      />
                      <LocationText>{locationText}</LocationText>
                    </Location>

                    {withDefaultCategories && (
                      <DefaultCategories
                        categories={defaultCategories}
                        mt={3}
                      />
                    )}
                  </Content>
                </ContentWrapper>
              </MainContent>
              {withBook && !withServices && (
                <MobileButtonHolder mt={16} position={withBookPosition}>
                  <StyledButton
                    background={primaryColor}
                    color={get(baseTheme, 'colors.primary_text')}
                    roundedborder={roundedBorder}
                    onClick={this.handleGoToMerchant}
                  >
                    {_('common.bookNow')}
                  </StyledButton>
                </MobileButtonHolder>
              )}
            </MainContentHolder>
          </ContentHolder>
          {withServices && (
            <ServiceList merchant={merchant} services={services} />
          )}
        </ContentHolderWrapper>
      </Container>
    )
  }
}

MerchantCard.defaultProps = {
  ignoreRoundedBorder: false,
  lazyLoad: false,
  merchant: null,
  withServices: false,
  withBook: true,
  withBookPosition: null,
  withDefaultCategories: true,
  withImage: true,
  withLink: false,
  withLogo: false,
  withWishlist: true,
  onClick: noop,
}

MerchantCard.propTypes = {
  baseTheme: PropTypes.object.isRequired,
  breakpoint: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  ignoreRoundedBorder: PropTypes.bool,
  lazyLoad: PropTypes.bool,
  match: PropTypes.object.isRequired,
  merchant: PropTypes.object,
  withBook: PropTypes.bool,
  withBookPosition: PropTypes.string,
  withDefaultCategories: PropTypes.bool,
  withImage: PropTypes.bool,
  withLink: PropTypes.bool,
  withLogo: PropTypes.bool,
  withServices: PropTypes.bool,
  withWishlist: PropTypes.bool,
  onClick: PropTypes.func,
}

export default withRouter(withAppContext(MerchantCard))
