import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Flex, Box } from 'Components/UI'

export const Container = styled(Box).attrs({
  width: 1,
})``

export const DesktopContent = styled(Flex).attrs({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: 1,
  display: ['none', 'none', 'flex'],
})`
  height: fit-content;

  border: 1px solid ${themeGet('colors.lightGrey')};
  border-radius: ${themeGet('borderRadius.1')};

  overflow: hidden;
  background-color: ${themeGet('colors.white')};

  > div {
    width: 100%;
  }

  > div > div:first-child {
    display: none;
  }

  > div > div:nth-child(2) {
    border-top: none;
    border-bottom: none;
    padding-top: 0;
  }
`

export const MobileContent = styled(Flex).attrs({
  width: 1,
  display: ['flex', 'flex', 'none'],
})``

export const FilterDropmenuHolder = styled(Box).attrs({
  width: 1,
})`
  > div {
    width: 100%;
    padding: 10px 16px;
    border: 1px solid #f3f3f3;
    border-radius: 4px;
    > div:first-child {
      justify-content: space-between;
    }
    > div:nth-child(2) {
      width: 100%;
      left: 0px;
      top: 100%;
      &:after {
        display: none !important;
      }
    }
  }
`
