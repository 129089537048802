import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import { Flex } from 'Components/UI'
import { useBaseTheme } from 'Hooks'

import { Content, ErrorImage, Text, Button } from './styles'

const handleReload = () => window.location.reload()

const getErrorMessage = error => {
  switch (get(error, 'status')) {
    case 503:
      return (
        <Text>
          We are currently upgrading our system. Please try again in a few
          minutes.
          <br />
          We apologise for any inconvenience.
        </Text>
      )
    default:
      return <Text>Oops! Something went wrong...</Text>
  }
}

const ErrorContent = ({ error, onBack }) => {
  const { primaryColor, primaryTextColor } = useBaseTheme()

  return (
    <Content>
      <ErrorImage />
      {getErrorMessage(error)}
      <Flex>
        <Button
          background={primaryColor}
          mr={2}
          textcolor={primaryTextColor}
          onClick={onBack}
        >
          Go back
        </Button>
        <Button
          background={primaryColor}
          ml={2}
          textcolor={primaryTextColor}
          onClick={handleReload}
        >
          Refresh
        </Button>
      </Flex>
    </Content>
  )
}

ErrorContent.defaultProps = {
  error: null,
}

ErrorContent.propTypes = {
  error: PropTypes.object,
  onBack: PropTypes.func.isRequired,
}

export default ErrorContent
