import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Flex, Box, Text, Icon } from 'Components/UI'

const roundedCss = ({ rounded }) =>
  rounded &&
  css`
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  `

export const DropdownContainer = styled(Box).attrs({})`
  background-color: ${themeGet('colors.white')};
  border-top: 1px solid ${themeGet('colors.lightGrey')};
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 100%;
  z-index: 3;

  ${roundedCss};
`

export const GroupHeading = styled(Box).attrs({
  px: 23,
  pb: 2,
})`
  vertical-align: center;
`

export const GroupHeadingTitle = styled(Text).attrs({
  is: 'span',
  fontSize: '14px',
})`
  color: ${themeGet('colors.gray')};
  text-transform: uppercase;
  font-weight: 500;
`

export const MapPinIconContainer = styled(Icon).attrs(props => ({
  fill: themeGet('colors.gray')(props),
  color: themeGet('colors.gray')(props),
  mr: 8,
}))`
  margin-top: -3px;
`

export const LabelOptionIcon = styled(Icon).attrs(props => ({
  fill: themeGet('colors.gray')(props),
}))`
  margin-top: -2px;
`

export const LabelOption = styled(Flex).attrs({
  justifyContent: 'flex-start',
  alignItems: 'center',
  ml: '-20px',
})``
