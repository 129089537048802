import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { renderRoutes } from 'react-router-config'
import { withRouter, matchPath } from 'react-router'
import { ThemeProvider } from 'styled-components'
import { Helmet } from 'react-helmet'
import 'react-dates/initialize'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import jwt from 'jsonwebtoken'
import qs from 'qs'

import StorageKeys from 'Constants/storageKeys'
import { PUBLIC_PATHS } from 'Constants/paths'
import { SEARCH_TYPE } from 'Constants/ids'

import { loadClientDetails } from 'Store/Actions/marketplace'

import {
  getIsReady,
  getBaseTheme,
  getAuthentication,
  getClientName,
  getLoadingClient,
  getConfig,
  getLocale,
} from 'Store/Selectors/app'

import { getTheme, getCookiesAccepted } from 'Store/Selectors/persist'

import routes from 'Config/Routes'

import { AppContext } from 'Services/Context'
import { initTranslations } from 'Services/I18n'
import SearchTypePersist from 'Services/SearchTypePersist'

import { IS_PRODUCTION } from 'Config/app'
import GlobalStyle from 'Components/GlobalStyle'
import { ErrorBoundary, ErrorContent } from 'Components/Blocks'
import { Loader } from 'Components/UI'

import themes from 'Theme'

import { Container } from './styles'

function LocaleRenderer({ children }) {
  return children
}

class App extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      breakpoint: this.theme.breakpointNames.DESKTOP,
      clientError: null,
    }
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }

  async componentDidMount() {
    const { history, baseTheme, onLoadClientDetails } = this.props

    this.unlisten = history.listen(this.handleListenHistory)

    if (isEmpty(baseTheme)) {
      const clientDetails = await onLoadClientDetails()
      if (!clientDetails || clientDetails.status !== 200) {
        this.setState({ clientError: clientDetails })
        return
      }
    }

    // On page load store the default search type depending on
    // the marketplace search type setting
    this.setDefaultSearchType()

    // Get authentication after await (clientDetails)
    const { authentication } = this.props
    const isPublic = authentication.require_auth === false

    if (isPublic || !IS_PRODUCTION) {
      return
    }

    const params = qs.parse(history.location.search.replace('?', ''))
    const savedToken = localStorage.getItem(StorageKeys.WIDGET_TOKEN)
    const token = params.token || savedToken
    const isExpired = this.isExpired(token)

    if (isExpired) {
      localStorage.removeItem(StorageKeys.WIDGET_TOKEN)
      history.push(PUBLIC_PATHS.REDIRECTOR())
      return
    }

    localStorage.setItem(StorageKeys.WIDGET_TOKEN, token)
    history.replace(history.location.pathname, {
      ...get(history, 'location.state', {}),
    })
  }

  componentWillUnmount() {
    this.unlisten()
    window.removeEventListener('resize', this.handleResize)
  }

  get theme() {
    const { theme } = this.props
    return themes[theme]
  }

  setDefaultSearchType = () => {
    const { baseTheme } = this.props
    const searchTypePersist = new SearchTypePersist()
    const isSearchByServiceEnabled = get(baseTheme, 'search_by_service', false)

    // Always default to business search if search by service is disabled
    if (!isSearchByServiceEnabled) {
      searchTypePersist.set(SEARCH_TYPE.BUSINESSES)

      return
    }

    if (!searchTypePersist.get()) {
      const defaultSearchType = isSearchByServiceEnabled
        ? SEARCH_TYPE.SERVICES
        : SEARCH_TYPE.BUSINESSES

      searchTypePersist.set(defaultSearchType)
    }
  }

  handleListenHistory = location => {
    const { history, authentication } = this.props
    const isPublic = authentication.require_auth === false

    const searchPath = matchPath(location.pathname, {
      path: PUBLIC_PATHS.SEARCH_QUERY(),
      exact: true,
    })

    const searchType = get(searchPath, 'params.searchType')

    if (searchType) {
      const searchTypePersist = new SearchTypePersist()
      searchTypePersist.set(searchType)
    }

    document.body.style.position = 'relative'
    document.body.style.width = 'initial'
    window.scrollTop = 0

    if (isEmpty(authentication)) {
      return
    }

    const canSkip =
      location.pathname === PUBLIC_PATHS.REDIRECTOR() ||
      isPublic ||
      !IS_PRODUCTION

    if (canSkip) {
      return
    }

    const token = localStorage.getItem(StorageKeys.WIDGET_TOKEN)
    const isExpired = this.isExpired(token)

    if (isExpired) {
      localStorage.removeItem(StorageKeys.WIDGET_TOKEN)
      history.push(PUBLIC_PATHS.REDIRECTOR())
    }
  }

  isExpired = token => {
    const result = jwt.decode(token)
    const now = parseInt(Date.now() / 1000, 10)
    return +get(result, 'exp', 0) < now
  }

  handleResize = () => {
    const {
      theme: { breakpoints, breakpointNames },
    } = this
    const { breakpoint } = this.state

    const width = window.innerWidth

    const setBreakpoint = pointName => {
      if (pointName !== breakpoint) {
        this.setState({ breakpoint: pointName })
      }
    }

    if (width <= parseInt(breakpoints[0], 10)) {
      setBreakpoint(breakpointNames.MOBILE)
    } else if (width <= parseInt(breakpoints[1], 10)) {
      setBreakpoint(breakpointNames.TABLET)
    } else if (width <= parseInt(breakpoints[2], 10)) {
      setBreakpoint(breakpointNames.DESKTOP)
    } else {
      setBreakpoint(breakpointNames.LARGE)
    }
  }

  handleGoBack = () => {
    const { history } = this.props
    history.goBack()
  }

  render() {
    const { theme } = this
    const { breakpoint, clientError } = this.state
    const {
      isReady,
      config,
      theme: themeName,
      baseTheme,
      cookiesAccepted,
      clientName,
      isLoadingClient,
      locale,
    } = this.props

    const canShowError = isReady && !isLoadingClient && clientError

    const contextValue = {
      breakpoint,
      config,
      theme,
      themeName,
      baseTheme,
      cookiesAccepted,
      locale,
    }

    initTranslations({ locale })

    if (canShowError) {
      return (
        <ThemeProvider theme={theme}>
          <AppContext.Provider value={contextValue}>
            <Container>
              <GlobalStyle
                background={get(baseTheme, 'colors.secondary_background')}
              />
              <ErrorContent error={clientError} onBack={this.handleGoBack} />
            </Container>
          </AppContext.Provider>
        </ThemeProvider>
      )
    }

    return (
      <ThemeProvider theme={theme}>
        <AppContext.Provider value={contextValue}>
          <LocaleRenderer key={locale}>
            <Helmet defer={false}>
              <link
                href={get(baseTheme, 'images.favicon_url', '')}
                rel="shortcut icon"
              />
              <title>{clientName}</title>
            </Helmet>
            <ErrorBoundary>
              <Container>
                <GlobalStyle
                  background={get(baseTheme, 'colors.secondary_background')}
                />
                {isReady && !isEmpty(baseTheme) ? (
                  renderRoutes(routes())
                ) : (
                  <Loader
                    color={get(baseTheme, 'colors.secondary_background')}
                  />
                )}
              </Container>
            </ErrorBoundary>
          </LocaleRenderer>
        </AppContext.Provider>
      </ThemeProvider>
    )
  }
}

App.defaultProps = {
  config: {},
}

App.propTypes = {
  authentication: PropTypes.object.isRequired,
  baseTheme: PropTypes.object.isRequired,
  clientName: PropTypes.string.isRequired,
  config: PropTypes.object,
  cookiesAccepted: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  isLoadingClient: PropTypes.bool.isRequired,
  isReady: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  onLoadClientDetails: PropTypes.func.isRequired,
}

const selector = createStructuredSelector({
  authentication: getAuthentication,
  config: getConfig,
  isLoadingClient: getLoadingClient,
  isReady: getIsReady,
  theme: getTheme,
  baseTheme: getBaseTheme,
  cookiesAccepted: getCookiesAccepted,
  clientName: getClientName,
  locale: getLocale,
})

const actions = {
  onLoadClientDetails: loadClientDetails,
}

export default withRouter(connect(selector, actions)(App))
