import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import noop from 'lodash/noop'

import { useBaseTheme } from 'Hooks'

import {
  Container,
  ImageBg,
  OverlayBg,
  TitleHolder,
  Title,
  TitleBg,
} from './styles'

const Location = ({ location, roundedBorder, onClick }) => {
  const { secondaryColor, secondaryTextColor } = useBaseTheme()
  const handleClick = () => onClick(location)

  return (
    <Container
      bgurl={get(location, 'image_url')}
      hovercolor={secondaryTextColor}
      roundedborder={roundedBorder}
      onClick={handleClick}
    >
      <ImageBg src={get(location, 'image_url')} />
      <OverlayBg />
      <TitleHolder>
        <Title className="location-title">{get(location, 'region_name')}</Title>
        <TitleBg color={secondaryColor} />
      </TitleHolder>
    </Container>
  )
}

Location.defaultProps = {
  location: {},
  roundedBorder: 0,
  onClick: noop,
}

Location.propTypes = {
  location: PropTypes.object,
  roundedBorder: PropTypes.number,
  onClick: PropTypes.func,
}

export default Location
