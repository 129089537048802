import { themeGet } from '@styled-system/theme-get'
import styled, { css } from 'styled-components'
import { background } from 'styled-system'

import { Flex, Box, Text as BaseText } from 'Components/UI'

const openMobileMenuCss = ({ open }) =>
  open &&
  css`
    transform: none;
  `
export const Container = styled(Flex).attrs({
  width: 1,
  pt: 56,
})`
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 200;

  list-style-type: none;
  -webkit-font-smoothing: antialiased;

  transform-origin: 0% 0%;
  transform: translate(100%, 0);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

  background-color: ${themeGet(`colors.secondary`)};

  ${background};
  ${openMobileMenuCss};
`

export const LeftSide = styled(Box).attrs({
  width: '0%',
})`
  height: 100%;
`

export const RightSide = styled(Box).attrs({
  width: '100%',
})`
  height: 100%;
`

export const Content = styled(Flex).attrs({
  width: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  py: 20,
  px: 16,
})``

const activeCss = ({ active }) =>
  active &&
  css`
    color: ${themeGet('colors.primary')};
  `
export const Text = styled(BaseText).attrs({
  fontSize: 20,
})`
  cursor: pointer;

  &:hover {
    color: ${themeGet('colors.primary')};
  }

  ${activeCss};
`

export const WishlistCountBadge = styled(Flex).attrs({
  ml: 2,
})`
  display: inline-block;
  border-radius: 50%;
  background-color: ${themeGet('colors.red')};

  height: 18px;
  width: 18px;

  font-size: 11px;
  text-align: center;
  color: ${themeGet('colors.white')};
`
