import { createReducer } from 'Store/utils'

import { LOAD_LOCATIONS } from 'Store/Actions/locations'

const initialState = {
  locations: [],
  isLoading: false,
  error: null,
}

const handleLoadLocationsRequest = state =>
  state.merge({ isLoading: true, error: null })

const handleLoadLocationsSuccess = (state, { data }) =>
  state.merge({ isLoading: false, locations: data })

const handleLoadLocationsFailure = state =>
  state.merge({ isLoading: false, error: null })

const handlers = {
  [LOAD_LOCATIONS.REQUEST]: handleLoadLocationsRequest,
  [LOAD_LOCATIONS.SUCCESS]: handleLoadLocationsSuccess,
  [LOAD_LOCATIONS.FAILURE]: handleLoadLocationsFailure,
}

export default createReducer(initialState, handlers)
