import React from 'react'

import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { chevronDownGlyph } from 'Assets/Svg'

import { Flex, Box, Icon, Dropdown } from 'Components/UI'

const checkedChevronCss = ({ opened }) =>
  opened &&
  css`
    transform: rotate(180deg);
  `

export const ChevronDown = styled(Icon).attrs({
  glyph: chevronDownGlyph,
  size: 12,
  ml: 2,
})`
  fill: gray;
  transition: 0.2s ease transform;

  ${checkedChevronCss};
`

export const Item = styled(Flex).attrs(({ alignItems }) => ({
  alignItems: alignItems || 'center',
  py: '6px',
}))`
  label {
    margin-bottom: 0;
    align-items: flex-start;
    width: auto;

    input {
      margin-right: 14px;

      &:not(checked):after {
        border: 2px solid #cfd9e0;
        background-color: ${themeGet('colors.white')};
      }
    }

    > div {
      font-family: ${themeGet('font')};
      font-size: 16px;
      font-weight: 200;
      letter-spacing: 0.5px;
      color: ${themeGet('colors.black')};
    }
  }

  div {
    font-size: 14px !important;
    color: #868788 !important;

    svg {
      fill: #868788;

      &:hover {
        fill: ${props => (props.color ? props.color : themeGet('colors.gray'))};
      }
    }
  }
`

export const Content = styled(Box).attrs({
  p: 12,
})`
  border-top: 1px solid ${themeGet('colors.concrete')};
  border-bottom: 1px solid ${themeGet('colors.concrete')};
  border-radius: ${themeGet('borderRadius.1')};

  max-height: 400px;
  overflow: auto;
`

export const StyledDropdown = styled(Dropdown)`
  color: ${props => (props.color ? props.color : themeGet('colors.gray'))};
  border: none;

  svg {
    fill: ${props => (props.color ? props.color : themeGet('colors.gray'))};
  }

  > div:last-child {
    box-shadow: none;
    border: 1px solid #f0f0f0;
    border-radius: 0;
  }

  > div:first-child {
    border: none;
    font-weight: 300 !important;
    font-size: 15px;
  }
`

export const BadgeContent = styled(Box).attrs({
  children: '1',
})`
  display: inline;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  line-height: 1.5;
  padding: 1px;
`

export const Badge = styled(Flex).attrs({
  children: <BadgeContent />,
  ml: 2,
})`
  position: relative;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 900;
  height: 18px;
  width: 18px;
  color: white;
  background-color: ${themeGet('colors.red')};
`
