import loadable from '@loadable/component'

const Home = loadable(() => import(/* webpackChunkName: "Home" */ './Home'))

const Search = loadable(
  () => import(/* webpackChunkName: "Search" */ './Search'),
)

const Merchant = loadable(
  () => import(/* webpackChunkName: "Merchant" */ './Merchant'),
)

const Service = loadable(
  () => import(/* webpackChunkName: "Service" */ './Service'),
)

const Redirector = loadable(
  () => import(/* webpackChunkName: "Redirector" */ './Redirector'),
)

const Wishlist = loadable(
  () => import(/* webpackChunkName: "Wishlist" */ './Wishlist'),
)

export { Home, Search, Merchant, Service, Redirector, Wishlist }
