import loadable from '@loadable/component'

const Main = loadable(
  () => import(/* webpackChunkName: "CategoriesMain" */ './Main'),
)

const List = loadable(
  () => import(/* webpackChunkName: "CategoriesList" */ './List'),
)

const Category = loadable(
  () => import(/* webpackChunkName: "Category" */ './Category'),
)

export { Main, Category, List }
