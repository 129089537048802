import PropTypes from 'prop-types'
import React from 'react'
import { Range as ReactRange, getTrackBackground } from 'react-range'

const Range = ({ color, max, min, step, value, onChange, onFinalChange }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        margin: '0 10px',
      }}
    >
      <ReactRange
        max={max}
        min={min}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            // eslint-disable-next-line
            key={props.key}
            style={{
              // eslint-disable-next-line
              ...props.style,
              height: '18px',
              width: '18px',
              borderRadius: '4px',
              backgroundColor: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA',
              outlineColor: color,
            }}
          >
            <div
              style={{
                height: '8px',
                width: '2px',
                backgroundColor: isDragged ? color : '#cfd9e0',
              }}
            />
          </div>
        )}
        renderTrack={({ props, children }) => (
          // eslint-disable-next-line
          <div
            style={{
              // eslint-disable-next-line
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%',
            }}
            // eslint-disable-next-line
            onMouseDown={props.onMouseDown}
            // eslint-disable-next-line
            onTouchStart={props.onTouchStart}
          >
            <div
              // eslint-disable-next-line
              ref={props.ref}
              style={{
                height: '4px',
                width: '100%',
                borderRadius: '6px',
                background: getTrackBackground({
                  values: value,
                  colors: [color, '#cfd9e0'],
                  min,
                  max,
                }),
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
          </div>
        )}
        step={step}
        values={value}
        onChange={onChange}
        onFinalChange={onFinalChange}
      />
    </div>
  )
}

Range.displayName = 'Range'

Range.defaultProps = {
  color: '#548BF4',
  max: 100,
  min: 0,
  step: 1,
  onChange: () => {},
  onFinalChange: () => {},
}

Range.propTypes = {
  color: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  onFinalChange: PropTypes.func,
}

export default Range
