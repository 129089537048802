import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { Box, Flex, Icon, Modal } from 'Components/UI'
import { arrowDownGlyph } from 'Assets/Svg'

export const SortIcon = styled(Icon).attrs({
  glyph: arrowDownGlyph,
  size: 18,
  mr: 1,
})``

export const ActiveDot = styled(Box).attrs({
  width: '6px',
  height: '6px',
  mt: '-12px',
  ml: '1px',
})`
  border-radius: 50%;
`

export const SortOptionsContainer = styled(Flex).attrs({
  mr: 3,
})`
  cursor: pointer;
  align-items: center;

  &:hover {
    color: ${({ hovercolor }) => hovercolor};
  }
`

export const SortOptionsModal = styled(Modal).attrs({
  className: 'sort-options-modal',
})`
  overflow-y: auto;
  overflow-x: hidden;

  .sort-options-modal {
    padding: 24px;
    min-height: 150px;
  }

  svg {
    right: 14px;
    top: 14px;

    :hover {
      opacity: 0.7;
    }
  }

  @media screen and (max-width: 769px) {
    .sort-options-modal {
      margin: 0 12px;
      max-height: calc(100% - 24px);
    }
  }
`

export const SortOptionsModalContent = styled(Flex).attrs({
  width: ['100%', '100%', '350px'],
})`
  gap: 12px;
  flex-direction: column;
`

export const Title = styled(Box).attrs({})`
  font-size: 20px;
  font-weight: 700;
  cursor: default;
`

export const RadioHolder = styled(Flex).attrs({
  flexDirection: 'column',
  mt: 3,
})`
  gap: 14px;

  label {
    margin: 0;
    font-size: 16px;
    color: ${themeGet('colors.text')};
    width: fit-content;

    input {
      margin-right: 12px;
    }
  }
`
