import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {
  HORIZONTAL_ORIENTATION,
  VERTICAL_ORIENTATION,
  INFO_POSITION_TOP,
  INFO_POSITION_BOTTOM,
} from 'react-dates/constants'

import { get } from 'lodash'

import { useBaseTheme } from 'Hooks'
import Labels from 'Constants/labels'
import { DatePicker } from 'Components/UI'

import DatePickerIcon from './styles'

const propTypes = {
  focused: PropTypes.bool.isRequired,
  rounded: PropTypes.number,
  shadow: PropTypes.number,
  small: PropTypes.bool,
  onDateChange: PropTypes.func.isRequired,
  onFocusChange: PropTypes.func.isRequired,
}

const defaultProps = {
  rounded: 0,
  shadow: 0,
  small: true,
}

function SearchDatePicker(props) {
  const { shadow, small } = props
  const { labels, isPortableDevice } = useBaseTheme()

  const startOfToday = moment().startOf('day')
  const oneYearFromNow = moment().add('1', 'year')

  const handleIsOutsideRange = day => {
    return day.isBefore(startOfToday) || day.isAfter(oneYearFromNow)
  }

  return (
    <DatePicker
      calendarInfoPosition={
        isPortableDevice ? INFO_POSITION_TOP : INFO_POSITION_BOTTOM
      }
      customInputIcon={shadow && small ? null : <DatePickerIcon />}
      displayFormat="ddd, D MMM"
      enableOutsideDays
      hideKeyboardShortcutsPanel
      id="date_picker"
      isOutsideRange={handleIsOutsideRange}
      numberOfMonths={2}
      orientation={
        isPortableDevice ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION
      }
      placeholder={get(labels, Labels.PLACEHOLDER_DATE_PICKER, 'Any Date')}
      showClearDate
      verticalSpacing={12}
      withFullScreenPortal={isPortableDevice}
      withPortal={!isPortableDevice}
      {...props}
    />
  )
}

SearchDatePicker.propTypes = propTypes
SearchDatePicker.defaultProps = defaultProps

export default SearchDatePicker
