import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { withRouter } from 'react-router'

import { withAppContext } from 'Services/Context'

import { loadLocations } from 'Store/Actions/locations'
import { getLocations, isLoading } from 'Store/Selectors/locations'

import { setSearchValue, setCategoryValue } from 'Store/Actions/marketplace'

import LocationPopup from './LocationPopup'

const selector = createStructuredSelector({
  locations: getLocations,
  isLoading,
})

const actions = {
  onLoadLocations: loadLocations,
  onSetSearchValue: setSearchValue,
  onSetCategoryValue: setCategoryValue,
}

export default connect(
  selector,
  actions,
)(withAppContext(withRouter(LocationPopup)))
