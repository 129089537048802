import { all, select, takeLatest, delay } from 'redux-saga/effects'

import { IS_PRODUCTION } from 'Config/app'
import StorageKeys from 'Constants/storageKeys'

import { getAuthentication } from 'Store/Selectors/app'

const TIMEOUT = 1200000 // 20 minutes

function* watchActivity() {
  yield delay(TIMEOUT)
  const authentication = yield select(getAuthentication)

  if (authentication.require_auth && IS_PRODUCTION) {
    localStorage.removeItem(StorageKeys.WIDGET_TOKEN)
    // eslint-disable-next-line no-console
    console.log('Session timeout')
  }
}

export default function* session() {
  yield all([takeLatest('*', watchActivity)])
}
