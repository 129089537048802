import qs from 'qs'

import get from 'lodash/get'
import pick from 'lodash/pick'
import map from 'lodash/map'

export const getFullLocation = location =>
  `${get(location, 'pathname', '/')}${get(location, 'search', '')}${get(
    location,
    'hash',
    '',
  )}`

export const checkConfirmation = location => {
  const { auth_token: authToken, confirmation_token: confirmationToken } = pick(
    qs.parse(location.search.replace('?', '')),
    ['auth_token', 'confirmation_token'],
  )
  if (authToken && confirmationToken) {
    return {
      authToken,
      confirmationToken,
    }
  }
  return false
}

export const slugify = string => {
  if (!string) {
    return string
  }

  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  /* eslint-disable no-useless-escape */
  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export const getLocationHashParams = location => {
  return qs.parse(get(location, 'hash', '').replace('#', ''))
}

export const getTagIdsFromLocation = location => {
  const hash = getLocationHashParams(location)
  const tagIds = get(hash, 'tags', null)

  if (tagIds) {
    return map(tagIds.split(','), id => Number(id))
  }
  return null
}

export const getTagSlugFromLocation = location => {
  const hash = getLocationHashParams(location)
  const tagSlug = get(hash, 'tags', null)

  if (tagSlug) {
    return tagSlug.split(',')
  }
  return null
}

export const getCategoryProps = categoryValue => {
  const categoryId =
    get(categoryValue, 'value.category_2.bucket_id') ||
    get(categoryValue, 'value.bucket_id')
  const categoryFullName =
    get(categoryValue, 'value.category_2.bucket_name') ||
    get(categoryValue, 'value.bucket_name')
  const categoryType =
    get(categoryValue, 'value.category_2.bucket_type') ||
    get(categoryValue, 'value.bucket_type')

  return {
    categoryId,
    categoryFullName,
    categoryType,
  }
}

export const getSearchResultsGeoBucketPath = searchResults => {
  return searchResults?.meta
    ? 'meta.geographical_buckets'
    : 'geographical_buckets'
}

export const getSearchResultsPaginationPath = searchResults => {
  return searchResults?.meta ? 'meta' : 'pagination'
}
