import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import { _ } from 'Services/I18n'

import {
  RatingStarsContainer,
  ReviewAverage,
  ReviewTotal,
  StarIcon,
} from './styles'
import ReviewsWidgetModal from '../ReviewsWidgetModal'

function RatingStars({
  businessName,
  color,
  display,
  enableOnClick,
  reviews,
  size,
  ...rest
}) {
  const [showReviewsModal, setShowReviewsModal] = useState()

  if (reviews?.total === undefined) return null

  const reviewAverage = get(reviews, 'average')
  const reviewTotal = get(reviews, 'total')

  const totalLabel = `${
    reviewTotal > 1 ? _('common.reviews') : _('common.review')
  }`

  const handleShowReviewsWidget = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()

      // Do not show the modal if there are no reviews
      if (!reviewTotal) {
        return
      }

      setShowReviewsModal(true)
    },
    [reviews],
  )

  return (
    <>
      <RatingStarsContainer
        onClick={enableOnClick ? rest.onClick || handleShowReviewsWidget : null}
        {...rest}
      >
        <StarIcon fill={reviewTotal ? '#FDCC08' : `#bebebe`} size={size} />
        {reviewTotal ? (
          <>
            <ReviewAverage color={color} fontSize={size} mr={1}>
              {reviewAverage.toFixed(1)}
            </ReviewAverage>
            <ReviewTotal
              clickable={enableOnClick ? 1 : 0}
              color={color}
              display={display ? 'flex' : 'none'}
              fontSize={size}
            >
              ({reviewTotal}&nbsp;<span>{totalLabel}</span>)
            </ReviewTotal>
          </>
        ) : (
          <ReviewAverage color={color} fontSize={size} mr={1}>
            {_('common.new')}
          </ReviewAverage>
        )}
      </RatingStarsContainer>
      {showReviewsModal && (
        <ReviewsWidgetModal
          businessName={businessName}
          url={get(reviews, 'widgetUrl')}
          onClose={() => setShowReviewsModal(false)}
        />
      )}
    </>
  )
}

RatingStars.defaultProps = {
  businessName: '',
  color: 'black',
  display: true,
  enableOnClick: true,
  reviews: {},
  size: 24,
}

RatingStars.propTypes = {
  businessName: PropTypes.string,
  color: PropTypes.string,
  display: PropTypes.bool,
  enableOnClick: PropTypes.bool,
  reviews: PropTypes.object,
  size: PropTypes.number,
}

export default RatingStars
