import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'

import get from 'lodash/get'

import { withRouter } from 'react-router'

import { withAppContext } from 'Services/Context'
import GlobalStyle from 'Components/GlobalStyle'

import Footer from '../Footer'
import ErrorContent from '../ErrorContent'

import { Root, Container } from './styles'

class ErrorBoundary extends Component {
  state = { error: null }

  interval = null

  timeout = null

  componentDidCatch(error, errorInfo) {
    // TODO: fix when issue resolved https://github.com/smooth-code/loadable-components/issues/340
    if (error.message.indexOf('Loading chunk') > -1) {
      window.location.reload()
      return
    }
    this.setState({ error })
    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
    })
    Sentry.captureException(error)

    this.interval = setInterval(() => {
      const iframe = document.body.getElementsByTagName('iframe')[0]
      if (iframe) {
        iframe.style.display = 'none'
        clearInterval(this.interval)
      }
    }, 300)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    clearTimeout(this.timeout)
    const iframe = document.body.getElementsByTagName('iframe')[0]
    if (iframe) {
      iframe.style.display = 'initial'
    }
  }

  handleGoBack = event => {
    event.preventDefault()
    const { history } = this.props
    history.goBack()
    this.timeout = setTimeout(() => window.location.reload(), 1000)
  }

  handleReport = () => {
    Sentry.showReportDialog()
  }

  render() {
    const { error } = this.state
    const { baseTheme, children } = this.props
    const isChunkError = get(error, 'message', '').indexOf('Loading chunk') > -1

    const canShowError = error && !isChunkError

    if (!canShowError) {
      return <>{children}</>
    }

    return (
      <Root>
        <Container>
          <GlobalStyle
            background={get(baseTheme, 'colors.sencodary_background')}
          />
          <ErrorContent onBack={this.handleGoBack} />
          <Footer px={[20, 20, 48]} />
        </Container>
      </Root>
    )
  }
}

ErrorBoundary.propTypes = {
  baseTheme: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
}

ErrorBoundary.ErrorContent = ErrorContent

export default withRouter(withAppContext(ErrorBoundary))
