import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { withAppContext } from 'Services/Context'

import get from 'lodash/get'
import map from 'lodash/map'
import range from 'lodash/range'
import reduce from 'lodash/reduce'
import concat from 'lodash/concat'
import size from 'lodash/size'

import CategorySection from './CategorySection'

import { Container, Content } from './styles'

function CategoryDetail({ baseTheme, category }) {
  const data = useMemo(
    () =>
      reduce(
        range(3),
        (acc, index) => {
          const title = get(category, `section_${index + 1}_title`)
          const description = get(category, `section_${index + 1}_description`)

          if (title) {
            return concat(acc, { title, description })
          }
          return acc
        },
        [],
      ),
    [category],
  )

  const primaryColor = get(baseTheme, 'colors.primary_background')
  const secondaryColor = get(baseTheme, 'colors.secondary_background')

  if (!size(data)) {
    return null
  }

  return (
    <Container>
      <Content>
        {map(data, ({ title, description }, index) => (
          <CategorySection
            description={description}
            key={index}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            title={title}
          />
        ))}
      </Content>
    </Container>
  )
}

CategoryDetail.defaultProps = {
  baseTheme: {},
  category: {},
}

CategoryDetail.propTypes = {
  baseTheme: PropTypes.object,
  category: PropTypes.object,
}

export default withAppContext(CategoryDetail)
