import styled, { css } from 'styled-components'
import { space, fontSize } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { closeGlyph, chevronDownGlyph } from 'Assets/Svg'
import { Flex, Text, Icon } from 'Components/UI'

export const OptionTitle = styled(Text).attrs({
  is: 'span',
  fontSize: '14px',
})`
  color: ${themeGet('colors.gray')};

  align-items: center;
  display: flex;

  ${fontSize};
`

export const ClearIcon = styled(Icon).attrs({
  glyph: closeGlyph,
  size: 14,
  mr: 2,
})`
  cursor: pointer;
  fill: ${props => (props.color ? props.color : themeGet('colors.gray'))};
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  z-index: 1;
`

export const ClearButton = styled(Flex).attrs({
  mr: 2,
})`
  cursor: pointer;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  z-index: 2;
`

const checkedChevronCss = ({ checked }) =>
  checked &&
  css`
    transform: rotate(180deg);
  `

export const ChevronDown = styled(Icon).attrs({
  glyph: chevronDownGlyph,
  size: 12,
  ml: 2,
})`
  fill: gray;
  transition: 0.2s ease transform;
  ${checkedChevronCss};
`

export const Option = styled(Flex).attrs({
  py: 2,
  pl: 16,
  pr: 24,
  width: 1,
})`
  cursor: pointer;
  position: relative;

  align-items: center;
  display: flex;

  &:hover {
    background: ${({ color }) => color || themeGet('colors.tealBlue')};
    color: ${themeGet('colors.white')};
  }

  &:hover > span {
    color: ${themeGet('colors.white')};
  }

  &:hover > div > label > div {
    color: ${themeGet('colors.white')};
  }

  &:hover {
    ${OptionTitle} {
      color: ${themeGet('colors.white')};
    }

    ${ClearIcon}, ${ChevronDown} {
      fill: white !important;
    }
  }

  ${space};
`

export const RadioHolder = styled(Flex).attrs({
  alignItems: 'center',
  py: '0px',
})`
  > label {
    margin-bottom: 0;
    width: auto;

    input {
      margin-right: 14px;

      &:not(checked):after {
        border: 2px solid #cfd9e0;
        background-color: ${themeGet('colors.white')};
      }
    }

    > div {
      font-family: ${themeGet('font')};
      font-size: 12px;
      font-weight: 200;
      letter-spacing: 0.5px;
      width: calc(100% - 32px);
      color: ${themeGet('colors.gray')};
    }
  }
`

export const OptionHolder = styled(Flex).attrs({
  justifyContent: 'space-between',
  alignItems: 'center',
  width: 1,
})`
  position: relative;
`
