import styled from 'styled-components'

import { Box } from 'Components/UI'

export const Container = styled(Box)`
  height: 100%;
  width: 100%;

  .gm-style-iw-a {
    display: none;

    &::after {
      display: none;
    }
  }
`

export default {}
