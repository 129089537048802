import styled, { css } from 'styled-components'
import { Flex, Modal } from 'Components/UI'

const isLoadedCss = ({ isLoaded }) =>
  isLoaded &&
  css`
    > iframe {
      opacity: 1;
    }
  `

export const WidgetModal = styled(Modal).attrs({})`
  > div {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    box-shadow: none;
  }

  > svg {
    right: 0;
    top: -15px;
    fill: white;
    color: white;
    padding: 2px;
    width: 48px;
    height: 48px;
  }

  > iframe {
    height: 100%;
    width: 100%;
    opacity: 0;
  }

  ${isLoadedCss};
`

export const LoaderHolder = styled(Flex).attrs({
  justifyContent: 'center',
  alignItems: 'center',
})`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
