import React from 'react'

// https://github.com/traviswimer/react-hydration-provider
export default function useHydrated() {
  // Once useEffect() has been called, we know the app has been hydrated.
  const [hydrated, setHydrated] = React.useState(false)

  React.useEffect(() => {
    setHydrated(true)
  }, [])

  return hydrated
}
