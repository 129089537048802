import React, { useState } from 'react'
import { matchPath, useHistory } from 'react-router'
import PropTypes from 'prop-types'

import { useBaseTheme } from 'Hooks'
import { Hamburger } from 'Components/UI'
import { PUBLIC_PATHS } from 'Constants/paths'

import LocationPopup from '../LocationPopup'
import HeaderLinks from './HeaderLinks'
import MobileMenu from './MobileMenu'

import {
  Container,
  Content,
  AppLogo,
  AppLogoHolder,
  AppLogoWrapper,
  BackButtonHolder,
  BackButton,
  LocationIcon,
  LocationHolder,
  WishlistHolder,
  WishlistIcon,
  WishlistCountBadge,
} from './styles'

const Header = ({ wishlistCount }) => {
  const history = useHistory()

  const [showPopup, setShowPopup] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const handleShow = () => setShowPopup(!showPopup)
  const handleMobileMenuToggle = () => setShowMobileMenu(!showMobileMenu)

  const handleWishlistClick = () => {
    history.push(PUBLIC_PATHS.WISHLIST())
  }

  const handleGoBack = () => {
    history.goBack()
  }

  const handleGoHome = () => {
    history.push(PUBLIC_PATHS.ROOT)
  }

  const {
    marketplaceServiceType,
    showLocationsPopover,
    header,
    headerPrimaryColor,
    headerBackground,
    logoUrl,
  } = useBaseTheme()

  const canShowLocations = marketplaceServiceType && showLocationsPopover

  const isMobileBackButtonVisible = matchPath(history.location.pathname, {
    path: [
      PUBLIC_PATHS.MERCHANT(),
      PUBLIC_PATHS.SERVICE(),
      PUBLIC_PATHS.SEARCH_QUERY(),
      PUBLIC_PATHS.WISHLIST(),
    ],
    exact: true,
  })

  return (
    <Container background={headerBackground}>
      <Content isfullwidth={0} withlinks={header.length ? 1 : 0}>
        {isMobileBackButtonVisible && (
          <BackButtonHolder onClick={handleGoBack}>
            <BackButton fill={headerPrimaryColor} />
          </BackButtonHolder>
        )}

        <AppLogoWrapper
          mr={isMobileBackButtonVisible ? ['30px', '30px', 0] : 0}
        >
          <AppLogoHolder>
            <AppLogo alt="Nabooki" src={logoUrl} onClick={handleGoHome} />
          </AppLogoHolder>
        </AppLogoWrapper>

        {!!header.length && <HeaderLinks />}

        <WishlistHolder onClick={handleWishlistClick}>
          <WishlistIcon fill={headerPrimaryColor} ml="16px" size={24} />
          {wishlistCount ? (
            <WishlistCountBadge>{wishlistCount}</WishlistCountBadge>
          ) : null}
        </WishlistHolder>

        {canShowLocations ? (
          <LocationHolder
            className={showPopup && 'active ignore-react-onclickoutside'}
            onClick={handleShow}
          >
            <LocationIcon stroke={headerPrimaryColor} />
          </LocationHolder>
        ) : null}
        {showPopup && <LocationPopup onClose={handleShow} />}

        <Hamburger
          color={headerPrimaryColor}
          open={showMobileMenu}
          onOpen={handleMobileMenuToggle}
        />
        <MobileMenu open={showMobileMenu} onClose={handleMobileMenuToggle} />
      </Content>
    </Container>
  )
}

Header.propTypes = {
  wishlistCount: PropTypes.number.isRequired,
}

export default Header
