import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import { withRouter } from 'react-router'
import { withAppContext } from 'Services/Context'

import {
  RootContainer,
  NotFoundContent,
  NotFoundImage,
  Text,
  GoBackButton,
} from './styles'

const NotFound = props => {
  const { staticContext, baseTheme, history } = props

  if (staticContext) {
    staticContext.status = 404
  }

  const handleGoBack = history.goBack

  return (
    <RootContainer>
      <NotFoundContent>
        <NotFoundImage color={get(baseTheme, 'colors.primary_background')} />
        <Text>This page does not exist or is under construction...</Text>
        <GoBackButton
          background={get(baseTheme, 'colors.primary_background')}
          color={get(baseTheme, 'colors.primary_text')}
          onClick={handleGoBack}
        >
          Go back
        </GoBackButton>
      </NotFoundContent>
    </RootContainer>
  )
}

NotFound.defaultProps = {
  staticContext: null,
}

NotFound.propTypes = {
  baseTheme: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  staticContext: PropTypes.object,
}

export default withRouter(withAppContext(NotFound))
