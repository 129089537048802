import { createAsyncAction } from 'Store/utils'

import apiCall from 'Services/Api'

export const LOAD_WISHLIST = createAsyncAction('marketplace/LOAD_WISHLIST')

export const SET_WISHLIST_COUNT = 'marketplace/SET_WISHLIST_COUNT'

export const loadWishlist = payload =>
  apiCall({
    method: 'POST',
    endpoint: `/marketplace/wishlist`,
    types: LOAD_WISHLIST,
    cache: false,
    payload,
  })

export const setWishlistCount = count => ({
  type: SET_WISHLIST_COUNT,
  count,
})

export default {}
