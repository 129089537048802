import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import Collapse from 'react-css-collapse'
import { fontSize, fontWeight } from 'styled-system'
import { Flex } from 'Components/UI'

export const DetailCollapse = styled(Collapse).attrs({
  className: 'collapse-transition',
})`
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;

  padding: 10px;
  background: #f7f7f7;

  p:not(:last-child) {
    margin-bottom: 12px !important;
  }
`

const individualWrapperCss = ({ individual }) =>
  individual &&
  css`
    border: 1px solid ${themeGet('colors.lightGrey')};
    padding: 16px;
    border-radius: ${themeGet('borderRadius.1')};

    @media (hover: hover) {
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 9px 2px;
        transform: scale(1.02);
        transition:
          all 0.2s ease 0s,
          -webkit-transform 0.2s ease 0s;
      }
    }
  `

export const Wrapper = styled(Flex).attrs({
  flexDirection: 'column',
  margin: '12px 0',
  pt: '12px',
})`
  border-top: 1px solid ${themeGet('colors.lightGrey')};
  background-color: ${themeGet('colors.white')};

  &:last-child {
    margin-bottom: 0;
  }

  transform: none;
  transition:
    all 0.2s ease 0s,
    -webkit-transform 0.2s ease 0s;

  @media (hover: hover) {
    &:hover {
      transform: scale(1.01);
      transition:
        all 0.2s ease 0s,
        -webkit-transform 0.2s ease 0s;
    }
  }

  ${individualWrapperCss}
`

export const PrimaryContainer = styled(Flex).attrs({
  flexDirection: 'column',
  position: 'relative',
})`
  gap: 4px;
  cursor: pointer;
  color: ${themeGet('colors.text')};
`

export const Name = styled(Flex).attrs({
  alignItems: 'center',
  display: 'block',
})`
  color: ${themeGet('colors.header')};
  font-size: 14px;

  ${fontSize}
  ${fontWeight}

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    font-size: 14px;
  }
`

export const Duration = styled(Flex).attrs({
  order: 1,
})`
  line-height: 1;
  border-radius: ${themeGet('borderRadius.2')};
  background-color: #ddd;
  padding: 3px 10px;
  font-size: 12px;
`

export const Details = styled(Flex).attrs({
  alignItems: 'start',
})`
  gap: 6px;

  .details-left {
    gap: 4px;
  }
`
