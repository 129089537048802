import get from 'lodash/get'
import compact from 'lodash/compact'

import { slugify } from 'Services/Utils'
import SearchTypePersist from 'Services/SearchTypePersist'

export const ROOT_PATH = '/'
export const CATEGORIES = `${ROOT_PATH}categories`

export const PRIMARY_CATEGORY = primaryCategorySlug =>
  `${CATEGORIES}/${primaryCategorySlug || ':primaryCategorySlug'}`

export const SECONDARY_CATEGORY = (
  primaryCategorySlug,
  secondaryCategorySlug,
) =>
  `${PRIMARY_CATEGORY(primaryCategorySlug)}/${
    secondaryCategorySlug || ':secondaryCategorySlug?'
  }`

const searchQuery = (route, params) => {
  const searchTypePersist = new SearchTypePersist()
  const base = `${ROOT_PATH}${route}`

  // Route path definition
  if (!params) {
    return [
      `${base}/:bucketType(near)`,
      `${base}/:searchType/:bucketType(results)/:currentPage?`,
      `${base}/:searchType/:bucketType(online)/:currentPage?`,
      `${base}/:searchType/:bucketType(online)/:categoryId/:categoryFullName/:currentPage?`,
      `${base}/:searchType/:bucketType(category)/:categoryId/:categoryFullName/:currentPage?`,
      `${base}/:searchType/:bucketType/:bucketId/:bucketFullName/:currentPage?`,
      `${base}/:searchType/:bucketType/:bucketId/:bucketFullName/:categoryId/:categoryFullName/:currentPage?`,
    ]
  }

  if (params === 'near') {
    return `${base}/near`
  }

  const bucketType = get(params, 'bucketType')
  const bucketId = get(params, 'bucketId')
  const bucketFullName = get(params, 'bucketFullName')
  const bucketName = get(params, 'bucketName')
  const categoryId = get(params, 'categoryId')
  const categoryFullName = get(params, 'categoryFullName')
  const currentPage = get(params, 'currentPage', 1)
  const searchType = get(params, 'searchType', searchTypePersist.get())

  // If search type has been passed in, then store it
  if (get(params, 'searchType')) {
    searchTypePersist.set(searchType)
  }

  if (bucketType === 'category') {
    const categoriesRoutePath = compact([
      base,
      searchType,
      bucketType,
      bucketId || categoryId,
      slugify(bucketName) ||
        slugify(bucketFullName) ||
        slugify(categoryFullName),
      currentPage,
    ])

    return categoriesRoutePath.join('/')
  }

  if (bucketType === 'results') {
    const resultsRoutePath = compact([
      base,
      searchType,
      bucketType,
      currentPage,
    ])

    return resultsRoutePath.join('/')
  }

  if (bucketType === 'online') {
    const onlineRoutePath = compact([
      base,
      searchType,
      bucketType,
      categoryId,
      slugify(categoryFullName),
      currentPage,
    ])

    return onlineRoutePath.join('/')
  }

  const routePath = compact([
    base,
    searchType,
    bucketType,
    bucketId,
    slugify(bucketFullName),
    categoryId,
    slugify(categoryFullName),
    currentPage,
  ])

  return routePath.join('/')
}

const getMerchantPath = params => {
  const id = get(params, 'id', null)
  const slug = get(params, 'slug', null)

  // Route path definitions
  if (!params) {
    return [`${ROOT_PATH}business/:id/:slug`]
  }

  return `${ROOT_PATH}business/${id || ':id'}/${slug || ':slug'}`
}

const getServicePath = params => {
  const id = get(params, 'id', null)
  const slug = get(params, 'slug', null)

  // Route path definitions
  if (!params) {
    return [`${ROOT_PATH}service/:id/:slug`]
  }

  return `${ROOT_PATH}service/${id || ':id'}/${slug || ':slug'}`
}

const getCategoryPath = params => {
  const primaryCategorySlug = get(
    params,
    'primaryCategorySlug',
    ':primaryCategorySlug',
  )
  const secondaryCategorySlug = get(
    params,
    'secondaryCategorySlug',
    ':secondaryCategorySlug',
  )

  return `${CATEGORIES}/${primaryCategorySlug}/${secondaryCategorySlug}`
}

// Public paths
export const PUBLIC_PATHS = {
  ROOT: `${ROOT_PATH}`,
  SEARCH_QUERY: params => searchQuery('search', params),
  MERCHANT: params => getMerchantPath(params),
  SERVICE: params => getServicePath(params),
  CATEGORIES,
  CATEGORY: params => getCategoryPath(params),
  PRIMARY_CATEGORY,
  SECONDARY_CATEGORY,
  REDIRECTOR: () => `${ROOT_PATH}redirector`,
  WISHLIST: () => `${ROOT_PATH}wishlist`,
}
