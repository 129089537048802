import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Flex } from 'Components/UI'
import { CONTENT_PADDING_X } from 'Constants/ids'

const fixheightCss = ({ fixheight }) =>
  fixheight &&
  css`
    min-height: unset !important;
    max-height: unset !important;
    height: ${fixheight} !important;
  `

const sizes = {
  desktop: {
    full: '760px',
    two_third: '500px',
    half: '392px',
    a_third: '250px',
  },
  mobile: {
    full: '392px',
    two_third: '250px',
    half: '196px',
    a_third: '125px',
  },
}

export const Container = styled(Flex).attrs({
  flexDirection: 'column',
  px: CONTENT_PADDING_X,
})`
  position: relative;
  background: ${props => `url(${props.bgurl})`} center center;
  background-repeat: no-repeat;
  background-size: cover;

  align-items: center;
  justify-content: center;
  height: ${({ size }) => sizes.desktop[size] || '760px'};
  min-height: 250px;
  width: 100%;

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    height: ${({ size }) => sizes.mobile[size] || '392px'};
    min-height: ${({ size }) => (size === 'half' ? 'initial' : '392px')};
    background-position: center center;
  }

  ${fixheightCss};
`

export default {}
