import styled, { css } from 'styled-components'
import { color } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { closeGlyph, chevronDownGlyph } from 'Assets/Svg'
import { Flex, Box, Icon } from 'Components/UI'

export const Container = styled(Box).attrs({
  px: [3, 3, 24],
  pt: [3, 3, 24],
})`
  background-color: ${themeGet('colors.white')};
`

export const Header = styled(Flex).attrs({
  justifyContent: 'space-between',
  alignItems: 'center',
})`
  width: 100%;
`

export const LabelHolder = styled(Flex).attrs({
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: 1,
})`
  cursor: pointer;
`

export const Label = styled(Box).attrs({})`
  font-size: 14px;
  font-weight: 700;
  color: ${themeGet('color.black')};
  line-height: 1;
  text-transform: lowercase;

  &:first-letter {
    text-transform: capitalize;
  }
`

export const Button = styled(Box).attrs({})`
  font-size: 14px;
  letter-spacing: 0.5px;
  color: ${themeGet('colors.tealBlue')};
  cursor: pointer;

  ${color};
`

export const Content = styled(Box).attrs({
  pt: 20,
  pb: 2,
})`
  border-bottom: 1px solid ${themeGet('colors.concrete')};
`

export const Item = styled(Flex).attrs(({ alignItems }) => ({
  alignItems: alignItems || 'flex-start',
  pb: '12px',
}))`
  label {
    margin-bottom: 0;
    align-items: flex-start;
    width: auto;

    input {
      margin-right: 14px;

      &:not(checked):after {
        border: 2px solid #cfd9e0;
        background-color: ${themeGet('colors.white')};
      }
    }

    > div {
      font-family: ${themeGet('font')};
      font-size: 14px;
      font-weight: 200;
      letter-spacing: 0.5px;
      color: ${themeGet('colors.black')};
    }
  }
`

export const ItemName = styled(Box).attrs({})`
  font-size: 14px;
  letter-spacing: 0.5px;
  cursor: pointer;
  flex: 1;
  line-height: 1.2;
  color: ${themeGet('colors.text')};
`

export const ItemCount = styled(ItemName).attrs({
  ml: 2,
})`
  flex: 0 !important;
`

export const RadioOptions = styled(Box).attrs({
  width: 1,
})``

export const RadioHolder = styled(Flex).attrs({
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: 1,
})`
  position: relative;
`

export const SubcategoryHolder = styled(Box).attrs({
  mt: 2,
})`
  > label {
    margin-top: 12px;
  }
`

export const ClearIcon = styled(Icon).attrs({
  glyph: closeGlyph,
  size: 14,
})`
  cursor: pointer;
  fill: ${props => (props.color ? props.color : themeGet('colors.gray'))};

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
`

const checkedChevronCss = ({ checked }) =>
  checked &&
  css`
    transform: rotate(180deg);
  `

export const ChevronDown = styled(Icon).attrs({
  glyph: chevronDownGlyph,
  size: 12,
  ml: 2,
})`
  cursor: pointer;
  transition: 0.2s ease transform;

  &:hover {
    fill: ${props => (props.color ? props.color : themeGet('colors.gray'))};
  }

  ${checkedChevronCss};
`
