import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getCategory, getAllCategories } from 'Store/Selectors/marketplace'

import BannerCategories from './BannerCategories'

const selector = createStructuredSelector({
  category: getCategory,
  allCategories: getAllCategories,
})

export default connect(selector, null)(BannerCategories)
