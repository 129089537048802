import StorageKeys from 'Constants/storageKeys'

class SearchTypePersist {
  static instance = null

  constructor() {
    if (SearchTypePersist.instance) {
      return SearchTypePersist.instance
    }

    try {
      this.searchType = localStorage.getItem(StorageKeys.SEARCH_TYPE) || ''
    } catch {
      this.searchType = ''
    }

    SearchTypePersist.instance = this
  }

  set(searchType) {
    this.searchType = searchType

    try {
      localStorage.setItem(StorageKeys.SEARCH_TYPE, searchType)
    } catch {
      // eslint-disable-next-line
      console.error(
        'Unable to save search type due to local storage not accessible.',
      )
    }
  }

  get() {
    return this.searchType
  }
}

export default SearchTypePersist
