import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import LazyLoad from 'react-lazyload'

import get from 'lodash/get'

import { CARD_IMAGE_HEIGHT } from 'Theme/constants'
import { PUBLIC_PATHS } from 'Constants/paths'
import { WISHLIST_TYPE } from 'Constants/ids'

import { _ } from 'Services/I18n'

import { useBaseTheme } from 'Hooks'

import { globeGlyph } from 'Assets/Svg'
import { MapPinIcon } from 'Assets/Svg/General'
import { Text } from 'Components/UI'

import PromotionCategories from '../PromotionCategories'
import RatingStars from '../RatingStars'
import WishlistToggle from '../WishlistToggle'

import {
  Container,
  Image,
  Content,
  Title,
  Location,
  ImageHolder,
  Overlay,
  ArrowIcon,
  Show,
} from './styles'

import { LocationIcon } from '../MerchantCard/styles'

function MerchantGridCard({
  goToPromotion,
  innerRef,
  landscape,
  merchant,
  scrollContainerClass,
  withShowMap,
  onShowOnMap,
  ...rest
}) {
  const history = useHistory()
  const { primaryColor, marketplaceType } = useBaseTheme()

  const data = useMemo(() => {
    const imageUrl = get(merchant, 'images.orig.main_url')
    const name = get(merchant, 'name')

    const multipleLocations = get(merchant, 'multiple_locations', false)
    const appended = get(merchant, 'appended', false)

    const bucketName = multipleLocations
      ? 'Multiple locations'
      : `${get(merchant, 'geographical_buckets.suburb.bucket_name')}, ${get(
          merchant,
          'geographical_buckets.state.bucket_name',
        )}`

    const locationText = appended ? _('common.onlineService') : bucketName

    const defaultCategories = get(merchant, 'default_categories', [])

    return {
      appended,
      imageUrl,
      name,
      locationText,
      defaultCategories,
    }
  }, [merchant, marketplaceType])

  const { appended, imageUrl, name, locationText, defaultCategories } = data

  const handleGoToMerchant = useCallback(() => {
    const path = goToPromotion
      ? PUBLIC_PATHS.SERVICE({
          id: get(merchant, 'promotion.token'),
          slug: get(merchant, 'promotion.slug'),
        })
      : PUBLIC_PATHS.MERCHANT({
          id: get(merchant, 'token'),
          slug: get(merchant, 'slug'),
          marketplaceType,
        })

    history.push(path, {
      ...history.location.state,
      prevPath: history.location.pathname,
    })
  }, [merchant])

  const merchantReviews = get(merchant, 'reviews')

  const handleShowOnMap = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()

      onShowOnMap(merchant)
    },
    [merchant],
  )

  return (
    <Container
      landscape={landscape ? 1 : 0}
      ref={innerRef}
      onClick={handleGoToMerchant}
      {...rest}
    >
      <ImageHolder landscape={landscape ? 1 : 0}>
        {withShowMap && (
          <Overlay onClick={handleShowOnMap}>
            <Show>{_('common.showOnMap')}</Show>
            <ArrowIcon />
          </Overlay>
        )}
        <LazyLoad
          height={
            landscape ? CARD_IMAGE_HEIGHT.LANDSCAPE : CARD_IMAGE_HEIGHT.GRID
          }
          offset={100}
          once
          scrollContainer={scrollContainerClass}
          style={{
            height: landscape
              ? CARD_IMAGE_HEIGHT.LANDSCAPE
              : CARD_IMAGE_HEIGHT.GRID,
          }}
        >
          <Image landscape={landscape ? 1 : 0} src={imageUrl} />
        </LazyLoad>
        {goToPromotion ? (
          <WishlistToggle
            token={get(merchant, 'promotion.token')}
            wishlistType={WISHLIST_TYPE.SERVICE}
          />
        ) : (
          <WishlistToggle token={merchant.token} />
        )}
      </ImageHolder>
      <Content landscape={landscape ? 1 : 0}>
        <div>
          <Title landscape={landscape ? 1 : 0}>{name}</Title>
        </div>

        <RatingStars
          businessName={name}
          color="#52525b"
          reviews={merchantReviews}
          size={landscape ? 12 : 14}
        />

        <Location color="#52525b">
          <LocationIcon
            color={primaryColor}
            glyph={appended ? globeGlyph : MapPinIcon}
          />
          <Text fontSize={landscape ? '12px' : '14px'} lineHeight={1.25}>
            {locationText}
          </Text>
        </Location>

        <PromotionCategories
          alignItems="flex-end"
          categories={defaultCategories}
          flex="1"
          mb={0}
        />
      </Content>
    </Container>
  )
}

MerchantGridCard.defaultProps = {
  goToPromotion: false,
  innerRef: null,
  landscape: false,
  scrollContainerClass: '',
  withShowMap: false,
  onShowOnMap: () => {},
}

MerchantGridCard.propTypes = {
  goToPromotion: PropTypes.bool,
  innerRef: PropTypes.object,
  landscape: PropTypes.bool,
  merchant: PropTypes.object.isRequired,
  scrollContainerClass: PropTypes.string,
  withShowMap: PropTypes.bool,
  onShowOnMap: PropTypes.func,
}

export default React.memo(MerchantGridCard)
