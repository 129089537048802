import styled from 'styled-components'

import { Flex, Modal, Text } from 'Components/UI'

export const ReviewsModal = styled(Modal).attrs({
  className: 'reviews-widget-modal',
})`
  overflow: hidden;

  .reviews-widget-modal {
    padding: 0 16px 0 0;
    padding: 0;

    > svg {
      width: 40px;
      height: 40px;
      top: 6px;
    }
  }

  @media screen and (max-width: 769px) {
    .reviews-widget-modal {
      margin: 0 12px;
      max-height: calc(100% - 24px);
      width: 100%;
    }
  }
`

export const ReviewsModalContent = styled(Flex).attrs({
  width: ['100%', '100%', '740px', '940px'],
})`
  flex-direction: column;
  max-height: calc(100vh - 150px);
  max-height: calc(100dvh - 150px);
  overflow: auto;

  @media screen and (max-width: 769px) {
    max-height: calc(100vh - 80px);
    max-height: calc(100dvh - 80px);
  }
`

export const LoaderHolder = styled(Flex).attrs({
  justifyContent: 'center',
  alignItems: 'center',
})`
  margin-top: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const Title = styled(Text).attrs({
  fontSize: ['18px', '18px', '24px'],
})`
  font-weight: 700;
  letter-spacing: 0.6px;
  width: 100%;
  padding: 16px 1.5rem;
  border-bottom: 2px solid #e2e8f0;
`
