import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { chevronDownGlyph } from 'Assets/Svg'

import Flex from '../Flex'
import Box from '../Box'
import Icon from '../Icon'

const selectedCss = props =>
  props.selected &&
  css`
    background-color: ${themeGet('colors.tealBlue')};
    color: ${themeGet('colors.white')};
  `

export const StyledOption = styled(Box)`
  padding: 8px 24px;
  border-bottom: 1px solid ${themeGet('colors.whiteHover')};
  transition: all ${themeGet('transitionTime')} ease;
  user-select: none;

  :last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  :first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding-top: 12px;
  }

  &:hover {
    color: ${themeGet('colors.curiousBlue')};
  }

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    border-bottom-color: ${themeGet('colors.whiteHover')};
  }

  ${selectedCss};
`

const leftCss = () => props => {
  return props.left
    ? css`
        left: 0px;
      `
    : css`
        left: unset;
        right: -8px;
      `
}

const topCss = () => props => {
  return props.top
    ? css`
        bottom: 100%;
      `
    : css`
        top: calc(100%);
      `
}

export const Menu = styled(Box)`
  background-color: ${themeGet('colors.white')};
  box-shadow: 0 -1px 19px 0 rgba(0, 0, 0, 0.17) !important;
  border: none !important;
  position: absolute;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  border-radius: ${themeGet('borderRadius.1')} !important;
  text-align: left;

  ${topCss()};
  ${leftCss()};
`

export const Opener = styled(Box)`
  align-items: center;
  display: inline-flex;
  width: 100%;
`

export const StyledDropdown = styled(Box)`
  align-items: center;
  display: inline-flex;
  position: relative;
  color: ${themeGet('colors.font.primary')};
  font-family: ${themeGet('font')};
  margin: 0;
  overflow: visible;
  text-transform: none;
  box-shadow: none;
  cursor: pointer;
  transition: all ${themeGet('transitionTime')};
  white-space: nowrap;
`

const openedCss = props =>
  props.opened &&
  css`
    transform: rotate(180deg);
  `

export const PickerWrapper = styled(Flex).attrs({
  p: 2,
  fontSize: 2,
})`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: ${({ inverse }) =>
    inverse ? themeGet('colors.blueZodiac') : themeGet('colors.white')};
  color: ${({ color }) => color && themeGet(`colors.${color}`)};

  > svg {
    margin-left: 4px;
    transition: all 270ms ease;
    transform: rotate(0deg);

    ${openedCss};
  }

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    border-bottom-color: ${themeGet('colors.whiteHover')};
  }
`

const checkedChevronCss = ({ opened }) =>
  opened &&
  css`
    transform: rotate(180deg);
  `

export const ChevronDown = styled(Icon).attrs({
  glyph: chevronDownGlyph,
  size: 12,
  ml: 2,
})`
  fill: gray;
  transition: 0.2s ease transform;

  ${checkedChevronCss};
`
