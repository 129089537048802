import ReactModal from 'react-modal'
import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { closeGlyph } from 'Assets/Svg'

import withStyledClassNames from './withStyledClassNames'
import Icon from '../Icon'
import Flex from '../Flex'

export const Close = styled(Icon).attrs({
  glyph: closeGlyph,
})`
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 6px;
  cursor: pointer;
  z-index: 99999999;
  width: 32px;
  height: 32px;

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    width: 36px;
    height: 36px;
  }
`

export const Container = withStyledClassNames(
  {
    className: {
      base: css`
        outline: none;
        opacity: 0;
        transform: scale(0.4);
        max-height: 96%;
        border-radius: 6px;
        padding: 32px 50px;
        min-height: 200px;
        min-width: 280px;
        background: ${themeGet('colors.white')};
        box-shadow: 10px 10px 20px -10px ${themeGet('colors.blackOpacity')};
        margin: 0 16px;
        position: relative;

        transition: all ${themeGet('transitionTime')};
        transition-delay: 100ms;

        @media screen and (max-width: ${themeGet('breakpoints.1')}) {
          padding: 16px 32px;
          min-height: 200px;
        }
      `,
      afterOpen: css`
        opacity: 1;
        transform: scale(1);
      `,
      beforeClose: css`
        opacity: 0;
      `,
    },
    overlayClassName: {
      base: css`
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${themeGet('colors.blackOpacity')};
        z-index: 99999998;
        width: 100%;
        height: 100%;
        outline: none;
        opacity: 0;

        transition: all ${themeGet('transitionTime')};
      `,
      afterOpen: css`
        opacity: 1;
      `,
      beforeClose: css`
        opacity: 0;
      `,
    },
  },
  ReactModal,
)

export const Wrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  outline: none;
  min-height: 240px;
  min-width: 320px;
  background-color: ${themeGet('colors.white')};
`

export const Text = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 399px;
  flex: 1 0;
  font-family: ${themeGet('font')};
  font-size: 18px;
  font-weight: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;

  > b {
    font-weight: 500;
  }
`

export const Buttons = styled(Flex)`
  justify-content: space-between;
  width: 100%;
`
