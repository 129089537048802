import styled from 'styled-components'
import Slider from 'react-slick'

import { Box } from 'Components/UI'

export const Container = styled(Box)`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  width: 100%;
`

export const FeaturedLocationsContainer = styled(Box).attrs({
  display: ['none', 'none', 'block', 'block'],
})`
  width: 100%;
`

export const FeaturedLocationsMobileContainer = styled(Box).attrs({
  display: ['block', 'block', 'none', 'none'],
  mx: [-16, -16, 0, 0],
})`
  overflow: hidden;
`
export const FeaturedLocationsMobileSlider = styled(Slider).attrs({})``
