import styled, { css } from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'
import { themeGet } from '@styled-system/theme-get'

import Flex from '../Flex'

const checkedCss = props =>
  props.checked &&
  css`
    opacity: 1;
  `

const afterCheckedCss = props =>
  props.checked &&
  css`
    background-color: ${({ color }) => color || themeGet('colors.tealBlue')};
    border: 1px solid ${({ color }) => color || themeGet('colors.tealBlue')};
  `

export const Container = styled(Flex).attrs({
  is: 'label',
})`
  display: inline-flex;
  align-items: center;
  flex-grow: 0;
  position: relative;
  cursor: pointer;
  height: 16px;
  padding-left: 32px;
  user-select: none;
  font-size: 12px;
  border-radius: 4px;

  color: ${props =>
    props.light ? themeGet('colors.white') : themeGet('colors.font.primary')};

  > input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }

  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    z-index: 2;
    background: ${props =>
      props.light ? themeGet('colors.clear') : themeGet('colors.white')};
    border: 2px solid
      ${props => (props.light ? themeGet('colors.white') : '#cfd9e0')};
    border-radius: 4px;

    ${afterCheckedCss};
  }

  :before {
    cursor: pointer;
    opacity: 0;
    content: '';
    position: absolute;
    width: 8px;
    height: 5px;
    top: 4px;
    left: 4px;
    border: 2px solid ${themeGet('colors.white')};
    border-top: none;
    border-right: none;
    z-index: 3;

    transform: rotate(-45deg);

    ${checkedCss};
  }

  :hover:before {
    opacity: 0.1;

    ${checkedCss};
  }

  > input[type='checkbox']:checked {
    border: 2px solid #0e34a0;

    :after {
      opacity: 1;
      border: 2px solid #0e34a0;
      border-top: none;
      border-right: none;
    }
  }
`

const Checkbox = ({ checked, id, name, onChange, children, ...rest }) => (
  <Container checked={checked} htmlFor={id} {...rest}>
    <input
      checked={checked}
      id={id}
      name={name}
      type="checkbox"
      onChange={onChange}
    />
    {children}
  </Container>
)

Checkbox.defaultProps = {
  checked: false,
  children: null,
  name: '',
  onChange: () => {},
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
}

export default Checkbox
