import React, { useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { useLocation } from 'react-router'
import { useBaseTheme } from 'Hooks'

import { Range } from 'Components/UI'

import { Container, Content, Header, Label, ValueLabel } from './styles'

const STEP = 5
const MIN = 5
const MAX = 100

const FilterRange = ({ onChange }) => {
  const location = useLocation()
  const { primaryColor } = useBaseTheme()
  const [value, setValue] = useState([
    get(location, 'state.distanceFilter', MAX),
  ])

  return (
    <Container>
      <Header>
        <Label>Filter by distance</Label>
        <ValueLabel>{value[0]} km</ValueLabel>
      </Header>
      <Content>
        <Range
          color={primaryColor}
          max={MAX}
          min={MIN}
          step={STEP}
          value={value}
          onChange={values => setValue(values)}
          onFinalChange={values => onChange(values[0])}
        />
      </Content>
    </Container>
  )
}

FilterRange.defaultProps = {
  onChange: () => {},
}

FilterRange.propTypes = {
  onChange: PropTypes.func,
}

export default FilterRange
