import { createSelector } from 'reselect'

export const getState = state => state.persist

export const getTheme = createSelector(getState, state => state.theme)

export const getCookiesAccepted = createSelector(
  getState,
  state => state.cookiesAccepted,
)
