import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { mapToTheme } from 'styled-map'

import { Flex, Box } from 'Components/UI'

export const Wrapper = styled(Box).attrs()`
  transform: none;
  transition:
    all 0.2s ease 0s,
    -webkit-transform 0.2s ease 0s;

  &:hover {
    transform: scale(1.01);
    transition:
      all 0.2s ease 0s,
      -webkit-transform 0.2s ease 0s;
  }
`

const borderRadiusCss = ({ roundedborder, circleborder }) => {
  if (circleborder) {
    return css`
      border-radius: ${circleborder}%;
      margin-top: 2px;
    `
  }

  return css`
    border-radius: ${roundedborder
      ? themeGet('borderRadius.2')
      : themeGet('borderRadius.1')};
  `
}

export const Container = styled(Flex).attrs({
  flexDirection: 'column',
  alignItems: 'center',
})`
  background: ${themeGet('colors.white')};
  width: 100%;
  padding-top: 100%;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  ${borderRadiusCss};

  &:hover {
    .category-title {
      color: ${({ hovercolor }) => hovercolor};
    }
  }
`

export const ImageBgHolder = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`
export const ImageBg = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background: ${({ src }) => `url(${src})`} center center;
  background-repeat: no-repeat;
  background-size: cover;
`

export const OverlayBg = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background-color: ${themeGet('colors.black')};
  opacity: 0.2;
`

export const TitleHolder = styled(Flex).attrs({
  width: 1,
  px: 3,
  alignItems: 'center',
})`
  height: ${({ customheight }) => customheight || 72}px;
  background-color: transparent;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: ${({ align }) => align};
`

export const Title = styled(Box)`
  position: relative;
  z-index: 1;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  color: ${({ color }) => color || 'white'};
`

export const TitleBg = styled(Box)`
  position: absolute;
  top: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  transition: all ${themeGet('transitionTime')} ease;
  background-color: ${({ color }) => color || mapToTheme('buttons.bg')};

  ${Container}:hover & {
    top: 0;
  }
`
