import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import map from 'lodash/map'
import first from 'lodash/first'
import size from 'lodash/size'
import isEmpty from 'lodash/isEmpty'

import onClickOutside from 'react-onclickoutside'

import { processBucketForSearchPathParams } from 'Services/Utils/merchants'
import { PUBLIC_PATHS } from 'Constants/paths'
import { Flex, Loader, Text } from 'Components/UI'

import {
  Container,
  Wrapper,
  Content,
  SectionTitle,
  LocationItem,
  Arrow,
  Count,
  Footer,
} from './styles'

class LocationPopup extends PureComponent {
  state = {
    selected: null,
  }

  componentDidMount() {
    const { locations, onLoadLocations } = this.props

    if (!size(locations)) {
      onLoadLocations()
    }
  }

  handleChooseLocation = selected => () => {
    this.setState({ selected })
  }

  handleClose = () => {
    const { onClose } = this.props
    onClose()
  }

  handleSetSearchValue = value => {
    const { onSetSearchValue, onSetCategoryValue } = this.props

    onSetCategoryValue(null)

    onSetSearchValue({
      label: value.bucket_name,
      type: value.bucket_type,
      value,
    })
  }

  handleGoPlace = bucket => () => {
    const { history } = this.props
    const queryParams = processBucketForSearchPathParams(bucket, 1)

    this.handleSetSearchValue(bucket)

    history.push(PUBLIC_PATHS.SEARCH_QUERY(queryParams))
    this.handleClose()
  }

  render() {
    const { selected } = this.state
    const { baseTheme, isLoading, locations } = this.props
    const rounded = get(baseTheme, 'colors.rounded_border')
    const secondaryColor = get(baseTheme, 'colors.secondary_background')

    const selectedLocation = selected || first(locations)

    if (isLoading) {
      return (
        <Container rounded={rounded ? 1 : 0}>
          <Wrapper>
            <Loader color={secondaryColor} />
          </Wrapper>
        </Container>
      )
    }

    if (!isLoading && isEmpty(locations)) {
      return null
    }

    return (
      <Container rounded={rounded ? 1 : 0}>
        <Flex>
          <Wrapper width={['120px', '170px']}>
            <SectionTitle>Location</SectionTitle>
            <Content>
              {map(locations, item => {
                const isSelected =
                  get(selectedLocation, 'bucket_id') === get(item, 'bucket_id')

                return (
                  <LocationItem
                    color={secondaryColor}
                    key={item.bucket_id}
                    selected={isSelected ? 1 : 0}
                    onClick={this.handleChooseLocation(item)}
                  >
                    <Text ellipsis={1} fontSize={15}>
                      {item.full_name}
                    </Text>
                    <Arrow
                      color={secondaryColor}
                      selected={isSelected ? 1 : 0}
                    />
                  </LocationItem>
                )
              })}
            </Content>
          </Wrapper>

          <Wrapper width="100%">
            <SectionTitle>Region</SectionTitle>
            <Content>
              {map(get(selectedLocation, 'region'), item => (
                <LocationItem
                  key={item.bucket_id}
                  onClick={this.handleGoPlace(item)}
                >
                  <Text ellipsis={1} fontSize={15}>
                    {item.bucket_name}
                  </Text>
                  <Count>{item.merchants_count}</Count>
                </LocationItem>
              ))}
            </Content>
          </Wrapper>
        </Flex>

        <Footer color={secondaryColor} />
      </Container>
    )
  }
}

const LocationType = {
  bucket_id: PropTypes.number.isRequired,
  bucket_name: PropTypes.string.isRequired,
  bucket_slug: PropTypes.string.isRequired,
  bucket_type: PropTypes.string.isRequired,
  full_name: PropTypes.string.isRequired,
  merchants_count: PropTypes.number,
}

LocationPopup.propTypes = {
  baseTheme: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      ...LocationType,
      region: PropTypes.arrayOf(PropTypes.shape(LocationType)).isRequired,
    }).isRequired,
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  onLoadLocations: PropTypes.func.isRequired,
  onSetCategoryValue: PropTypes.func.isRequired,
  onSetSearchValue: PropTypes.func.isRequired,
}

const clickOutsideConfig = {
  handleClickOutside: instance => {
    return instance.handleClose
  },
}

export default onClickOutside(LocationPopup, clickOutsideConfig)
