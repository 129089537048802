import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Flex, Box, Button } from 'Components/UI'

export const Container = styled(Flex).attrs({
  justifyContent: 'center',
  width: 1,
  px: 2,
})`
  flex-wrap: wrap;
`

const borderRadiusCss = ({ rounded }) => css`
  border-radius: ${rounded
    ? themeGet('borderRadius.2')
    : themeGet('borderRadius.1')};
`

const activeCss = ({ active, color }) =>
  active &&
  css`
    color: ${color || themeGet('colors.primary')};
    background-color: ${themeGet('colors.white')};

    &:hover {
      color: ${color || themeGet('colors.primary')} !important;
    }
  `

export const A = styled(Box).attrs({
  as: 'a',
  mx: 2,
  mb: 3,
})`
  text-decoration: none;
`

export const StyledButton = styled(Button)`
  color: ${themeGet('colors.white')};
  border: 2px solid ${themeGet('colors.white')};
  background-color: rgba(0, 0, 0, 0.2);
  height: 40px;
  font-size: 14px;
  padding: 0 32px;

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    padding: 0 16px;
  }

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    padding: 0 8px;
    font-size: 12px;
    height: 26px;
  }

  ${borderRadiusCss}
  ${activeCss};
`
