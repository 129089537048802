import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { WISHLIST_TYPE } from 'Constants/ids'
import WishlistPersist from 'Services/WishlistPersist'

import { WishlistOverlay, WishlistIcon } from './styles'

function WishlistToggle({ token, wishlistType }) {
  const wishlistPersist = new WishlistPersist()

  const dispatch = useDispatch()

  const [isWishlisted, setIsWishlisted] = useState(
    wishlistPersist.exists(token, wishlistType),
  )

  const handleToggleWishlist = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()

      setIsWishlisted(wishlistPersist.toggle(token, dispatch, wishlistType))
    },
    [token],
  )

  const renderWishlistIcon = () => {
    return (
      token && (
        <WishlistIcon
          active={isWishlisted ? 1 : 0}
          size={20}
          onClick={handleToggleWishlist}
        />
      )
    )
  }

  return <WishlistOverlay>{renderWishlistIcon()}</WishlistOverlay>
}

WishlistToggle.defaultProps = {
  token: null,
  wishlistType: WISHLIST_TYPE.MERCHANT,
}

WishlistToggle.propTypes = {
  token: PropTypes.string,
  wishlistType: PropTypes.string,
}

export default WishlistToggle
