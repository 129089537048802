import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'

import * as Utils from 'Services/Utils'

import Categories from './Categories'

import { Container } from './styles'

function DefaultCategories({ categoryValue, categories, active, ...rest }) {
  const { categoryFullName, categoryType, categoryId } =
    Utils.getCategoryProps(categoryValue)

  const data = useMemo(() => {
    const sortedCategories = sortBy(categories, 'supplementary')

    if (categoryType === 'category_2') {
      return filter(sortedCategories, ['name', categoryFullName])
    }

    if (categoryType === 'category_1') {
      return filter(sortedCategories, ['category_1_id', categoryId])
    }

    return sortedCategories
  }, [categories, categoryFullName])

  return (
    <Container {...rest}>
      <Categories active={active} categories={data} />
    </Container>
  )
}

DefaultCategories.defaultProps = {
  active: false,
  categories: [],
  categoryValue: null,
}

DefaultCategories.propTypes = {
  active: PropTypes.bool,
  categories: PropTypes.array,
  categoryValue: PropTypes.object,
}

export default DefaultCategories
