import styled, { css } from 'styled-components'
import { background } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import { getColorFromBg } from 'Theme'

import Flex from '../Flex'
import Text from '../Text'

const PILL_WIDTH = 80
const PILL_HEIGHT = 44
const PILL_GUTTER = 0

export const Container = styled(Flex)`
  background-color: ${themeGet('colors.concrete')};
  user-select: none;

  align-items: center;
  justify-content: space-between;
  height: ${PILL_HEIGHT + PILL_GUTTER * 2}px;
  padding: 0 ${PILL_GUTTER}px;
  position: relative;

  width: 100%;

  ${props => props.title && 'margin-top: 14px'};
`

export const ActivePill = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: ${PILL_WIDTH}px;
  width: ${props => props.w && props.w}px;

  height: ${PILL_HEIGHT}px;
  background: ${themeGet('colors.tealBlue')};
  position: absolute;

  left: ${props => props.index * props.w + 0}px;

  top: ${PILL_GUTTER}px;
  z-index: 0;
  transition: all ${themeGet('transitionTime')};

  ${background};
`

const activePillCss = props =>
  props.active &&
  css`
    background-color: ${props.hover || themeGet('colors.tealBlue')};
    color: ${themeGet('colors.white')};

    > svg {
      color: ${themeGet('colors.white')};
      stroke: ${themeGet('colors.white')};
    }
  `

export const Pill = styled(Flex)`
  /* width: ${PILL_WIDTH}px; */
  width: ${({ w }) => w}px;

  height: ${PILL_HEIGHT}px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: ${themeGet('colors.font.secondary')};
  z-index: 1;
  cursor: pointer;
  transition: all ${themeGet('transitionTime')};
  background-color: ${({ bg }) =>
    bg ? getColorFromBg(bg) : themeGet('colors.alto')};

  ${activePillCss};

  @media screen and (min-width: 1100px) {
    &:hover {
      background-color: ${themeGet('colors.gray')};
      color: ${themeGet('colors.white')};

      svg {
        stroke: currentColor;
      }
    }
  }
`

export const Title = styled(Text)`
  position: absolute;
  top: -18px;
  left: 0;
  right: 0;
  text-align: center;
  color: ${themeGet('colors.FONT_PRIMARY')};
  font-size: 12px;
`
