import styled from 'styled-components'
import tag from 'clean-tag'
import { fontSize } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

const Table = styled(tag.table)`
  width: 100%;
  margin-top: 5px;
  border-collapse: collapse;
  overflow-y: auto;

  ${fontSize};

  th {
    text-transform: uppercase;
  }

  th,
  td {
    position: relative;
    letter-spacing: 0.06em;
    vertical-align: middle;
    text-align: left;
    line-height: 1.5;
    padding: 0.75rem;
    border-bottom: 1px solid ${themeGet('admin.colors.darkGreyBorder')};
  }

  a {
    text-decoration: none;
    outline: none;
    background: transparent;
    color: ${themeGet('admin.colors.font.primary')};
  }
`

export default Table
