import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import Box from '../Box'

const Container = styled(Box).attrs({
  is: 'input',
})`
  margin: 0;
  padding: 13px 16px;
  width: 100%;
  border: 1px solid ${themeGet('colors.input.border.color')};
  border-radius: 4px;
  background-color: ${themeGet('colors.input.bg.color')};
  font-size: 18px;
  outline: none;
  -webkit-appearance: none;

  ${props =>
    props.joined &&
    css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `};

  :focus {
    border-color: ${themeGet('colors.input.border.active')};
  }

  ::placeholder {
    color: ${themeGet('colors.input.font.placeholder')};
  }

  ${props =>
    props.error &&
    css`
      border-color: ${themeGet('colors.input.border.error')};
    `};
`

const Input = ({ label, name, placeholder, type, ...rest }) => (
  <Container
    label={label}
    name={name}
    placeholder={placeholder}
    type={type}
    {...rest}
  />
)

Input.defaultProps = {
  label: '',
  placeholder: '',
  type: 'text',
}

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
}

Input.displayName = 'Input'

export default Input
