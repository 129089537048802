import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { useBaseTheme } from 'Hooks'

import { _ } from 'Services/I18n'
import {
  getPrice,
  getRRP,
  isFreeService,
  isPriceFromVisible,
} from 'Services/Utils/service'

import { Flex } from 'Components/UI'

import {
  From,
  Price as PriceWrapper,
  PriceHolder,
  PriceLabel,
  PriceCross,
} from '../styles'

function Price({ service, flexDirection, small }) {
  const { primaryColor } = useBaseTheme()

  const priceMinimum = getPrice(service)
  const priceLabel = get(service, 'price_label')
  const showFromLabel = isPriceFromVisible(service)
  const recommendedRetailPrice = getRRP(service)
  const fontSize = small ? '12px' : '14px'

  if (!priceMinimum) {
    return null
  }

  return (
    <>
      {isFreeService(service) ? (
        <PriceWrapper
          color={primaryColor}
          flexDirection={flexDirection}
          fontSize={fontSize}
        >
          {_('service.free')}
        </PriceWrapper>
      ) : (
        <PriceWrapper
          color={primaryColor}
          flexDirection={flexDirection}
          fontSize={fontSize}
        >
          <PriceHolder>
            {showFromLabel && !recommendedRetailPrice && <From />}
            {recommendedRetailPrice && (
              <PriceCross color={primaryColor} fontSize={fontSize}>
                {recommendedRetailPrice}
              </PriceCross>
            )}
            {priceMinimum}
          </PriceHolder>
          <Flex>{priceLabel && <PriceLabel>{priceLabel}</PriceLabel>}</Flex>
        </PriceWrapper>
      )}
    </>
  )
}

Price.defaultProps = {
  flexDirection: ['row', 'row', 'column'],
  small: false,
}

Price.propTypes = {
  flexDirection: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  service: PropTypes.object.isRequired,
  small: PropTypes.bool,
}

export default Price
