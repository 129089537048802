import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { Flex } from 'Components/UI'

export const Container = styled(Flex).attrs({
  justifyContent: 'space-evenly',
})`
  border: 1px solid ${themeGet('colors.lightGrey')};
  border-radius: ${({ roundedborder }) =>
    roundedborder ? themeGet('borderRadius.2') : themeGet('borderRadius.1')};
`

const activeCss = props =>
  props.active &&
  css`
    color: ${props.activecolor};
    background-color: white;
    font-weight: 500;
  `

export const Option = styled(Flex).attrs({
  py: [2, 2, 2, '10px'],
  px: [14, 20],
  flex: 1,
  justifyContent: 'center',
})`
  user-select: none;
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  font-size: 14px;
  font-weight: 500;
  background-color: ${themeGet('colors.concrete')};
  color: ${themeGet('colors.text')};

  border-radius: ${({ roundedborder }) =>
    roundedborder ? themeGet('borderRadius.2') : themeGet('borderRadius.1')};

  &:first-child {
    border-bottom-right-radius: unset;
    border-top-right-radius: unset;
    border-right: 1px solid ${themeGet('colors.lightGrey')};
  }

  &:last-child {
    border-bottom-left-radius: unset;
    border-top-left-radius: unset;
  }

  ${activeCss};

  &:hover {
    color: ${({ activecolor }) => activecolor};
    background-color: white;
  }
`
