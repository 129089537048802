import styled from 'styled-components'

import { Flex, Text } from 'Components/UI'

export const Container = styled(Flex).attrs({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  px: [4, 4, 5],
  py: [3, 3, 4],
  width: 1,
})`
  position: absolute;
  bottom: 0;
  min-height: 112px;
  background-color: ${({ bg }) => bg && bg}b3;
`

export const Title = styled(Text).attrs({
  fontSize: [3, 4, 4],
})`
  font-weight: bold;
  letter-spacing: 0.5px;
`

export const Description = styled(Text).attrs({
  fontSize: 15,
  mt: [2, 2, 3],
})`
  letter-spacing: 0.5px;
  cursor: pointer;
  text-align: center;
  line-height: 20px;
`
