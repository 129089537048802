import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import omit from 'lodash/omit'

import { Checkbox, Radio } from 'Components/UI'

import {
  OptionHolder,
  Option,
  OptionTitle,
  RadioHolder,
  ClearIcon,
  ClearButton,
  ChevronDown,
} from './styles'

const CustomOption = option => {
  const {
    color,
    innerProps,
    data,
    optiontype,
    onChange,
    isSelected: isValueSelected,
  } = option

  const { label, value, count, subOption, isParent } = data

  const categoryValue = get(option, 'categoryValue')
  const selectedBucketId = get(categoryValue, 'value.bucket_id')
  const selectedSubBucketId = get(categoryValue, 'value.category_2.bucket_id')
  const selectedBucketType = get(categoryValue, 'value.bucket_type')
  const selectedSubBucketType = get(
    categoryValue,
    'value.category_2.bucket_type',
  )
  const bucketId = get(value, 'value.bucket_id')
  const subBucketId = get(value, 'value.category_2.bucket_id')
  const bucketType = get(value, 'value.bucket_type')
  const subBucketType = get(value, 'value.category_2.bucket_type')

  const isSelected =
    selectedBucketId === bucketId &&
    selectedBucketType === bucketType &&
    selectedSubBucketId === subBucketId &&
    selectedSubBucketType === subBucketType

  const parentIsSelected =
    selectedBucketId === bucketId && selectedBucketType === bucketType

  const isParentSelected =
    get(value, 'value.bucket_id') === get(categoryValue, 'value.bucket_id') &&
    get(value, 'value.bucket_type') ===
      get(categoryValue, 'value.bucket_type') &&
    !get(value, 'value.category_2', false)

  const resetCategory = () => {
    if (isParentSelected) {
      onChange(null)
      return
    }
    if (isSelected) {
      onChange({
        value: omit(value, ['value.category_2']),
      })
    }
  }

  const countLabel = count ? `(${count})` : ''

  if (optiontype === 'checkbox') {
    return (
      <Option color={color} {...innerProps}>
        <Checkbox checked={option.isSelected} color={color} id={value} />
        <OptionTitle>
          {label} {!isValueSelected && countLabel}
        </OptionTitle>
      </Option>
    )
  }

  if (subOption && !parentIsSelected) return null

  return (
    <OptionHolder>
      <Option color={color} {...innerProps}>
        <RadioHolder pl={subOption ? 4 : 0}>
          <Radio
            checked={isSelected || isParentSelected}
            color={color}
            readOnly
          />
          <OptionTitle mt={1}>
            {label} {!isValueSelected && countLabel}
          </OptionTitle>
          {isParent ? (
            <ChevronDown checked={isSelected || isParentSelected} />
          ) : null}
        </RadioHolder>
        {(isSelected || isParentSelected) && (
          <ClearIcon color={color} onClick={resetCategory} />
        )}
      </Option>
      {(isSelected || isParentSelected) && (
        <ClearButton color={color} onClick={resetCategory} />
      )}
    </OptionHolder>
  )
}

CustomOption.propTypes = {
  data: PropTypes.object.isRequired,
  innerProps: PropTypes.object.isRequired,
  optiontype: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default CustomOption
