import styled from 'styled-components'

import Flex from '../Flex'

export const HamburgerButton = styled(Flex).attrs({
  pl: '2px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: 20,
  display: ['flex', 'flex', 'none'],
})`
  cursor: pointer;
  position: ${props => (props.open ? `fixed` : `absolute`)};
  top: 20px;
  right: 16px;
  z-index: 201;

  input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;
    opacity: 0;
    z-index: 202;
    cursor: pointer;
    -webkit-touch-callout: none;
  }

  span {
    display: block;
    margin-bottom: 5px;
    position: relative;

    width: 20px;
    height: 2px;
    border-radius: 1px;
    background: ${({ color }) => color || 'white'};

    z-index: 201;

    transform-origin: 4px 0px;

    transition:
      transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.55s ease;
  }

  span:first-child {
    transform-origin: 0% 0%;
  }

  span:nth-last-child {
    transform-origin: 0% 100%;
  }

  input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(0px, 3px);

    width: 24px;
  }

  input:checked ~ span:nth-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  input:checked ~ span:last-child {
    transform: rotate(-45deg) translate(-2px, -3px);
  }
`

export default {}
