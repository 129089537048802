import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Text, Flex } from 'Components/UI'

export const Container = styled(Flex).attrs({
  alignItems: 'center',
})`
  flex-wrap: wrap;
  gap: 10px;
`

const pillcolorCss = ({ pillcolor }) =>
  pillcolor &&
  css`
    border: 1px solid ${pillcolor};
    color: ${pillcolor};
  `

const invertPillColorCss = ({ pillcolor, selectable }) =>
  pillcolor &&
  selectable &&
  css`
    cursor: pointer;
    background-color: ${pillcolor};
    color: ${themeGet('colors.white')};
  `

const activeCss = ({ active }) =>
  active &&
  css`
    ${invertPillColorCss};
  `

export const PillContainer = styled(Flex)`
  border-radius: 9999px;
  border: 1px solid black;
  flex-wrap: nowrap;
  transition: all ${themeGet('transitionTime')} ease;
  cursor: default;

  ${pillcolorCss}

  &:hover {
    ${invertPillColorCss};
  }

  ${activeCss};
`

export const Label = styled(Text).attrs({
  fontSize: '12px',
})`
  line-height: 1;
  font-weight: 700;
`

export const PlusButton = styled(PillContainer)`
  cursor: pointer;
  transition: all ${themeGet('transitionTime')} ease;

  &:hover {
    ${invertPillColorCss};
  }
`

export const Plus = styled(Text)`
  font-size: 12px;
  padding-bottom: 2px;
`
