import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import { chevronRightGlyph } from 'Assets/Svg'

import { withAppContext } from 'Services/Context'
import { Flex, Icon as IconBase } from 'Components/UI'

import { ContentHeaderInner, Icon, Title } from './styles'

const iconComponent = icon => {
  if (typeof icon === 'function') {
    return <Icon>{React.createElement(icon)}</Icon>
  }
  return icon
}

const ContentHeader = ({ baseTheme, icon, links, children, ...rest }) => (
  <ContentHeaderInner mt={links.length > 0 ? '24px' : 0} {...rest}>
    <Flex alignItems="center" fontWeight="500" justifyContent="center">
      {iconComponent(icon)}
      {links.map((link, index) => (
        <Fragment key={`link-${link.id}`}>
          {index !== 0 ? (
            <Flex alignItems="center" px={2}>
              {index !== 0 ? (
                <IconBase
                  fill={get(baseTheme, 'colors.secondary_background')}
                  glyph={chevronRightGlyph}
                  size={10}
                />
              ) : (
                ''
              )}
            </Flex>
          ) : null}
          <Title linkcolor={get(baseTheme, 'colors.secondary_background')}>
            {link.element}
          </Title>
        </Fragment>
      ))}
    </Flex>
    {children}
  </ContentHeaderInner>
)

ContentHeader.defaultProps = {
  icon: null,
}

ContentHeader.propTypes = {
  baseTheme: PropTypes.object.isRequired,
  children: PropTypes.element,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  links: PropTypes.arrayOf(PropTypes.object).isRequired,
}

ContentHeader.defaultProps = {
  children: <div />,
}

export default withAppContext(ContentHeader)
