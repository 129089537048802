import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import map from 'lodash/map'
import noop from 'lodash/noop'

import { components } from 'react-select'

import { withAppContext } from 'Services/Context'

import { Select } from 'Components/UI'

import CustomOption from './CustomOption'

import {
  DropdownContainer,
  GroupHeading,
  GroupHeadingTitle,
  MapPinIconContainer,
} from './styles'

class SearchSelect extends PureComponent {
  state = {
    inputValue: '',
  }

  handleChange = option => {
    const { isDisabledSelect, onChange } = this.props
    if (isDisabledSelect) {
      return
    }
    onChange(option)
  }

  handleKeyDown = event => {
    const { isDisabledSelect } = this.props
    if (isDisabledSelect && event.key === 'Enter') {
      event.preventDefault()
    }
  }

  handleInputChange = (inputValue, { action }) => {
    const { onInputChange } = this.props
    if (action === 'input-change') {
      this.setState({ inputValue })
    }
    onInputChange(inputValue, { action })
  }

  renderDropdownContainer = props => (
    <DropdownContainer
      rounded={get(this.props, 'baseTheme.colors.rounded_border') ? 1 : 0}
    >
      <components.Menu {...props}>{props.children}</components.Menu>
    </DropdownContainer>
  )

  renderGroupHeading = ({ children }) => {
    const { title, icon } = children

    return (
      <GroupHeading>
        <MapPinIconContainer glyph={icon} size={16} />
        <GroupHeadingTitle>{title}</GroupHeadingTitle>
      </GroupHeading>
    )
  }

  renderOption = props => {
    const { baseTheme } = this.props
    const { inputValue } = this.state
    return (
      <CustomOption baseTheme={baseTheme} inputValue={inputValue} {...props} />
    )
  }

  render() {
    const {
      baseTheme,
      isLoading,
      options,
      value,
      onBlur,
      onFocus,
      fieldRef,
      ...rest
    } = this.props
    const roundedBorder = get(baseTheme, 'colors.rounded_border') ? 1 : 0

    return (
      <Select
        color={get(baseTheme, 'colors.secondary_background')}
        components={{
          GroupHeading: this.renderGroupHeading,
          Option: this.renderOption,
          Menu: this.renderDropdownContainer,
        }}
        fieldRef={fieldRef}
        isClearable
        isLoading={isLoading}
        options={map(options, option => ({ ...option, isDisabled: isLoading }))}
        placeholder="Location or Restaurant"
        roundedborder={roundedBorder}
        value={value}
        onBlur={onBlur}
        onChange={this.handleChange}
        onFocus={onFocus}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown}
        {...rest}
      />
    )
  }
}

SearchSelect.defaultProps = {
  fieldRef: null,
  isDisabledSelect: false,
  isLoading: false,
  value: '',
  onFocus: noop,
}

SearchSelect.propTypes = {
  baseTheme: PropTypes.object.isRequired,
  fieldRef: PropTypes.object,
  isDisabledSelect: PropTypes.bool,
  isLoading: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onInputChange: PropTypes.func.isRequired,
}

export default withAppContext(SearchSelect)
