import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Text, Flex } from 'Components/UI'

export const Container = styled(Flex).attrs({
  alignItems: 'center',
  flexDirection: 'column',
})`
  &:empty {
    display: none;
  }
`

const categoryColorCss = ({ categorycolor }) =>
  categorycolor &&
  css`
    border: 1px solid ${categorycolor};
    color: ${categorycolor};
  `
const invertCategoryColorCss = ({ categorycolor }) =>
  categorycolor &&
  css`
    background-color: ${categorycolor};
    color: white;
  `

export const Category = styled(Flex).attrs({
  mr: 1,
  mb: 1,
})`
  border-radius: 9999px;
  border: 1px solid black;
  align-items: center;
  justify-content: center;
  cursor: default;

  ${categoryColorCss}
`

export const Label = styled(Text)`
  line-height: 1;
  white-space: nowrap;
`

export const PlusButton = styled(Category)`
  cursor: pointer;
  transition: all ${themeGet('transitionTime')} ease;

  &:hover {
    ${invertCategoryColorCss};
  }
`

export const Plus = styled(Text)`
  font-size: 12px;
  padding-bottom: 2px;
`

export const Row = styled(Flex).attrs({
  width: 1,
})`
  flex-wrap: wrap;
`
