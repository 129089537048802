import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import { setCategoryValue } from 'Store/Actions/marketplace'

import FeaturedCategories from './FeaturedCategories'

const actions = {
  onSetCategoryValue: setCategoryValue,
}

export default withRouter(connect(null, actions)(FeaturedCategories))
