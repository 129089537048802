import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { NavLink } from 'react-router-dom'

export default styled(NavLink).attrs({
  mx: 3,
})`
  font-weight: 600;
  color: ${themeGet('colors.font.primary')};
  text-decoration: none;
  transition: all ${themeGet('transitionTime')} ease;

  &:hover {
    color: ${themeGet('colors.font.secondary')};
    text-decoration: underline;
  }

  &.active {
    color: ${themeGet('colors.font.secondary')};
    opacity: 0.6;
  }
`
