import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getWishlistCount } from 'Store/Selectors/wishlist'

import MobileMenu from './MobileMenu'

const selector = createStructuredSelector({
  wishlistCount: getWishlistCount,
})

export default connect(selector, null)(MobileMenu)
