import { Public, Root } from 'Containers/Layout'

import { ROOT_PATH, PUBLIC_PATHS } from 'Constants/paths'
import * as PublicPages from 'Containers/Pages/Public'

import publicRoutes from './public'

const routes = () => {
  const allRoutes = [
    {
      path: ROOT_PATH,
      component: Public,
      routes: publicRoutes(),
    },
  ]

  return [
    {
      path: PUBLIC_PATHS.REDIRECTOR(),
      exact: true,
      component: PublicPages.Redirector,
    },
    {
      component: Root,
      routes: allRoutes,
    },
  ]
}

export default routes
