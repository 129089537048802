import Cookies from 'universal-cookie'

import { APP_ENV, COOKIE } from 'Config/app'

const defaultOptions = {
  path: '/',
}

if (APP_ENV !== 'development') {
  defaultOptions.domain = COOKIE.SHARED_DOMAIN
}

const keyWithPrefix = key =>
  key ? `${COOKIE.PERSIST_KEY}.${key}` : `${COOKIE.PERSIST_KEY}`

class CookiePersist {
  static instance = null

  constructor(cookies) {
    if (!CookiePersist.instance) {
      this.cookies = new Cookies(cookies)
      CookiePersist.instance = this
    }

    return CookiePersist.instance
  }

  set(value, options = {}) {
    this.cookies.set(COOKIE.PERSIST_KEY, value, {
      ...defaultOptions,
      ...options,
    })
  }

  get() {
    return this.cookies.get(COOKIE.PERSIST_KEY)
  }

  remove(key, options = {}) {
    this.cookies.remove(keyWithPrefix(key), { ...defaultOptions, ...options })
  }
}

export default CookiePersist
