import React from 'react'
import PropTypes from 'prop-types'
import IframeResizer from 'iframe-resizer-react'

import { Container } from './styles'

function IFrame({ src, title, ...rest }) {
  return (
    <Container>
      <IframeResizer frameBorder="0" src={src} title={title} {...rest} />
    </Container>
  )
}

IFrame.defaultProps = {
  src: null,
  title: null,
}

IFrame.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
}

export default IFrame
