import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Pill } from './styles'

class Item extends PureComponent {
  handleChangeIndex = () => {
    const { index, onChangeIndex } = this.props
    if (onChangeIndex) onChangeIndex(index)
  }

  render() {
    const { active, bg, hoverBg, index, text, width } = this.props

    return (
      <Pill
        active={active ? 1 : 0}
        bg={bg}
        hover={hoverBg}
        index={index}
        w={width}
        onClick={this.handleChangeIndex}
      >
        {text}
      </Pill>
    )
  }
}

Item.defaultProps = {
  active: false,
  bg: null,
  hoverBg: null,
  width: 80,
}

Item.propTypes = {
  active: PropTypes.bool,
  bg: PropTypes.string,
  hoverBg: PropTypes.string,
  index: PropTypes.number.isRequired,
  text: PropTypes.object.isRequired,
  width: PropTypes.number,
  onChangeIndex: PropTypes.func.isRequired,
}

export default Item
