import React from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import { HamburgerButton } from './styles'

function Hamburger({ color, open, onOpen }) {
  return (
    <HamburgerButton color={color} open={open ? 1 : 0} onClick={onOpen}>
      <input checked={open} type="checkbox" onChange={noop} />
      <span />
      <span />
      <span />
    </HamburgerButton>
  )
}

Hamburger.defaultProps = {
  color: 'white',
  open: false,
  onOpen: noop,
}

Hamburger.propTypes = {
  color: PropTypes.string,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
}

export default Hamburger
