import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'

import map from 'lodash/map'
import noop from 'lodash/noop'
import get from 'lodash/get'
import find from 'lodash/find'

import { getIsChecked } from 'Services/Utils/categoryOptions'
import RadioCategory from '../RadioCategory'

import { Badge, Content, Item, StyledDropdown } from './styles'

function FilterDropmenu({
  color,
  items,
  value,
  showBadge,
  showClear,
  placeholder,
  onSelect,
  ...rest
}) {
  const [openCategoryId, setOpenCategoryId] = useState(null)

  const handleResetSelected = () => {
    setOpenCategoryId(null)
    onSelect()
  }

  const handleToggleCategorySubGroup = selectedCategory => {
    const selectedCategoryId = get(selectedCategory, 'value.bucket_id', null)

    // Toggle current
    if (openCategoryId === selectedCategoryId) {
      setOpenCategoryId(null)
      return
    }

    setOpenCategoryId(selectedCategoryId)
  }

  const checked = !!find(items, item => getIsChecked(value, item))

  const label =
    !checked || !showBadge ? (
      placeholder
    ) : (
      <>
        {placeholder}
        <Badge />
      </>
    )

  return (
    <StyledDropdown chevron color={color} label={label} {...rest}>
      <Content>
        {map(items, item => (
          <Item color={color} key={item.label}>
            <RadioCategory
              color={color}
              isOpen={openCategoryId === get(item, 'value.bucket_id')}
              item={item}
              showClear={showClear}
              value={value}
              onRadioSelect={onSelect}
              onResetSelected={handleResetSelected}
              onToggleSubGroup={handleToggleCategorySubGroup}
            />
          </Item>
        ))}
      </Content>
    </StyledDropdown>
  )
}

FilterDropmenu.defaultProps = {
  color: '',
  items: [],
  value: null,
  showBadge: true,
  showClear: true,
  onSelect: noop,
}

FilterDropmenu.propTypes = {
  color: PropTypes.string,
  items: PropTypes.array,
  placeholder: PropTypes.string.isRequired,
  showBadge: PropTypes.bool,
  showClear: PropTypes.bool,
  value: PropTypes.any,
  onSelect: PropTypes.func,
}

export default memo(FilterDropmenu)
