export const MARKETPLACE_TYPE = {
  restaurant: 'restaurant',
  wellness: 'wellness',
  activity: 'activity',
}

export const MARKETPLACE_SERVICE_TYPE = {
  online: 0,
  physical: 1,
  onlineAndPhysical: 2,
  allResults: 3,
}

export const MERCHANT_CONTENT_TAB = {
  about: 0,
  book: 1,
  reviews: 2,
}

export const MARKETPLACE_DROPDOWN_TEXT = {
  restaurant: 'dropdown.restaurant',
  wellness: 'dropdown.wellness',
  activity: 'dropdown.activity',
}

export const PHONE_MASK = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
]
export const LOCAL_PHONE_MASK = [
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const SHORTNUM_MASK = [/\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/]

export const DAYS = [
  'days.monday',
  'days.tuesday',
  'days.wednesday',
  'days.thursday',
  'days.friday',
  'days.saturday',
  'days.sunday',
]

export const LANGUAGE = {
  EN: 'en',
  FR: 'fr',
}

export const CONTENT_WIDTH = 1200
export const CONTENT_PADDING_X = [16, 16, 16, 32]

export const SERVICE_AVAILABILITY = {
  AVAILABLE: 'Available',
  UNAVAILABLE: 'Unavailable',
  BOOKING_REQUEST: 'Booking Request',
}

export const SEARCH_TYPE = {
  BUSINESSES: 'businesses',
  SERVICES: 'products',
}

export const SEARCH_VIEW = {
  LIST: 'list',
  GRID: 'grid',
  MAP: 'map',
}

export const MERCHANT_PAGE_LAYOUT = {
  LAYOUT_1: 0, // Services appear in sticky right side panel
  LAYOUT_2: 1, // Services inline with page content
}

export const SERVICE_LOCATION_TYPE = {
  PHYSICAL: 0,
  ONLINE: 1,
  MOBILE: 2,
}

export const WISHLIST_TYPE = {
  MERCHANT: 'merchants',
  SERVICE: 'services',
}
