import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { useBaseTheme } from 'Hooks'

import {
  StyledSlider,
  LeftArrow,
  NextArrow,
  PrevArrow,
  RightArrow,
} from './styles'

function DesktopSlider({ children }) {
  const { secondaryColor, roundedBorder } = useBaseTheme()

  if (!children?.length) {
    return null
  }

  const slidesToShow = children.length < 4 ? children.length : 4

  const settings = useMemo(() => {
    return {
      slidesToShow,
      slidesToScroll: slidesToShow,
      speed: 500,
      nextArrow: (
        <NextArrow roundedborder={roundedBorder ? 1 : 0}>
          <RightArrow fill={secondaryColor} />
        </NextArrow>
      ),
      prevArrow: (
        <PrevArrow roundedborder={roundedBorder ? 1 : 0}>
          <LeftArrow fill={secondaryColor} />
        </PrevArrow>
      ),
      lazyLoad: 'ondemand',
    }
  }, [secondaryColor, roundedBorder])

  return <StyledSlider {...settings}>{children}</StyledSlider>
}

DesktopSlider.defaultProps = {
  children: null,
}

DesktopSlider.propTypes = {
  children: PropTypes.any,
}

export default DesktopSlider
