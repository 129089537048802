import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getSearchDatePickerOpenStatus } from 'Store/Selectors/marketplace'

import { setSearchDatePickerOpenStatus } from 'Store/Actions/marketplace'

import SearchDatePicker from './SearchDatePicker'

const selector = createStructuredSelector({
  focused: getSearchDatePickerOpenStatus,
})

const actions = {
  onFocusChange: setSearchDatePickerOpenStatus,
}

export default connect(selector, actions)(SearchDatePicker)
