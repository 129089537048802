import styled, { css } from 'styled-components'
import { Flex } from 'Components/UI'
import Slider from 'react-slick'

const isGridCss = ({ compact }) =>
  compact &&
  css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    width: 100%;
    height: fit-content;
  `

export const Container = styled(Flex).attrs({
  flexDirection: 'column',
})`
  width: 100%;
  gap: 16px;

  ${isGridCss};
`

export const MobileSliderContainer = styled(Slider).attrs({})``

export default {}
