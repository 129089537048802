import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Text, Flex, Button } from 'Components/UI'

export const Container = styled(Flex).attrs({
  justifyContent: 'center',
  alignItems: 'center',
  py: 10,
  px: 3,
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100000000;
  background-color: ${({ background }) =>
    background || themeGet('colors.goldenrod')};
  box-shadow: 0 -1px 3px ${themeGet('colors.darkDusty')};
`

export const Content = styled(Flex).attrs({
  flexDirection: ['column-reverse', 'row'],
  justifyContent: 'flex-start',
  alignItems: 'center',
})`
  width: 100%;
  max-width: 1000px;
`

export const StyledButton = styled(Button).attrs({
  mt: [2, 0],
})`
  background-color: ${({ background }) =>
    background || themeGet('colors.goldenrod')};
  border-radius: 0;
  border: 3px solid ${({ color }) => color || themeGet('colors.black')};
  color: ${({ color }) => color || themeGet('colors.black')};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  height: 40px;

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    width: 100%;
  }
`

export const Title = styled(Text).attrs({
  ml: [0, 2],
  fontSize: [1, 14],
})`
  color: ${({ color }) => color || themeGet('colors.black')};

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    display: inline-table;
  }
`

export const LinkExternal = styled(Text).attrs({
  is: 'a',
  target: '_blank',
  fontSize: [1, 14],
})`
  background: none;
  transition: all ${themeGet('transitionTime')} ease;
  color: ${({ color }) => color || themeGet('colors.tealBlue')} !important;
  cursor: pointer;
  white-space: nowrap;
  font-weight: bold;

  &.active,
  :hover {
    opacity: 0.9;
    background: none;
  }
`
