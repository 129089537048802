import React from 'react'
import { useRouteMatch } from 'react-router'

import PropTypes from 'prop-types'

import get from 'lodash/get'
import map from 'lodash/map'

import { SEARCH_TYPE } from 'Constants/ids'
import Labels from 'Constants/labels'

import { useBaseTheme } from 'Hooks'

import { Container, Option } from './styles'

const SearchTypeSwitch = ({ onChange, ...rest }) => {
  const match = useRouteMatch()
  const searchType = get(match, 'params.searchType')

  const { labels, isSearchByServiceEnabled, primaryColor } = useBaseTheme()

  if (!isSearchByServiceEnabled) {
    return null
  }

  const handleChangeOption = option => {
    if (searchType === option) {
      return
    }

    onChange(option)
  }

  const renderOptions = () => {
    const options = [SEARCH_TYPE.SERVICES, SEARCH_TYPE.BUSINESSES]

    return map(options, option => {
      const isActive = searchType === option

      const label =
        option === SEARCH_TYPE.BUSINESSES
          ? get(labels, Labels.SEARCH_TYPE_BUSINESSES, 'Businesses')
          : get(labels, Labels.SEARCH_TYPE_SERVICES, 'Experiences')

      return (
        <Option
          active={isActive}
          activecolor={primaryColor}
          key={option}
          onClick={() => handleChangeOption(option)}
        >
          {label}
        </Option>
      )
    })
  }

  return <Container {...rest}>{renderOptions()}</Container>
}

SearchTypeSwitch.defaultProps = {}

SearchTypeSwitch.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default SearchTypeSwitch
