export const APP_READY = 'app/READY'
export const STORE_READY = 'app/STORE_READY'
export const ACCEPT_COOKIE_POLICY = 'app/ACCEPT_COOKIE_POLICY'
export const INITIALIZE_CONFIG = 'app/INITIALIZE_CONFIG'

export const appReady = () => ({
  type: APP_READY,
})

export const storeReady = () => ({
  type: STORE_READY,
})

export const acceptCookiePolicy = () => ({
  type: ACCEPT_COOKIE_POLICY,
})

export const initializeConfig = config => ({
  type: INITIALIZE_CONFIG,
  config,
})
