import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { errorPageImage } from 'Assets/Images'

import { Flex, Text as TextUI } from 'Components/UI'

export const Root = styled(Flex)`
  background-color: ${themeGet('colors.bg')};

  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  width: 100%;
`

export const Container = styled(Flex)`
  background-color: ${themeGet('colors.white')};

  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  max-width: 1600px;
  margin: 0 auto;
  min-height: 100vh;
  height: 100%;
  width: 100%;
`

export const Text = styled(TextUI).attrs({ is: 'span', mt: 3 })`
  color: ${themeGet('colors.boulder')};
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`

export const Button = styled(Flex).attrs({ is: 'button', mt: 4, p: 0 })`
  background-color: ${({ background }) =>
    background || themeGet('colors.goldenrod')};
  border: none;
  border-radius: 0;
  box-shadow: none;
  color: ${themeGet('colors.black')};
  cursor: pointer;

  font-family: Inter, sans-serif;
  font-weight: bold;
  font-size: 12px;
  outline: none;
  transition: all 200ms ease;
  text-transform: uppercase;
  user-select: none;

  align-items: center;
  justify-content: center;
  display: flex;
  height: 48px;
  width: 190px;

  &:hover:enabled {
    color: ${themeGet('colors.white')};
  }

  &:active:enabled {
    color: ${themeGet('colors.white')};
  }
`

export const ErrorImage = styled(Flex).attrs({
  is: 'img',
  src: errorPageImage,
})`
  width: 350px;
`

export const Content = styled(Flex).attrs({ my: 123 })`
  background-color: ${themeGet('colors.white')};

  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export default {}
