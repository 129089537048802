import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { background } from 'styled-system'

import { Text, Flex, Box } from 'Components/UI'

export const Container = styled(Flex).attrs({
  flexDirection: 'column',
  width: 1,
})`
  ${background};

  &:not(:last-child) {
    margin-bottom: 32px;
  }
`

export const Title = styled(Text).attrs({
  fontSize: 18,
})`
  font-weight: bold;
  letter-spacing: 0.5px;
  color: ${props => (props.color ? props.color : themeGet('colors.black'))};
`

export const Description = styled(Box).attrs({
  fontSize: 14,
  mt: 3,
})`
  letter-spacing: 0.5px;
  color: ${themeGet('colors.header')};
  line-height: 1.5;

  a {
    color: ${({ linkcolor }) => linkcolor || themeGet('colors.black')};
  }
`
