import React from 'react'
import PropTypes from 'prop-types'
import Interweave from 'interweave'

import noop from 'lodash/noop'

import { Container, Title, Description } from './styles'

function CategorySection({
  title,
  description,
  primaryColor,
  roundedBorder,
  secondaryColor,
  onCategory,
  ...rest
}) {
  return (
    <Container
      roundedborder={roundedBorder ? 1 : 0}
      onClick={onCategory}
      {...rest}
    >
      <Title color={primaryColor}>{title}</Title>
      <Description color={secondaryColor}>
        <Interweave content={description} />
      </Description>
    </Container>
  )
}

CategorySection.defaultProps = {
  description: '',
  primaryColor: null,
  roundedBorder: false,
  secondaryColor: null,
  title: '',
  onCategory: noop,
}

CategorySection.propTypes = {
  description: PropTypes.string,
  primaryColor: PropTypes.string,
  roundedBorder: PropTypes.bool,
  secondaryColor: PropTypes.string,
  title: PropTypes.string,
  onCategory: PropTypes.func,
}

export default CategorySection
