import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { closeGlyph } from 'Assets/Svg'

import Flex from '../Flex'
import Icon from '../Icon'
import ButtonUI from '../Button'

export const Container = styled(Flex)`
  position: relative;
`

export const PopupWrapper = styled(Flex).attrs({ pl: 3, py: 2, pr: 4 })`
  background-color: ${themeGet('colors.white')};
  border: 1px solid ${themeGet('colors.frenchGray')};
  border-radius: 4px;

  flex-direction: column;
  position: absolute;
  top: -65px;
`

export const Close = styled(Icon).attrs({
  glyph: closeGlyph,
})`
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  width: 30px;
  height: 30px;
  cursor: pointer;
`

export const Button = styled(ButtonUI).attrs({ mt: 3 })`
  text-transform: uppercase;
`

export default {}
