import React from 'react'

import { Container } from './styles'

const Dash = ({ ...rest }) => <Container {...rest} />

Dash.defaultProps = {}

Dash.propTypes = {}

export default Dash
