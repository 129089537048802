import React from 'react'
import PropTypes from 'prop-types'

import {
  TitleHolder,
  Title as TitleLabel,
  Business,
  BusinessName,
} from '../styles'

function Title({ businessName, serviceName, small }) {
  return (
    <TitleHolder gap={small ? '2px' : '8px'}>
      <TitleLabel fontSize={small ? '14px' : '16px'} lines={small ? 1 : 2}>
        {serviceName}
      </TitleLabel>
      {businessName && (
        <Business>
          <BusinessName fontSize={small ? '12px' : '14px'}>
            {businessName}
          </BusinessName>
        </Business>
      )}
    </TitleHolder>
  )
}

Title.defaultProps = {
  small: false,
}

Title.propTypes = {
  businessName: PropTypes.string.isRequired,
  serviceName: PropTypes.string.isRequired,
  small: PropTypes.bool,
}

export default Title
