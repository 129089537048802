import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import { Container, Title, Image } from './styles'

function SubCategory({ primaryColor, category, onCategory, ...rest }) {
  return (
    <Container onClick={onCategory(category)} {...rest}>
      <Image src={get(category, 'marketplace_banner_image')} />
      <Title color={primaryColor}>{get(category, 'name')}</Title>
    </Container>
  )
}

SubCategory.defaultProps = {
  category: {},
  primaryColor: null,
}

SubCategory.propTypes = {
  category: PropTypes.object,
  primaryColor: PropTypes.string,
  onCategory: PropTypes.func.isRequired,
}

export default SubCategory
