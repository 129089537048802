import { createSelector } from 'reselect'

export const getState = state => state.wishlist

export const isLoading = createSelector(getState, state => state.isLoading)

export const getWishlistError = createSelector(getState, state => state.error)

export const getWishlist = createSelector(getState, state => state.wishlist)

export const getWishlistCount = createSelector(getState, state => state.count)
