import React, { Component } from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import Button from '../Button'

import { Container, Close, Wrapper, Text, Buttons } from './styles'

class Modal extends Component {
  handleOkClick = e => {
    e.preventDefault()
    e.stopPropagation()

    const { onCallback } = this.props
    if (onCallback) onCallback(true)
  }

  handleCancelClick = e => {
    e.preventDefault()
    e.stopPropagation()

    const { onCallback } = this.props
    if (onCallback) onCallback(false)
  }

  render() {
    const {
      children,
      style,
      isOpen,
      text,
      okText,
      cancelText,
      className,
      withClose,
    } = this.props

    const element =
      (window.document && window.document.getElementById('root')) || null

    return (
      <Container
        appElement={element}
        className={className}
        closeTimeoutMS={170}
        isOpen={isOpen}
        style={style}
        onRequestClose={this.handleCancelClick}
      >
        {withClose && <Close onClick={this.handleCancelClick} />}
        {children || (
          <Wrapper>
            <Text>{text}</Text>
            <Buttons>
              <Button inverse={1} onClick={this.handleCancelClick}>
                {cancelText}
              </Button>
              <Button onClick={this.handleOkClick}>{okText}</Button>
            </Buttons>
          </Wrapper>
        )}
      </Container>
    )
  }
}

Modal.defaultProps = {
  className: '',
  isOpen: false,
  text: null,
  okText: 'Ok',
  cancelText: 'Cancel',
  children: null,
  style: {},
  withClose: true,
  onCallback: noop,
}

Modal.propTypes = {
  cancelText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  okText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  style: PropTypes.object,
  text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  withClose: PropTypes.bool,
  onCallback: PropTypes.func,
}

Modal.Close = Close

export default Modal
