import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { triangleDownGlyph } from 'Assets/Svg'

import Icon from '../Icon'

import { PickerWrapper } from './styles'

class Picker extends Component {
  state = {}

  render() {
    const { children, color, inverse } = this.props
    const { isOpened } = this.context

    return (
      <PickerWrapper
        color={color}
        inverse={inverse ? 1 : 0}
        opened={isOpened ? 1 : 0}
      >
        {children}
        <Icon fill="#a7abb1" glyph={triangleDownGlyph} size={10} />
      </PickerWrapper>
    )
  }
}

Picker.displayName = 'Dropdown.Picker'

Picker.contextTypes = {
  isOpened: PropTypes.bool,
}

Picker.defaultProps = {
  inverse: false,
  color: 'aluminium',
}

Picker.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  inverse: PropTypes.bool,
}

export default Picker
