export default {
  PLACEHOLDER_HOME_SEARCH: 1, // Location or Business (home search field)
  PLACEHOLDER_HOME_SEARCH_CATEGORY: 2, // Search by Category (home search field)
  FILTER_BY_CATEGORY: 3, // Filter by Category (search)
  FILTER_BY_TYPE: 4, // Filter by Type (search)
  FILTER_BY_DESTINATION: 5, // Filter by Destination (search)
  PLACEHOLDER_SEARCH_LOCATION: 6, // Any Location (search page location field)
  PLACEHOLDER_SEARCH_CATEGORY: 7, // Any Location (search page location field)
  TITLE_POPULAR_CATEGORIES: 8, // Popular categories
  TITLE_DESTINATIONS: 9, // Destinations
  TITLE_POPULAR_SEARCHES: 10, // Popular searches
  TITLE_TOP_OFFERS: 11, // Top Offers
  TITLE_FEATURED_BUSINESSES: 12, // Featured Businesses
  SEARCH_TYPE_BUSINESSES: 13, // Businesses
  SEARCH_TYPE_SERVICES: 14, // Experiences
  PLACEHOLDER_ONLINE_SERVICES: 15, // Online services only
  WISHLIST_BUSINESSES: 16, // Shortlisted Businesses
  WISHLIST_SERVICES: 17, // Shortlisted Services
  TITLE_SERVICE_ABOUT: 18, // About
  TITLE_SERVICE_DATE: 19, // Date
  TITLE_SERVICE_ORGANISED_BY: 20, // Organised by
  TITLE_SERVICE_CONTACT_ORGANISER: 21, // Contact the organiser
  PLACEHOLDER_DATE_PICKER: 22, // Any Date
  TITLE_FEATURED_ARTICLES: 23, // Featured articles
  TITLE_MERCHANT_TRADING_HOURS: 24, // Trading hours
}
