import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import map from 'lodash/map'
import debounce from 'lodash/debounce'
import without from 'lodash/without'

import { _ } from 'Services/I18n'

import { withAppContext } from 'Services/Context'

import { Checkbox } from 'Components/UI'

import RadioCategory from '../RadioCategory'

import {
  Container,
  Header,
  Label,
  Button,
  Content,
  Item,
  ItemName,
  ItemCount,
} from './styles'

class Filter extends PureComponent {
  state = {
    showMore: false,
    openCategoryId: null,
  }

  handleResetSelected = () => {
    const { type, onSelect } = this.props

    if (type === 'radio') {
      this.setState({ openCategoryId: null })
      onSelect()
    } else {
      onSelect([])
    }
  }

  handleSelect = debounce(id => {
    const { onSelect, activeFilters } = this.props
    let nextSelected = Object.assign([], activeFilters)

    if (activeFilters.includes(id)) {
      nextSelected = without(activeFilters, id)
    } else {
      nextSelected = [...activeFilters, id]
    }

    onSelect(nextSelected)
  }, 150)

  handleRadioSelect = value => {
    const { onSelect } = this.props
    onSelect(value)
  }

  handleShowMore = () => {
    this.setState(state => ({ showMore: !state.showMore }))
  }

  handleToggleCategorySubGroup = category => {
    const { openCategoryId: currentOpenCategoryId } = this.state
    const openCategoryId = get(category, 'value.bucket_id', null)

    // Toggle current
    if (currentOpenCategoryId === openCategoryId) {
      this.setState({ openCategoryId: null })
      return
    }

    this.setState({ openCategoryId })
  }

  render() {
    const { openCategoryId, showMore } = this.state
    const {
      baseTheme,
      showClear,
      label,
      items,
      limit,
      activeFilters,
      type,
      value,
    } = this.props
    const secondaryColor = get(baseTheme, 'colors.secondary_background')

    if (!get(items, 'length', 0)) {
      return null
    }

    return (
      <Container>
        <Header>
          <Label>{label}</Label>
          {showClear && (activeFilters.length || value?.value) ? (
            <Button color={secondaryColor} onClick={this.handleResetSelected}>
              {_('common.clearAll')}
            </Button>
          ) : null}
        </Header>
        <Content>
          {map(
            items.slice(0, showMore ? items.length : limit),
            (item, index) => {
              const itemId = get(item, 'id') || get(item, 'value')
              const active = activeFilters.includes(itemId)

              return (
                <Item key={`${label}-${index}`}>
                  {type === 'checkbox' && (
                    <>
                      <Checkbox
                        checked={active}
                        color={secondaryColor}
                        id={`${label}-${itemId}`}
                        onChange={() => this.handleSelect(itemId)}
                      />
                      <ItemName onClick={() => this.handleSelect(itemId)}>
                        {get(item, 'name') ||
                          get(item, 'label') ||
                          get(item, 'value')}
                      </ItemName>
                      {!active && (
                        <ItemCount>({item.count || item.total})</ItemCount>
                      )}
                    </>
                  )}
                  {type === 'radio' && (
                    <RadioCategory
                      color={secondaryColor}
                      isOpen={openCategoryId === get(item, 'value.bucket_id')}
                      item={item}
                      key={item.label}
                      value={value}
                      onRadioSelect={this.handleRadioSelect}
                      onResetSelected={this.handleResetSelected}
                      onToggleSubGroup={this.handleToggleCategorySubGroup}
                    />
                  )}
                </Item>
              )
            },
          )}
          {!showMore && limit < items.length && (
            <Button
              color={secondaryColor}
              ml={4}
              mt={12}
              onClick={this.handleShowMore}
            >
              Show more...
            </Button>
          )}
          {showMore && limit < items.length && (
            <Button
              color={secondaryColor}
              ml={4}
              mt={12}
              onClick={this.handleShowMore}
            >
              Show less...
            </Button>
          )}
        </Content>
      </Container>
    )
  }
}

Filter.defaultProps = {
  activeFilters: [],
  label: 'Filter',
  limit: 3,
  showClear: true,
  type: 'checkbox',
  value: null,
}

Filter.propTypes = {
  activeFilters: PropTypes.array,
  baseTheme: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  label: PropTypes.string,
  limit: PropTypes.number,
  showClear: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onSelect: PropTypes.func.isRequired,
}

export default withAppContext(Filter)
