import React, { useState } from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import { useBaseTheme } from 'Hooks'
import { Loader } from 'Components/UI'

import IFrame from '../IFrame'

import {
  ReviewsModal,
  ReviewsModalContent,
  LoaderHolder,
  Title,
} from './styles'

const ReviewsWidgetModal = ({ businessName, url, onClose }) => {
  const { secondaryColor } = useBaseTheme()
  const [iFrameLoading, setIFrameLoading] = useState(true)

  return (
    <ReviewsModal isLoaded={!iFrameLoading} isOpen onCallback={onClose}>
      {iFrameLoading && (
        <LoaderHolder>
          <Loader color={secondaryColor} />
        </LoaderHolder>
      )}

      <Title>{businessName}</Title>

      <ReviewsModalContent>
        <IFrame
          src={url}
          title="Reviews widget"
          onLoad={() => setIFrameLoading(false)}
        />
      </ReviewsModalContent>
    </ReviewsModal>
  )
}

ReviewsWidgetModal.defaultProps = {
  onClose: noop,
}

ReviewsWidgetModal.propTypes = {
  businessName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}

export default ReviewsWidgetModal
