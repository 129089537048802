import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { withAppContext } from 'Services/Context'
import { Route, Switch, useRouteMatch, useLocation } from 'react-router'

import get from 'lodash/get'

import { PUBLIC_PATHS } from 'Constants/paths'

import MerchantsSearch from 'Components/Blocks/MerchantsSearch'
import CategoryLineDescription from 'Components/Blocks/CategoryLineDescription'
import { BannerImage } from 'Components/Blocks/Banners'
import CategoriesSearchByTags from 'Components/Blocks/CategoriesSearchByTags'

import { Content, BannerTitle } from './styles'

function BannerCategories({ baseTheme, allCategories, category }) {
  const location = useLocation()
  const isCategoriesPage = useRouteMatch({
    exact: true,
    path: PUBLIC_PATHS.CATEGORIES,
  })

  const pageSettings = get(allCategories, 'page_settings')
  const marketplaceBannerImage = get(category, 'marketplace_banner_image', null)
  const mainImageUpload = get(category, 'main_image_upload', null)

  const data = useMemo(() => {
    const primaryFont = get(baseTheme, 'colors.primary_font')
    const customPlaceholder = get(pageSettings, 'custom_placeholder')
    const bgUrl = get(pageSettings, 'banner_image')

    let customHeader = get(pageSettings, 'custom_header')
    if (!isCategoriesPage) {
      customHeader = get(baseTheme, 'banner.copy')
    }

    return {
      primaryFont,
      bgUrl,
      customHeader,
      customPlaceholder,
    }
  }, [
    baseTheme,
    pageSettings,
    marketplaceBannerImage,
    mainImageUpload,
    location.pathname,
  ])
  const { primaryFont, bgUrl, customHeader, customPlaceholder } = data

  return (
    <Switch>
      <Route exact path={PUBLIC_PATHS.CATEGORIES}>
        <BannerImage bgUrl={bgUrl}>
          <Content maxWidth={600}>
            <BannerTitle
              dangerouslySetInnerHTML={{ __html: customHeader }}
              font={primaryFont}
            />
            <CategoriesSearchByTags customPlaceholder={customPlaceholder} />
          </Content>
        </BannerImage>
      </Route>

      <Route path={PUBLIC_PATHS.PRIMARY_CATEGORY()}>
        <BannerImage bgUrl={mainImageUpload || marketplaceBannerImage || bgUrl}>
          <Content maxWidth={1000} mt={-112}>
            <BannerTitle
              dangerouslySetInnerHTML={{ __html: customHeader }}
              font={primaryFont}
            />
            <MerchantsSearch />
          </Content>
          <CategoryLineDescription />
        </BannerImage>
      </Route>
    </Switch>
  )
}

BannerCategories.defaultProps = {
  allCategories: {},
  baseTheme: {},
  category: {},
}

BannerCategories.propTypes = {
  allCategories: PropTypes.object,
  baseTheme: PropTypes.object,
  category: PropTypes.object,
}

export default withAppContext(BannerCategories)
