import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Flex, Text } from 'Components/UI'

export const Container = styled(Flex).attrs({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: 1,
  py: 4,
})``

export const Content = styled(Flex).attrs({
  width: 1,
})``

export const Grid = styled(Flex).attrs({
  justifyContent: 'center',
  width: 1,
  mt: 32,
})`
  height: fit-content;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
`

export const Title = styled(Text).attrs({
  fontSize: [2, 3, 27],
  mb: 3,
})`
  font-family: ${({ font }) => font || 'Inter, MarkPro, sans-serif'};
  font-weight: bold;
  letter-spacing: 0.5px;
`

export const NoResult = styled(Text).attrs({
  fontSize: [1, 1, 2],
  my: 32,
})`
  color: ${themeGet('colors.doveGray')};
`
