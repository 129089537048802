import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import Slider from 'react-slick'

import { chevronGlyph } from 'Assets/Svg'

import { Box, Icon } from 'Components/UI'

export const StyledSlider = styled(Slider)`
  .slick-arrow {
    z-index: 1;
  }
  .slick-prev {
    left: -36px;

    @media screen and (max-width: ${themeGet('breakpoints.2')}) {
      left: -24px;
    }
  }
  .slick-next {
    right: -36px;

    @media screen and (max-width: ${themeGet('breakpoints.2')}) {
      right: -24px;
    }
  }

  .slick-dots {
    bottom: 10px;

    > li {
      > button {
        background-color: ${themeGet('colors.white')};
        border-radius: 50%;
        width: 8px;
        height: 8px;
        position: relative;

        &:before {
          content: '';
        }
      }
    }

    > li.slick-active {
      > button {
        width: 14px;
        height: 14px;

        &:before {
          content: ' ';
          width: 8px;
          height: 8px;
          background-color: ${({ color }) =>
            color || themeGet('colors.tealBlue')};
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 1;
        }
      }
    }
  }
`

export const SliderArrow = styled(Box).attrs({})`
  width: 32px !important;
  height: 32px !important;
  cursor: pointer !important;
  position: absolute !important;
  z-index: 100 !important;
`

const sliderBlacklist = ['slideCount', 'currentSlide']

export const NextArrow = styled(SliderArrow).attrs({
  blacklist: sliderBlacklist,
})`
  background-color: transparent;

  border-radius: ${({ roundedborder }) => (roundedborder ? '50%' : '0')};

  align-items: center;
  display: flex !important;
  justify-content: center;

  ::before {
    display: none !important;
    content: '' !important;
  }

  transform: translateY(-50%) rotate(90deg) !important;
`

export const RightArrow = styled(Icon).attrs({
  glyph: chevronGlyph,
  size: 16,
})`
  fill: ${({ fill }) => fill || themeGet('colors.tealBlue')} !important;
`

export const LeftArrow = styled(Icon).attrs({
  glyph: chevronGlyph,
  size: 16,
})`
  fill: ${({ fill }) => fill || themeGet('colors.tealBlue')} !important;
`

export const PrevArrow = styled(NextArrow)`
  transform: translateY(-50%) rotate(270deg) !important;
`
