import styled, { css } from 'styled-components'

import { themeGet } from '@styled-system/theme-get'
import Text from '../Text'
import Box from '../Box'

const borderRadiusCss = ({ roundedborder }) =>
  roundedborder
    ? css`
        border-radius: ${themeGet('borderRadius.2')};
      `
    : `
    border-radius: ${themeGet('borderRadius.1')};
  `

export const Tip = styled(Text).attrs({
  is: 'span',
})`
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  transition: opacity 1s;
  text-align: center;
  white-space: normal;
  align-items: center;
  display: block;
  height: auto;
  padding: 5px;
  width: fit-content;
  min-width: 200px;
  position: absolute;
  z-index: -1;
  font-size: 14px;
  bottom: 130%;
  left: 50%;
  transform: translateX(-50%);
  ${borderRadiusCss}
  :after {
    border: solid 5px;
    border-color: rgba(0, 0, 0, 0.6) transparent transparent transparent;
    content: ' ';
    margin-top: -5px;
    transform: translateX(-50%);
    position: absolute;
    bottom: -10px;
    left: 50%;
  }
`

export const Container = styled(Box)`
  position: relative;
  text-overflow: ellipsis;
  // white-space: nowrap;
  width: ${({ width }) => width || 'auto'};
  min-width: 0;

  :hover {
    ${Tip} {
      display: block;
      z-index: ${({ showtip }) => (showtip ? 1 : -1)};
    }
  }

  /* hack Microsoft-specific media query, it will only be parsed in IE10+. */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 200px;
  }
`
