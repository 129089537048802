import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Button, ButtonAlt, Flex, Modal } from 'Components/UI'

export const DetailsModal = styled(Modal).attrs({
  className: 'service-details-modal',
})`
  overflow-y: auto;
  overflow-x: hidden;

  .service-details-modal {
    padding: 32px;
  }

  @media screen and (max-width: 769px) {
    .service-details-modal {
      padding: 24px;
      margin: 0 12px;
      max-height: calc(100% - 24px);
    }
  }
`

export const DetailsModalContent = styled(Flex).attrs({
  width: ['100%', '100%', '720px'],
})`
  gap: 12px;
  flex-direction: column;
`

export const DetailsModalTopWrapper = styled(Flex).attrs({
  flexDirection: ['column', 'column', 'row'],
})`
  gap: 20px;
`

export const DetailsModalTopContent = styled(Flex).attrs({
  flex: 1,
  flexDirection: 'column',
})`
  gap: 8px;
`

export const DetailsModalButtonHolder = styled(Flex).attrs({
  flexDirection: ['row-reverse', 'row-reverse', 'column'],
})`
  gap: 10px;
`

const roundedborderButtonCss = ({ roundedborder }) =>
  roundedborder &&
  css`
    border-radius: ${themeGet('borderRadius.2')} !important;
    overflow: hidden;
  `

export const StyledButton = styled(Button)`
  background-color: ${({ background }) =>
    background || themeGet('colors.goldenrod')};

  border: 1px solid
    ${({ background }) => background || themeGet('colors.goldenrod')};

  border-radius: ${themeGet('borderRadius.1')};
  color: ${({ color }) => color || themeGet('colors.black')};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  width: 215px;
  min-width: 120px;
  white-space: nowrap;
  height: unset;
  padding: 12px;

  @media screen and (max-width: 769px) {
    flex: 1;
  }

  transition:
    all 0.2s ease 0s,
    -webkit-transform 0.2s ease 0s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 9px 2px;
    transition:
      all 0.2s ease 0s,
      -webkit-transform 0.2s ease 0s;
  }

  ${roundedborderButtonCss};
`

export const StyledButtonAlt = styled(ButtonAlt).attrs({
  invert: 1,
})`
  border-radius: ${themeGet('borderRadius.1')};
  text-transform: uppercase;

  padding: 12px;
  height: unset;
  width: auto;
  min-width: 150px !important;
  font-weight: 500;

  @media screen and (max-width: 769px) {
    flex: 1;
  }

  transition:
    all 0.2s ease 0s,
    -webkit-transform 0.2s ease 0s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 9px 2px;
    transition:
      all 0.2s ease 0s,
      -webkit-transform 0.2s ease 0s;
  }

  ${roundedborderButtonCss};
`
