export const BREAKPOINT_NAMES = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
  LARGE: 'large',
}

export const CARD_IMAGE_HEIGHT = {
  LIST: '171px',
  GRID: '176px',
  LANDSCAPE: '100%',
}

export default {}
