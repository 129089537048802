import React from 'react'
import PropTypes from 'prop-types'

import map from 'lodash/map'
import orderBy from 'lodash/orderBy'

import { Container, StyledButton, A } from './styles'

const BannerTabs = ({ roundedBorder, secondaryColor, tabs }) => {
  const orderedTabs = orderBy(tabs, ['order'])

  return (
    <Container>
      {map(orderedTabs, tab => (
        <A href={tab.url} key={tab.name}>
          <StyledButton
            active={tab.default ? 1 : 0}
            color={secondaryColor}
            rounded={roundedBorder}
          >
            {tab.name}
          </StyledButton>
        </A>
      ))}
    </Container>
  )
}

BannerTabs.defaultProps = {
  roundedBorder: 0,
  secondaryColor: '',
  tabs: [],
}

BannerTabs.propTypes = {
  roundedBorder: PropTypes.number,
  secondaryColor: PropTypes.string,
  tabs: PropTypes.array,
}

export default BannerTabs
