import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getWishlistCount } from 'Store/Selectors/wishlist'

import Header from './Header'

const selector = createStructuredSelector({
  wishlistCount: getWishlistCount,
})

export default connect(selector, null)(Header)
