import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import Box from '../Box'
import Flex from '../Flex'

const borderRadiusCss = ({ roundedborder }) =>
  roundedborder &&
  css`
    border-radius: ${themeGet('borderRadius.2')};
  `

const checkedCss = ({ checked }) =>
  checked &&
  css`
    &:after {
      background-color: ${themeGet('colors.clear')} !important;
      border: 4px solid ${({ color }) => color || themeGet('colors.tealBlue')} !important;
    }
    border: 4px solid ${({ color }) => color || themeGet('colors.tealBlue')};
    outline: none;
    position: relative;
    color: white;
  `

export const StyledRadio = styled(Box).attrs({
  is: 'input',
  type: 'radio',
})`
  background-color: transparent;
  margin: 0;
  padding: 0;
  outline: none;
  box-shadow: none;
  flex-grow: 0;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  &:not(checked) {
    font-weight: 500;
    cursor: pointer;
    position: relative;
    &:before {
      content: '';
      width: 16px;
      height: 16px;
      position: absolute;
      border-radius: 50%;
      background-color: ${themeGet('colors.white')};
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:after {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: ${themeGet('colors.white')};
      border: 1px solid ${themeGet('colors.gray')};
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &:checked {
    &:after {
      background-color: ${themeGet('colors.clear')};
      border: 4px solid ${({ color }) => color || themeGet('colors.tealBlue')};
    }
  }
  &:checked::-ms-check {
    border: 4px solid ${({ color }) => color || themeGet('colors.tealBlue')};
    outline: none;
    position: relative;
    color: white;
  }
  ${checkedCss};
`

export const Label = styled(Flex).attrs({
  is: 'label',
  mb: 3,
  fontSize: 3,
})`
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 100%;
  color: ${themeGet('colors.gray')};

  div {
    white-space: pre-line;
  }

  ${borderRadiusCss};
`
