import React, { memo } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import map from 'lodash/map'
import omit from 'lodash/omit'
import size from 'lodash/size'

import { withAppContext } from 'Services/Context'
import { _ } from 'Services/I18n'
import { Icon } from 'Components/UI'

import {
  FbLogo,
  InstagramLogo,
  TwitterLogo,
  YoutubeLogo,
  PinterestLogo,
} from 'Assets/Svg/Footer'

import {
  Container,
  Content,
  InnerContainer,
  LeftUl,
  LeftLi,
  Link,
  RightUl,
  ColumnTitle,
  SocialUl,
  SocialLi,
  SocialLink,
} from './styles'

const omitKeys = ['cookiesAccepted', 'themeName']

const SOCIAL_TYPES = {
  facebook: 'facebook',
  twitter: 'twitter',
  pinterest: 'pinterest',
  instagram: 'instagram',
  youtube: 'youtube',
}
const SOCIAL_ICONS = {
  [SOCIAL_TYPES.facebook]: FbLogo,
  [SOCIAL_TYPES.twitter]: TwitterLogo,
  [SOCIAL_TYPES.pinterest]: PinterestLogo,
  [SOCIAL_TYPES.instagram]: InstagramLogo,
  [SOCIAL_TYPES.youtube]: YoutubeLogo,
}

const Footer = ({ baseTheme: { colors, footer }, ...rest }) => {
  const columns = get(footer, 'columns', [])
  const socialLinks = get(footer, 'links', [])

  const background = get(colors, 'footer_background')
  const color = get(colors, 'footer_text')

  const renderSocialLinks = () =>
    map(socialLinks, link => (
      <SocialLi key={`type-${link.type}`}>
        <SocialLink color={color} href={link.url} target={link.target}>
          <Icon fill={color} glyph={SOCIAL_ICONS[link.type]} size={23} />
        </SocialLink>
      </SocialLi>
    ))

  const renderLinks = links =>
    map(links, (link, index) => (
      <LeftLi key={`link-${index}-${link.url}`}>
        <Link color={color} href={link.url} target={link.target}>
          {link.label}
        </Link>
      </LeftLi>
    ))

  return (
    <Container background={background}>
      <Content id="app-footer" {...omit(rest, omitKeys)}>
        <InnerContainer>
          {size(columns) > 0 &&
            map(columns, ({ label, links }, index) => (
              <LeftUl key={`column-${index}`}>
                {size(label) > 0 && (
                  <ColumnTitle color={color}>{label}</ColumnTitle>
                )}
                {renderLinks(links)}
              </LeftUl>
            ))}
          {size(socialLinks) > 0 && (
            <RightUl>
              <ColumnTitle color={color} textAlign={['left', 'left', 'right']}>
                {_('common.followUs')}
              </ColumnTitle>
              <SocialUl>{renderSocialLinks()}</SocialUl>
            </RightUl>
          )}
        </InnerContainer>
      </Content>
    </Container>
  )
}

Footer.defaultProps = {}

Footer.propTypes = {
  baseTheme: PropTypes.object.isRequired,
}

export default memo(withAppContext(Footer))
