import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { SingleDatePicker } from 'react-dates'
import omit from 'lodash/omit'

import { useBaseTheme } from 'Hooks'
import { _ } from 'Services/I18n'

import { CloseIcon, DatePickerWrapper, InfoWrapper, InfoButton } from './styles'

const propTypes = {
  rounded: PropTypes.number,
  shadow: PropTypes.number,
  onDateChange: PropTypes.func.isRequired,
  onFocusChange: PropTypes.func.isRequired,
}

const defaultProps = {
  rounded: 0,
  shadow: 0,
}

function DatePicker(props) {
  const { primaryColor, secondaryColor } = useBaseTheme()
  const { rounded, shadow } = props

  const handleOnTodayClick = () => {
    const { onDateChange, onFocusChange } = props

    onDateChange(moment())
    onFocusChange({ focused: false })
  }

  const handleOnTomorrowClick = () => {
    const { onDateChange, onFocusChange } = props

    onDateChange(moment().add(1, 'day'))
    onFocusChange({ focused: false })
  }

  const handleOnClearClick = () => {
    const { onDateChange, onFocusChange } = props

    onDateChange()
    onFocusChange({ focused: false })
  }

  const renderCalendarInfo = () => {
    return (
      <InfoWrapper>
        <InfoButton
          background={primaryColor}
          invert={1}
          rounded={rounded}
          onClick={handleOnTodayClick}
        >
          {_('datePicker.today')}
        </InfoButton>
        <InfoButton
          background={primaryColor}
          invert={1}
          rounded={rounded}
          onClick={handleOnTomorrowClick}
        >
          {_('datePicker.tomorrow')}
        </InfoButton>
        <InfoButton
          background={primaryColor}
          invert={1}
          rounded={rounded}
          onClick={handleOnClearClick}
        >
          {_('action.clear')}
        </InfoButton>
      </InfoWrapper>
    )
  }

  return (
    <DatePickerWrapper
      color={primaryColor}
      rounded={rounded}
      secondarycolor={secondaryColor}
      shadow={shadow}
    >
      <SingleDatePicker
        customCloseIcon={<CloseIcon />}
        noBorder
        readOnly
        renderCalendarInfo={renderCalendarInfo}
        {...omit(props, ['rounded', 'shadow'])}
      />
    </DatePickerWrapper>
  )
}

DatePicker.displayName = 'DatePicker'
DatePicker.propTypes = propTypes
DatePicker.defaultProps = defaultProps

export default DatePicker
