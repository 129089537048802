import styled from 'styled-components'
import { background } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { heartGlyph, longArrowLeftGlyph, pinMapGlyph } from 'Assets/Svg'

import { Flex, Box, Text, Icon, Button } from 'Components/UI'
import { CONTENT_WIDTH, CONTENT_PADDING_X } from 'Constants/ids'

export const Container = styled(Flex).attrs({
  is: 'header',
})`
  background-color: ${themeGet('colors.tealBlue')};
  box-shadow: 0 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 0;
  z-index: 101;

  justify-content: center;

  ${background};
`

export const Content = styled(Flex).attrs(({ withlinks }) => ({
  justifyContent: withlinks ? ['center', 'center', 'space-between'] : 'center',
  px: CONTENT_PADDING_X,
}))`
  width: 100%;
  max-width: ${({ isfullwidth }) => (isfullwidth ? '1600' : CONTENT_WIDTH)}px;

  position: relative;

  align-items: center;
  height: 60px;
  min-height: 60px;
  justify-content: center;
  margin: 0 auto;

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    margin: 0;
    max-width: 100vw;
  }
`
export const AppLogoWrapper = styled(Flex).attrs({
  justifyContent: ['center', 'center', 'normal'],
  flex: 1,
})``

export const AppLogoHolder = styled(Flex).attrs({
  justifyContent: ['center', 'center', 'normal'],
})`
  width: 100%;
  max-width: 230px;

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    max-width: 147px;
    margin-right: 0px;
  }
`

export const AppLogo = styled(Text).attrs({
  is: 'img',
  fontSize: 3,
  m: 0,
})`
  align-items: left;
  display: flex;
  justify-content: left;
  height: 44px;
  user-select: none;
  object-fit: contain;
  max-width: 100%;
`

export const WishlistHolder = styled(Box).attrs({
  display: ['none', 'none', 'flex'],
})`
  position: relative;
  cursor: pointer;
`

export const WishlistIcon = styled(Icon).attrs({
  glyph: heartGlyph,
})`
  fill: ${({ fill }) => fill || themeGet('colors.white')};

  &:hover {
    opacity: 0.9;
  }
`

export const WishlistCountBadge = styled(Flex).attrs({})`
  display: block;
  position: absolute;
  right: -10px;
  top: -10px;
  border-radius: 50%;
  background-color: ${themeGet('colors.red')};

  height: 18px;
  width: 18px;

  font-size: 11px;
  text-align: center;
  color: ${themeGet('colors.white')};
`

export const HeaderLinksContainer = styled(Flex).attrs({
  flexDirection: 'row-reverse',
  alignItems: 'center',
  display: ['none', 'none', 'flex'],
})`
  flex: 1;
  justify-content: right;
`

export const LinkExternal = styled(Text).attrs({
  is: 'a',
  fontSize: [1, 14],
  mt: '2px',
  px: [2, 2, 2, 15],
})`
  background: none;
  transition: all ${themeGet('transitionTime')} ease;
  cursor: pointer;
  white-space: nowrap;
  font-weight: bold;
  text-decoration: none;

  &.active,
  :hover {
    opacity: 0.9;
    background: none;
  }

  &:last-child {
    padding-left: 0px !important;
  }
`

export const LinkLabel = styled(Flex)`
  color: ${({ color }) => color && color};
`
export const StyledButton = styled(Button).attrs({
  width: 'fit-content',
})`
  color: ${({ color }) => color && color};
  border: 2px solid ${({ color }) => color && color};
  background-color: ${({ bg }) => bg || 'transparent'};
  border-color: ${({ bc }) => bc || 'white'};
  height: 40px;
  font-size: 14px;
  padding: 0 32px;

  &:hover {
    color: ${({ color }) => color && color} !important;
  }

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    padding: 0 16px;
  }
`

export const LocationHolder = styled(Flex).attrs({
  ml: 4,
  display: ['none', 'none', 'flex', 'flex'],
})`
  cursor: pointer;
  transition: all 200ms;

  align-items: center;
  justify-content: center;

  height: 100%;
`

export const LocationIcon = styled(Icon).attrs({
  glyph: pinMapGlyph,
  size: 22,
})`
  z-index: 2;
  stroke: ${({ stroke }) => stroke || 'white'};
`

export const BackButtonHolder = styled(Flex).attrs({
  display: ['flex', 'flex', 'none', 'none'],
})`
  cursor: pointer;
  align-items: center;
  height: 60px;
  margin: 0;
  padding: 0 10px;
  margin-left: -10px;
`

export const BackButton = styled(Icon).attrs({
  glyph: longArrowLeftGlyph,
  size: 22,
})`
  fill: ${({ fill }) => fill || 'white'};
`
