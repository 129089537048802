import styled from 'styled-components'

import { Text, Flex } from 'Components/UI'

export const Container = styled(Flex).attrs({})`
  min-height: 20px;
`

export const Category = styled(Flex).attrs({
  px: '12px',
  py: '4px',
  mr: 1,
})`
  border-radius: 9999px;
  border: 1px solid ${({ color }) => color};
  cursor: pointer;
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: 0;
`

export const Label = styled(Text)`
  font-size: 11px;
  font-weight: 700;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Count = styled(Label)`
  text-decoration: underline;
  cursor: pointer;
  padding-top: 4px;
  padding-bottom: 4px;
  text-overflow: unset;
  overflow: visible;
`
