import styled, { css } from 'styled-components'
import {
  space,
  color,
  fontWeight,
  textAlign,
  lineHeight,
  fontSize,
  display,
  width,
} from 'styled-system'
import tag from 'clean-tag'

const ellipsisCss = props =>
  props.ellipsis &&
  css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `

const Text = styled(tag.p).attrs({
  blacklist: [
    'lineHeight',
    'textAlign',
    'fontSize',
    'fontWeight',
    'alignItems',
  ],
})`
  font-family: Inter, sans-serif;
  font-weight: normal;

  ${ellipsisCss};

  ${space}
  ${color}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${textAlign}
  ${display}
  ${width}
`

Text.displayName = 'Text'

Text.propTypes = {
  ...space.propTypes,
  ...color.propTypes,
  ...fontSize.propTypes,
  ...lineHeight.propTypes,
  ...textAlign.propTypes,
  ...width.propTypes,
}

Text.defaultProps = {
  lineHeight: 1,
  fontSize: 2,
}

export default Text
