import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import map from 'lodash/map'

import { withAppContext } from 'Services/Context'

import themes from 'Theme'

import { Select } from 'Components/UI'

const DROPDOWN_OPTIONS = map(themes, (theme, key) => ({
  value: key,
  label: theme.name,
}))

class ThemeSwitch extends PureComponent {
  state = {}

  handleSelect = ({ value }) => {
    const { onChangeTheme } = this.props

    onChangeTheme(value)
  }

  render() {
    const { theme, themeName, ...rest } = this.props

    return (
      <Select
        options={DROPDOWN_OPTIONS}
        value={{ value: themeName, label: theme.name }}
        width={200}
        onChange={this.handleSelect}
        {...rest}
      />
    )
  }
}

ThemeSwitch.propTypes = {
  theme: PropTypes.object.isRequired,
  themeName: PropTypes.string.isRequired,
  onChangeTheme: PropTypes.func.isRequired,
}

export default withAppContext(ThemeSwitch)
