import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { space } from 'styled-system'
import Box from '../Box'

export const Container = styled(Box)`
  width: 100%;
  height: 1px;
  background-color: ${themeGet('colors.lightGrey')};

  ${space};
`

export default {}
