import { opacify } from 'polished'

import colors from './colors'

const HOVER_VALUE = 0.05
const ACTIVE_VALUE = 0.1

export default {
  bg: {
    link: colors.clearFromBlack,
    outline: colors.clearFromBlack,
    reverted: colors.white,
    default: colors.primary,
  },
  color: {
    link: colors.font.primary,
    outline: colors.white,
    reverted: colors.primary,
    default: colors.white,
  },
  hover: {
    link: opacify(HOVER_VALUE, colors.clearFromBlack),
    outline: colors.whiteHover,
    reverted: colors.white,
    default: colors.primaryHover,
  },
  active: {
    link: opacify(ACTIVE_VALUE, colors.clearFromBlack),
    outline: colors.whiteActive,
    reverted: colors.white,
    default: colors.primaryActive,
  },
}
