import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import onClickOutside from 'react-onclickoutside'

import { Container, PopupWrapper, Close, Button } from './styles'

class Popup extends PureComponent {
  state = {}

  ref = null

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  addRef = ref => {
    this.ref = ref
  }

  handleKeyDown = event => {
    if (this.ref && event.key === 'Escape') {
      this.handleClose()
    }
  }

  handleClickOutside = () => {
    if (this.ref) {
      this.handleClose()
    }
  }

  handleClose = () => {
    const { onCallback } = this.props
    onCallback(false)
  }

  handleSubmit = () => {
    const { onCallback } = this.props
    onCallback(true)
  }

  render() {
    const { text, isOpen, children } = this.props

    return (
      <Container>
        {isOpen && (
          <PopupWrapper ref={this.addRef}>
            {text}
            <Close onClick={this.handleClose} />
            <Button onClick={this.handleSubmit}>Ok</Button>
          </PopupWrapper>
        )}
        {children}
      </Container>
    )
  }
}

Popup.propTypes = {
  children: PropTypes.element.isRequired,
  isOpen: PropTypes.bool.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onCallback: PropTypes.func.isRequired,
}

const clickOutsideConfig = {
  handleClickOutside: instance => {
    return instance.handleClickOutside
  },
}

export default onClickOutside(Popup, clickOutsideConfig)
