import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  getSearchDate,
  getServicesAvailability,
  getServicesNextAvailableDate,
} from 'Store/Selectors/marketplace'

import ServiceCard from './ServiceCard'

const selector = createStructuredSelector({
  availabilityDate: getSearchDate,
  servicesAvailability: getServicesAvailability,
  servicesNextAvailableDate: getServicesNextAvailableDate,
})

const actions = {}

export default connect(selector, actions)(ServiceCard)
