import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Flex, Box } from 'Components/UI'

export const Container = styled(Box).attrs({
  px: [3, 3, 24],
  pt: [3, 3, 24],
})`
  background-color: ${themeGet('colors.white')};
`

export const Header = styled(Flex).attrs({
  justifyContent: 'space-between',
  alignItems: 'center',
})`
  width: 100%;
`

export const Label = styled(Box).attrs({})`
  font-size: 14px;
  font-weight: 700;
  color: ${themeGet('color.black')};
  line-height: 1;
  text-transform: lowercase;

  &:first-letter {
    text-transform: capitalize;
  }
`

export const ValueLabel = styled(Box).attrs({})`
  font-size: 12px;
  font-weight: 500;
  color: ${themeGet('color.text')};
  line-height: 1;

  cursor: default;
  background-color: ${themeGet('colors.pearl')};
  border: 1px solid ${themeGet('colors.concrete')};
  border-radius: 4px;
  padding: 4px 8px;
`

export const Content = styled(Box).attrs({
  pb: 2,
})`
  border-bottom: 1px solid ${themeGet('colors.concrete')};
`
