import React from 'react'
import PropTypes from 'prop-types'

import Box from '../Box'

import { StyledRadio, Label } from './styles'

const renderField = ({ input, meta, ...rest }) => (
  <StyledRadio {...input} {...rest} />
)

renderField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}

const Radio = ({ checked, label, input, name, roundedborder, ...rest }) => (
  <Label roundedborder={roundedborder}>
    <StyledRadio checked={checked} name={name} {...input} {...rest} />
    <Box w={1}>{label}</Box>
  </Label>
)

Radio.displayName = 'Radio'

Radio.defaultProps = {
  checked: false,
  input: {},
  label: '',
  name: '',
  roundedborder: 0,
}

Radio.propTypes = {
  checked: PropTypes.bool,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]),
  }),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string,
  roundedborder: PropTypes.number,
}

export default Radio
