import { connect } from 'react-redux'

import { acceptCookiePolicy } from 'Store/Actions/app'

import CookiesPolicy from './CookiesPolicy'

const actions = {
  onAcceptCookiePolicy: acceptCookiePolicy,
}

export default connect(null, actions)(CookiesPolicy)
