import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import map from 'lodash/map'
import noop from 'lodash/noop'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'

import { components } from 'react-select'
import { Select } from 'Components/UI'

import CustomOption from './Option'

import {
  Badge,
  BadgeContent,
  Container,
  DropdownContainer,
  PlaceholderContainer,
} from './styles'

class FilterSelect extends PureComponent {
  state = {
    menuIsOpen: undefined,
  }

  renderDropdownContainer = props => (
    <DropdownContainer>
      <components.Menu {...props}>{props.children}</components.Menu>
    </DropdownContainer>
  )

  renderMultiValue = props => {
    const { isMultiValue, value, placeholder } = this.props

    if (isMultiValue) {
      return (
        <components.MultiValue {...props} placeholder={placeholder}>
          {props.children}
        </components.MultiValue>
      )
    }

    return (
      <components.Placeholder {...props}>
        {value.length > 0 ? (
          <PlaceholderContainer>
            {placeholder}
            <Badge>
              <BadgeContent>{value.length}</BadgeContent>
            </Badge>
          </PlaceholderContainer>
        ) : (
          placeholder
        )}
      </components.Placeholder>
    )
  }

  renderSingleValue = ({ children, ...props }) => {
    const { value, placeholder, placeholdervalue } = this.props

    if (placeholdervalue) {
      return (
        <components.Placeholder {...props}>
          {value.length > 0 ? (
            <PlaceholderContainer>
              {placeholder}
              <Badge>
                <BadgeContent>{value.length}</BadgeContent>
              </Badge>
            </PlaceholderContainer>
          ) : (
            placeholder
          )}
        </components.Placeholder>
      )
    }

    return (
      <components.SingleValue {...props} placeholder={placeholder}>
        {children}
      </components.SingleValue>
    )
  }

  renderOption = props => {
    const { color, optiontype, value } = this.props

    return (
      <CustomOption
        categoryValue={value}
        color={color}
        optiontype={optiontype}
        onChange={this.handleChange}
        {...props}
      />
    )
  }

  renderCustomClearText = () => {
    const { clearIndicator } = this.props
    return <>{clearIndicator}</>
  }

  renderClearIndicator = props => {
    const {
      children = this.renderCustomClearText(),
      innerProps: { ref, ...restInnerProps },
    } = props
    return (
      <div {...restInnerProps} ref={ref}>
        {children}
      </div>
    )
  }

  handleChange = values => {
    const { closeOnSelect, options, onChange } = this.props

    if (!closeOnSelect) {
      this.setState({ menuIsOpen: true })
    }

    const allOptionIds = map(options || [], 'id')

    onChange(values, allOptionIds)
  }

  handleBlur = event => {
    const { onBlur } = this.props

    this.setState({ menuIsOpen: undefined })

    onBlur(event)
  }

  render() {
    const { menuIsOpen } = this.state
    const {
      clearIndicator,
      isClearable,
      isMulti,
      options,
      placeholder,
      value,
      width,
      onBlur,
      onChange,
      onInputChange,
      closeOnSelect,
      ...rest
    } = this.props

    return (
      <Container width={width} {...rest}>
        <Select
          components={pickBy(
            {
              Option: this.renderOption,
              Menu: this.renderDropdownContainer,
              MultiValue: this.renderMultiValue,
              SingleValue: this.renderSingleValue,
              ...(clearIndicator !== null && {
                ClearIndicator: this.renderClearIndicator,
              }),
            },
            identity,
          )}
          hideSelectedOptions={false}
          isClearable={isClearable}
          isMulti={isMulti}
          isSearchable={false}
          menuIsOpen={menuIsOpen}
          options={options}
          placeholder={placeholder}
          value={value}
          width={width}
          withIcon={false}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onInputChange={onInputChange}
        />
      </Container>
    )
  }
}

FilterSelect.defaultProps = {
  clearIndicator: null,
  closeOnSelect: true,
  color: '',
  isClearable: false,
  isMulti: true,
  isMultiValue: false,
  optiontype: 'checkbox',
  placeholdervalue: 0,
  value: null,
  width: 'auto',
  onBlur: noop,
  onInputChange: noop,
}

FilterSelect.propTypes = {
  clearIndicator: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  closeOnSelect: PropTypes.bool,
  color: PropTypes.string,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isMultiValue: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  optiontype: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  placeholdervalue: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
    PropTypes.string,
  ]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
}

export default FilterSelect
