import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import { PillContainer, Label } from './styles'

function Pill({ active, big, label, pillcolor, selectable, onActive }) {
  const handlePill = useCallback(() => {
    onActive(label)
  }, [label])

  return (
    <PillContainer
      active={active}
      p={big ? '6px 14px' : '4px 12px'}
      pillcolor={pillcolor}
      selectable={selectable ? 1 : 0}
      onClick={handlePill}
    >
      <Label>{label}</Label>
    </PillContainer>
  )
}

Pill.defaultProps = {
  active: false,
  big: false,
  label: '',
  pillcolor: '',
  selectable: false,
  onActive: noop,
}

Pill.propTypes = {
  active: PropTypes.bool,
  big: PropTypes.bool,
  label: PropTypes.string,
  pillcolor: PropTypes.string,
  selectable: PropTypes.bool,
  onActive: PropTypes.func,
}

export default Pill
