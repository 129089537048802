import { createAsyncAction } from 'Store/utils'

import apiCall from 'Services/Api'

export const LOAD_LOCATIONS = createAsyncAction('marketplace/LOAD_LOCATIONS')

export const loadLocations = () =>
  apiCall({
    method: 'GET',
    endpoint: '/marketplace/locations',
    types: LOAD_LOCATIONS,
  })

export default {}
