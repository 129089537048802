import { all, put, fork } from 'redux-saga/effects'

import { appReady } from 'Store/Actions/app'

import analytics from './analytics'
import session from './session'
import locations from './locations'

export default function* root(isSSR) {
  if (!isSSR) {
    yield put(appReady())
  }
  yield all([fork(analytics), fork(session), fork(locations)])
}
