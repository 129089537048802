import Immutable from 'seamless-immutable'

import build from 'redux-object'

import get from 'lodash/get'
import map from 'lodash/map'
import isArray from 'lodash/isArray'

export const denormalize = (entities, type, id) =>
  build(entities, type, isArray(id) ? map(id, i => i.id || i) : id, {
    eager: true,
  })

export const transformToImmutable = state =>
  get(state, 'withoutImmutable') === true ? state : Immutable(state)

export const createReducer =
  (initialState, handlers) =>
  (state = transformToImmutable(initialState), action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state

export const createAsyncAction = type => ({
  REQUEST: `${type}.REQUEST`,
  SUCCESS: `${type}.SUCCESS`,
  FAILURE: `${type}.FAILURE`,
})
