import React from 'react'
import PropTypes from 'prop-types'
import { withAppContext } from 'Services/Context'
import { useRouteMatch } from 'react-router'

import map from 'lodash/map'
import get from 'lodash/get'

import { Box } from 'Components/UI'
import ServiceRow from '../ServiceRow'

const ServiceList = ({
  baseTheme,
  breakpoint,
  merchant,
  services,
  servicesAvailability,
  ...rest
}) => {
  if (!merchant) {
    return null
  }

  const match = useRouteMatch()

  const merchantDetailPage = match?.path === '/business/:id/:slug'

  let merchantDetailTruncateLines = services.length > 1 ? 3 : 18

  if (services.length === 2) {
    merchantDetailTruncateLines = 8
  }

  const merchantData = {
    business: {
      name: merchant.name,
      token: merchant.token,
      slug: merchant.slug,
    },
    reviews: merchant.reviews,
  }

  return (
    <Box {...rest}>
      {map(services, (service, index) => {
        const availability = get(servicesAvailability, service.id)

        return (
          <ServiceRow
            availability={availability}
            breakpoint={breakpoint}
            key={index}
            merchant={merchantData}
            service={service}
            showDescription={merchantDetailPage}
            truncateLines={merchantDetailPage ? merchantDetailTruncateLines : 5}
          />
        )
      })}
    </Box>
  )
}

ServiceList.defaultProps = {
  merchant: null,
  services: [],
  servicesAvailability: null,
}

ServiceList.propTypes = {
  baseTheme: PropTypes.object.isRequired,
  breakpoint: PropTypes.string.isRequired,
  merchant: PropTypes.object,
  services: PropTypes.array,
  servicesAvailability: PropTypes.object,
}

export default withAppContext(ServiceList)
