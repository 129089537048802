import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  getIsLoadingServicesAvailability,
  getIsLoadingServicesNextAvailableDate,
  getSearchDate,
} from 'Store/Selectors/marketplace'

import ServiceAvailability from './ServiceAvailability'

const selector = createStructuredSelector({
  availabilityDate: getSearchDate,
  isAvailabilityLoading: getIsLoadingServicesAvailability,
  isNextAvailableDateLoading: getIsLoadingServicesNextAvailableDate,
})

const actions = {}

export default connect(selector, actions)(ServiceAvailability)
