import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import Flex from '../Flex'

export const Container = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
`

export const Spinner = styled(Flex)`
  height: ${({ size }) => size && size}px;
  width: ${({ size }) => size && size}px;

  border-radius: 50%;
  border-top: 4px solid ${themeGet('colors.frenchGray')};
  border-right: 4px solid ${themeGet('colors.frenchGray')};
  border-bottom: 4px solid ${themeGet('colors.frenchGray')};
  border-left: 4px solid ${({ color }) => color || themeGet('colors.gray')};

  animation: spin 1.1s infinite linear;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`

class Loader extends PureComponent {
  state = {}

  render() {
    const { color, size } = this.props

    return (
      <Container>
        <Spinner color={color} size={size} />
      </Container>
    )
  }
}

Loader.displayName = 'Loader'

Loader.defaultProps = {
  color: '',
  size: 56,
}

Loader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

export default Loader
