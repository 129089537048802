import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { init } from '@sentry/browser'

import { SENTRY_DSN } from 'Config/app'

import App from 'Containers/App'

import createStore from 'Store'

const initialState = window.INITIAL_STATE || {}

init({
  dsn: SENTRY_DSN,
})

const { store, history } = createStore({ initialState })

export default () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>
)
