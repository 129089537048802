import React from 'react'
import PropTypes from 'prop-types'
import { matchPath, useHistory } from 'react-router'

import map from 'lodash/map'

import { MARKETPLACE_SERVICE_TYPE, SEARCH_VIEW } from 'Constants/ids'
import { PUBLIC_PATHS } from 'Constants/paths'

import { useBaseTheme } from 'Hooks'

import { gridGlyph, listGlyph, mapMarkerGlyph } from 'Assets/Svg'

import { Container, Option, OptionIcon } from './styles'

const SearchViewSwitch = ({ value, onChange, ...rest }) => {
  const { isMobileDevice, marketplaceServiceType, primaryColor } =
    useBaseTheme()
  const history = useHistory()

  const handleChangeOption = option => {
    if (value === option) {
      return
    }

    onChange(option)
  }

  const isSearchPage = matchPath(history.location.pathname, {
    path: [PUBLIC_PATHS.SEARCH_QUERY()],
    exact: true,
  })

  const isMapOptionVisible =
    isSearchPage && marketplaceServiceType !== MARKETPLACE_SERVICE_TYPE.online

  const options = [
    {
      id: SEARCH_VIEW.LIST,
      label: 'List',
      glyph: listGlyph,
      visible: true,
    },
    {
      id: SEARCH_VIEW.GRID,
      label: 'Grid',
      glyph: gridGlyph,
      visible: !isMobileDevice,
    },
    {
      id: SEARCH_VIEW.MAP,
      label: 'Map',
      glyph: mapMarkerGlyph,
      visible: isMapOptionVisible,
    },
  ]

  if (options.filter(option => option.visible).length <= 1) {
    return null
  }

  const renderOptions = () => {
    return map(options, ({ label, id, glyph, visible }) => {
      let currentValue = value

      if (isMobileDevice && currentValue === SEARCH_VIEW.GRID) {
        currentValue = SEARCH_VIEW.LIST
      }

      const isActive = currentValue === id

      if (!visible) {
        return null
      }

      return (
        <Option
          active={isActive}
          activecolor={primaryColor}
          key={id}
          onClick={() => handleChangeOption(id)}
        >
          <OptionIcon glyph={glyph} /> {label}
        </Option>
      )
    })
  }

  return <Container {...rest}>{renderOptions()}</Container>
}

SearchViewSwitch.defaultProps = {}

SearchViewSwitch.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default SearchViewSwitch
