import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Item from './Item'
import Picker from './Picker'

import { StyledDropdown, Opener, Menu, ChevronDown } from './styles'

class Dropdown extends PureComponent {
  state = {
    // eslint-disable-next-line
    selected: this.props.value,
    isOpened: false,
  }

  // eslint-disable-next-line
  mounted = true

  ref = null

  getChildContext() {
    const { isOpened } = this.state
    return { isOpened }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick, false)
    document.addEventListener('touchend', this.handleDocumentClick, false)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selected } = this.state
    const { value } = nextProps

    if (value && value !== selected) {
      this.setState({
        selected: value,
      })
    } else if (!value) {
      this.setState({
        selected: null,
      })
    }
  }

  componentWillUnmount() {
    this.mounted = false
    document.removeEventListener('click', this.handleDocumentClick, false)
    document.removeEventListener('touchend', this.handleDocumentClick, false)
  }

  onChange = nextState => {
    const { selected } = this.state
    const { onChange } = this.props
    if (nextState.selected !== selected && onChange) {
      onChange(nextState.selected)
    }
  }

  addRef = ref => {
    this.ref = ref
  }

  handleSetValue = (value, label) => {
    const nextState = {
      selected: {
        value,
        label,
      },
      isOpened: false,
    }
    this.onChange(nextState)
    this.setState(nextState)
  }

  handleMenu = () => {
    const { isOpened } = this.state
    const { closeOnClick } = this.props

    if (isOpened && closeOnClick) {
      this.setState({ isOpened: false })
    }
  }

  handleDocumentClick = event => {
    if (this.mounted && this.ref) {
      if (!this.ref.contains(event.target)) {
        this.setState({ isOpened: false })
      }
    }
  }

  handleMouseDown = event => {
    const { isOpened } = this.state

    if (event.type === 'mousedown' && event.button !== 0) return

    this.setState({
      isOpened: !isOpened,
    })
  }

  renderOption(option, index) {
    const { selected } = this.state
    const value = option.value || option.label || option
    const label = option.label || option.value || option

    const key = `${value}-${index}`

    return (
      <Item
        key={key}
        label={label}
        selected={value === selected}
        value={value}
        onSelect={this.handleSetValue}
      />
    )
  }

  render() {
    // eslint-disable-next-line
    const { label, left, children, options, top, chevron, ...rest } = this.props
    const { isOpened } = this.state

    return (
      <StyledDropdown ref={this.addRef} {...rest}>
        <Opener
          onMouseDown={this.handleMouseDown}
          onTouchEnd={this.handleMouseDown}
        >
          {label}
          {chevron && <ChevronDown opened={isOpened ? 1 : 0} />}
        </Opener>

        {isOpened && (
          <Menu left={left ? 1 : 0} top={top ? 1 : 0} onClick={this.handleMenu}>
            {options &&
              options.map((item, index) => this.renderOption(item, index))}
            {children && children}
          </Menu>
        )}
      </StyledDropdown>
    )
  }
}

Dropdown.displayName = 'Dropdown'

Dropdown.Picker = Picker

Dropdown.childContextTypes = {
  isOpened: PropTypes.bool,
}

Dropdown.defaultProps = {
  chevron: false,
  children: null,
  closeOnClick: false,
  options: [],
  label: 'Dropmenu',
  left: true,
  top: false,
  value: undefined,
  onChange: null,
}

Dropdown.propTypes = {
  chevron: PropTypes.bool,
  children: PropTypes.any,
  closeOnClick: PropTypes.bool,
  label: PropTypes.any,
  left: PropTypes.bool,
  options: PropTypes.array,
  top: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default Dropdown
