import styled, { css } from 'styled-components'
import { space, fontSize, background } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { Flex, Box, Text, Icon } from 'Components/UI'

export const HeadOptionTitle = styled(Text).attrs({
  is: 'span',
  fontSize: '14px',
})`
  font-weight: 600;
  cursor: pointer;
  color: ${({ color }) => color || themeGet('colors.tealBlue')};

  svg {
    fill: ${({ color }) => color || themeGet('colors.tealBlue')};
  }

  ${space};
  ${fontSize};
`

export const SecondaryOptionTitleHolder = styled(Flex).attrs({
  justifyContent: 'space-between',
})``

export const Circle = styled(Box).attrs({})`
  margin-left: 8px;
  width: 16px;
  min-width: 16px;
  height: 16px;
  border: 1px solid ${themeGet('colors.alto')};
  border-radius: 50%;

  ${background};
`

export const SecondaryOptionTitle = styled(Text).attrs({
  is: 'span',
  fontSize: '14px',
})`
  color: ${themeGet('colors.gray')};

  ${fontSize};
`

const hoveredCss = () => css`
  :hover {
    background-color: ${themeGet('colors.tealBlue')};
    color: ${themeGet('colors.white')};

    svg {
      color: ${themeGet('colors.white')};
      fill: ${themeGet('colors.white')};
    }

    .highlight-option {
      color: ${themeGet('colors.white')};
      font-weight: bold;
    }

    ${HeadOptionTitle},
    ${SecondaryOptionTitle} {
      color: ${themeGet('colors.white')};
    }

    ${background};
  }
`

export const HeadOption = styled(Box).attrs({
  py: 2,
  px: 23,
})`
  color: ${themeGet('colors.font.secondary')};
  ${space};

  .highlight-option {
    background-color: transparent;
    font-family: Inter, sans-serif;
    font-weight: bold;
  }

  ${hoveredCss};
`

export const SecondaryOption = styled(Box).attrs(({ near }) => ({
  py: 2,
  px: near ? 40 : 35,
  mb: near ? 10 : 'initial',
}))`
  cursor: pointer;

  &:hover {
    background: ${themeGet('colors.tealBlue')};
    color: ${themeGet('colors.white')};

    ${background};
  }

  &:hover > span {
    color: ${themeGet('colors.white')};
  }

  .highlight-option {
    background-color: transparent;
    color: black;
    font-family: Inter, sans-serif;
    font-weight: bold;
  }

  ${space};
  ${hoveredCss};
`

export const MapPinIconContainer = styled(Icon).attrs({ mr: 8 })`
  color: ${({ color }) => color || themeGet('colors.tealBlue')};
  margin-top: -1px;
`
