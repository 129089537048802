import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import map from 'lodash/map'
import size from 'lodash/size'
import filter from 'lodash/filter'

import { Container, Grid, PrimaryCategory, SecondaryCategory } from './styles'

function Category({
  category,
  color,
  isDetailPage,
  onPrimaryCategory,
  onSecondaryCategory,
}) {
  const secondaryCategories = filter(
    get(category, 'category_2', []),
    secondaryCategory => secondaryCategory.is_visible,
  )

  if (!size(secondaryCategories)) {
    return null
  }

  return (
    <Container>
      <PrimaryCategory color={color} onClick={onPrimaryCategory({ category })}>
        {category.bucket_name}
      </PrimaryCategory>
      <Grid ml={isDetailPage ? 12 : 0}>
        {map(secondaryCategories, secondaryCategory => (
          <SecondaryCategory
            color={color}
            key={secondaryCategory.bucket_id}
            onClick={onSecondaryCategory({ category, secondaryCategory })}
          >
            {secondaryCategory.bucket_name}
          </SecondaryCategory>
        ))}
      </Grid>
    </Container>
  )
}

Category.defaultProps = {
  category: {},
  color: null,
  isDetailPage: false,
}

Category.propTypes = {
  category: PropTypes.object,
  color: PropTypes.string,
  isDetailPage: PropTypes.bool,
  onPrimaryCategory: PropTypes.func.isRequired,
  onSecondaryCategory: PropTypes.func.isRequired,
}

export default Category
