import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import app from './app'
import marketplace from './marketplace'
import persist from './persist'
import locations from './locations'
import wishlist from './wishlist'

export default history =>
  combineReducers({
    app,
    marketplace,
    persist,
    locations,
    wishlist,
    router: connectRouter(history),
  })
