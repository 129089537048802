import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import { chevronDownGlyph } from 'Assets/Svg'

import Icon from '../Icon'

import { Container, StyledSelect, SearchIcon, ClearIcon } from './styles'

const DropdownIndicator = () => <Icon glyph={chevronDownGlyph} size={12} />

class Select extends PureComponent {
  inputRef = React.createRef()

  handleClear = () => {
    const { onInputChange } = this.props
    onInputChange('', { action: 'input-change' })
  }

  render() {
    const {
      customGlyph,
      roundedborder,
      className,
      color,
      options,
      portal,
      withIcon,
      onClear,
      fieldRef,
      value,
      components,
      ...rest
    } = this.props
    return (
      <Container>
        {withIcon && (
          <SearchIcon color={color} customglyph={customGlyph} fill={color} />
        )}
        <StyledSelect
          blurInputOnSelect={false}
          className={`${className} react-select__container`}
          classNamePrefix="react-select"
          color={color}
          components={{ ...components, DropdownIndicator }}
          menuPortalTarget={portal}
          options={options}
          ref={fieldRef || this.inputRef}
          roundedborder={roundedborder}
          value={value}
          {...rest}
        />
        {rest.isClearable && rest.inputValue && !rest.isLoading && (
          <ClearIcon fill={color} onClick={this.handleClear} />
        )}
      </Container>
    )
  }
}

Select.displayName = 'Select'

Select.defaultProps = {
  components: [],
  customGlyph: null,
  className: '',
  color: '',
  fieldRef: null,
  portal: null,
  roundedborder: 0,
  withIcon: true,
  value: null,
  onClear: noop,
  onInputChange: noop,
}

Select.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  components: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  customGlyph: PropTypes.any,
  fieldRef: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),
  ).isRequired,
  portal: PropTypes.object,
  roundedborder: PropTypes.number,
  value: PropTypes.any,
  withIcon: PropTypes.bool,
  onClear: PropTypes.func,
  onInputChange: PropTypes.func,
}

export default Select
