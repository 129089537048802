import styled from 'styled-components'
import { width, fontWeight } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import Text from '../Text'

const Title = styled(Text).attrs({
  is: 'h1',
  fontSize: [3, 4, 4],
})`
  font-family: Inter, sans-serif;
  font-weight: bold;
  width: 100%;

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    font-weight: 600;
  }
`

Title.displayName = 'Title'

Title.H2 = styled(Title).attrs({
  is: 'h2',
  fontSize: [2, 3, 3],
})``
Title.H2.displayName = 'Title.H2'

Title.H3 = styled(Title).attrs({
  is: 'h3',
  fontSize: [2, 2, 2],
})`
  font-weight: normal;

  ${fontWeight};
  ${width};
`
Title.H3.displayName = 'Title.H3'

Title.H4 = styled(Title).attrs({
  is: 'h4',
  fontSize: [2, 2, 2],
})``
Title.H4.displayName = 'Title.H4'

Title.Page = styled(Title).attrs({
  py: [3, 3, 4],
  mt: [3, 0, 0],
  ml: [3, 0, 0],
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
Title.Page.displayName = 'Title.Page'

export default Title
