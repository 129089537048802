import styled from 'styled-components'

import { Text as BaseText, Flex, Icon } from 'Components/UI'

import { starSolidGlyph } from 'Assets/Svg'

export const RatingStarsContainer = styled(Flex)`
  align-items: center;
  flex-wrap: wrap;
  width: fit-content;
`
export const ReviewAverage = styled(BaseText)`
  margin-left: 4px;
  line-height: 1.25;
`
export const ReviewTotal = styled(BaseText)`
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  line-height: 1.25;

  span {
    text-decoration: ${({ clickable }) => (clickable ? 'underline' : 'none')};
    line-height: 1.25;
  }

  &:hover span {
    text-decoration: none;
  }
`

export const StarIcon = styled(Icon).attrs({
  glyph: starSolidGlyph,
})`
  margin-top: -3px;
`
