import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import map from 'lodash/map'
import get from 'lodash/get'
import size from 'lodash/size'
import slice from 'lodash/slice'

import { useBaseTheme } from 'Hooks'

import { Category, Row, Label, Plus, PlusButton } from './styles'

function Categories({ categories, active, limit, subtle }) {
  if (!categories.length) return null

  const [collapsed, setCollapsed] = useState(true)

  const { primaryColor, secondaryColor } = useBaseTheme()

  const allCategories = collapsed ? slice(categories, 0, limit) : categories

  const handleShow = useCallback(
    e => {
      e.stopPropagation()
      e.preventDefault()

      setCollapsed(false)
    },
    [collapsed],
  )

  const renderCategories = useMemo(
    () =>
      map(allCategories, ({ id, name, supplementary }) => {
        let categoryColor = supplementary ? primaryColor : secondaryColor

        categoryColor = subtle ? '#52525B' : categoryColor

        return (
          <Category
            categorycolor={active ? 'white' : categoryColor}
            key={id || name}
            px={subtle ? '12px' : '16px'}
            py={subtle ? '3px' : '5px'}
          >
            <Label
              fontSize={subtle ? 11 : 12}
              fontWeight={subtle ? 'normal' : 700}
            >
              {name}
            </Label>
          </Category>
        )
      }),
    [allCategories, active],
  )

  const renderCountLeft = useMemo(() => {
    const categoryColor = get(categories, ['3', 'supplementary'])
      ? secondaryColor
      : primaryColor

    return size(categories) > limit ? (
      <PlusButton
        categorycolor={active ? 'white' : categoryColor}
        height={subtle ? 18 : 24}
        width={subtle ? 18 : 24}
        onClick={handleShow}
      >
        <Plus>+</Plus>
      </PlusButton>
    ) : null
  }, [allCategories, active])
  return (
    <Row>
      {renderCategories}
      {collapsed && renderCountLeft}
    </Row>
  )
}

Categories.defaultProps = {
  active: false,
  categories: [],
  limit: 3,
  subtle: false,
}

Categories.propTypes = {
  active: PropTypes.bool,
  categories: PropTypes.array,
  limit: PropTypes.number,
  subtle: PropTypes.bool,
}

export default Categories
