import { darken, lighten } from 'polished'

const palette = {
  blue: '#060e41',
  green: '#31d28f',
  yellow: '#ffec3e',
  orange: '#ff8827',
  salmon: '#ff6666',
  purple: '#d64292',
  lightGray: '#d1d5da',
  white: '#ffffff',
  black: '#000000',
  blackOpacity: 'rgba(0, 0, 0, 0.5)',
  frenchGray: '#c3c0c4',
  osloGray: '#868788',
  doveGray: '#646464',
}

const colors = {
  clear: 'rgba(255, 255, 255, 0)',
  clearFromBlack: 'rgba(0, 0, 0, 0)',

  bg: palette.white,

  // Colors
  primary: palette.purple,
  secondary: palette.salmon,
  accent: palette.orange,

  danger: palette.salmon,
  success: palette.green,

  ...palette,
}

colors.whiteHover = darken(0.05, colors.white)
colors.whiteActive = darken(0.1, colors.white)

colors.primaryHover = darken(0.05, colors.primary)
colors.primaryActive = darken(0.1, colors.primary)

// Fonts
colors.font = {
  primary: colors.primary,
  secondary: palette.doveGray,
  link: palette.blue,
  disabled: darken(0.1, palette.blue),
}

// Inputs
colors.input = {
  font: {
    color: palette.black,
    placeholder: palette.gray,
  },
  bg: {
    color: palette.white,
    active: palette.white,
    disabled: palette.lightGray,
    error: palette.white,
  },
  border: {
    color: palette.lightGray,
    active: colors.primary,
    disabled: palette.lightGray,
    error: palette.salmon,
  },
  select: {
    active: colors.primary,
    hover: lighten(0.8, colors.primary),
  },
}

colors.gradients = {
  white: {
    toLeft: `linear-gradient(to left, ${colors.white}, ${colors.clear})`,
    toRight: `linear-gradient(to right, ${colors.white}, ${colors.clear})`,
    toTop: `linear-gradient(to top, ${colors.white}, ${colors.clear})`,
    toBottom: `linear-gradient(to bottom, ${colors.white}, ${colors.clear})`,
  },
}

export default colors
