import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Box, Title } from 'Components/UI'
import { height, maxWidth } from 'styled-system'

export const Content = styled(Box).attrs({
  width: 1,
})`
  max-width: 600px;
  ${maxWidth};
`

export const BannerTitle = styled(Title).attrs({
  fontSize: [20, 3, 36],
  is: 'h2',
  mb: 3,
})`
  display: inline-block;

  color: ${themeGet('colors.white')};
  font-family: ${({ font }) => font};
  font-weight: 900;
  line-height: 1;
  text-align: left !important;
  text-shadow: #7f7f7f 2px 2px 3px;

  z-index: 1;

  ${height};
`
