import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { chevronRightGlyph } from 'Assets/Svg'

import { Flex, Text, Icon } from 'Components/UI'

const borderCss = ({ rounded }) =>
  rounded &&
  css`
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
  `

export const Container = styled(Flex)`
  background-color: ${themeGet('colors.white')};
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);

  flex-direction: column;
  position: absolute;
  top: ${themeGet('sizes.header')};
  right: 0;
  min-height: 100px;
  width: 550px;
  z-index: 90000001;

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    left: 0;
    width: 100%;
  }

  ${borderCss};
`

export const Wrapper = styled(Flex).attrs({ pt: 24 })`
  flex-direction: column;
  border-right: 1px solid ${themeGet('colors.wildSand')};
`

export const Content = styled(Flex).attrs({ mt: 3 })`
  flex-direction: column;
  max-height: 325px;
  min-width: 100px;
  overflow: auto;
  width: 100%;
`

export const SectionTitle = styled(Text).attrs({ as: 'h3', px: 24 })`
  color: ${themeGet('colors.black')};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
`

const selectedCss = ({ selected, color }) =>
  selected &&
  css`
    background-color: ${themeGet('colors.wildSand')};
    color: ${color || themeGet('colors.tealBlue')};
    fill: ${color || themeGet('colors.tealBlue')};

    p {
      font-weight: 600;
    }
  `

export const LocationItem = styled(Text).attrs({
  as: 'small',
  px: 24,
  py: 2,
  my: '2px',
})`
  color: ${themeGet('colors.boulder')};
  cursor: pointer;
  line-height: 1.3;
  user-select: none;
  max-width: 425px;

  align-items: center;
  display: flex;
  justify-content: space-between;

  border-left: ${({ selected, color }) =>
    selected ? `3px solid ${color || themeGet('colors.tealBlue')}` : 'none'};

  &:hover {
    background-color: ${themeGet('colors.wildSand')};
  }

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    max-width: calc(100vw - 125px);
  }

  ${selectedCss};
`

export const Arrow = styled(Icon).attrs({
  glyph: chevronRightGlyph,
  size: 14,
  ml: 3,
})`
  fill: ${themeGet('colors.boulder')};
  margin-top: -2px;

  ${selectedCss};
`

export const Count = styled(Text).attrs({ as: 'small', ml: 3 })`
  font-weight: bold;
`

export const Footer = styled(Flex).attrs({ px: 24 })`
  border-top: 1px solid ${themeGet('colors.wildSand')};
  color: ${({ color }) => color || themeGet('colors.tealBlue')};

  align-items: center;
  height: 60px;
  width: 100%;
`
