import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { useBaseTheme } from 'Hooks'

import { Container, MapContainer, ResultsContainer } from './styles'
import Map from '../Map'

const SearchResultMap = ({
  activeMapResult,
  children,
  innerRef,
  results,
  onActiveMarkerChange,
  ...rest
}) => {
  const { primaryColor, isLargeScreen, isSearchByServiceEnabled } =
    useBaseTheme()

  const handleMarkerClick = marker => {
    onActiveMarkerChange(marker)
  }

  const isPaginated = get(rest, 'paginated', false)

  let mobileHeightOffset = isPaginated ? '200px' : '160px'

  // Account for the search type switch if visible
  if (isSearchByServiceEnabled) {
    mobileHeightOffset = isPaginated ? '250px' : '210px'
  }

  return (
    <Container mobileoffset={mobileHeightOffset} {...rest}>
      <ResultsContainer
        activecolor={primaryColor}
        className="search-results-container"
        p={isLargeScreen ? '5px' : '0'}
        ref={innerRef}
      >
        {children}
      </ResultsContainer>
      <MapContainer>
        <Map
          activeMarker={activeMapResult}
          markers={results}
          showMarkerCard={false}
          onMarkerCardClose={() => {}}
          onMarkerClick={handleMarkerClick}
        />
      </MapContainer>
    </Container>
  )
}

SearchResultMap.defaultProps = {
  children: null,
  innerRef: null,
}

SearchResultMap.propTypes = {
  activeMapResult: PropTypes.object.isRequired,
  children: PropTypes.any,
  innerRef: PropTypes.object,
  results: PropTypes.array.isRequired,
  onActiveMarkerChange: PropTypes.func.isRequired,
}

export default SearchResultMap
