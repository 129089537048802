import styled from 'styled-components'

import { Box, Text } from 'Components/UI'

export const Container = styled(Box)`
  min-height: 100vh;
`

export const RedirectMessage = styled(Text).attrs({ as: 'span', m: 1 })``

export default {}
