import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import {
  background,
  width,
  height,
  color,
  fontSize,
  space,
  minWidth,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import { mapToTheme } from 'styled-map'
import tag from 'clean-tag'

// eslint-disable-next-line
const invertCss = ({ invert, background }) =>
  invert &&
  css`
    color: ${background || mapToTheme('buttons.bg')};
    background-color: ${mapToTheme('buttons.color')};
    border: 1px solid ${background || mapToTheme('buttons.bg')};

    &:hover {
      color: ${mapToTheme('buttons.color')};
      background-color: ${background || mapToTheme('buttons.bg')};
    }
  `

const ButtonAlt = styled(tag.button).attrs({
  type: 'button',
})`
  height: 36px;
  position: relative;
  user-select: none;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  padding: 0 16px;
  display: inline-block;
  border: none;
  font-family: Inter, sans-serif;
  font-weight: bold;
  font-size: 14px;
  z-index: 0;
  flex-shrink: 0;
  color: ${mapToTheme('buttons.color')};
  border-radius: 0;
  background: ${mapToTheme('buttons.bg')};
  transition: all ${themeGet('transitionTime')} ease;

  svg {
    position: absolute;
    left: 8px;
    top: 9px;
  }

  &:disabled {
    opacity: 0.6;
    cursor: initial;
  }

  &:hover:enabled {
    background: ${mapToTheme('buttons.hover')};
    color: ${themeGet('colors.white')};
  }

  &:active:enabled {
    background: ${mapToTheme('buttons.hover')};
    color: ${themeGet('colors.white')};
  }

  ${width};
  ${height};
  ${color};
  ${fontSize};
  ${minWidth};
  ${space};
  ${background};

  ${invertCss}
`

ButtonAlt.propTypes = {
  centered: PropTypes.bool,
  shadow: PropTypes.bool,
}

ButtonAlt.displayName = 'ButtonAlt'

export default ButtonAlt
