import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import Highlighter from 'react-highlight-words'

import {
  HeadOption,
  HeadOptionTitle,
  SecondaryOption,
  SecondaryOptionTitle,
  MapPinIconContainer,
  SecondaryOptionTitleHolder,
  Circle,
} from './styles'

function CustomOption(props) {
  const {
    baseTheme,
    innerProps,
    isFocused,
    inputValue,
    data: {
      label,
      labelIcon,
      labelIconActive,
      value,
      metadata: { type },
    },
  } = props

  if (type === 'h1') {
    return (
      <HeadOption
        background={get(baseTheme, 'colors.secondary_background')}
        focused={isFocused ? 1 : 0}
        {...innerProps}
      >
        <MapPinIconContainer
          color={get(baseTheme, 'colors.secondary_background')}
          glyph={labelIcon}
          size={16}
        />
        <HeadOptionTitle color={get(baseTheme, 'colors.secondary_background')}>
          {label}
        </HeadOptionTitle>
      </HeadOption>
    )
  }

  return (
    <SecondaryOption
      background={get(baseTheme, 'colors.secondary_background')}
      focused={isFocused ? 1 : 0}
      near={value === 'near' || value === 'online' ? 1 : 0}
      {...innerProps}
    >
      <SecondaryOptionTitleHolder>
        <SecondaryOptionTitle>
          {value === 'near' || value === 'online' ? (
            label
          ) : (
            <Highlighter
              highlightClassName="highlight-option"
              searchWords={[inputValue]}
              textToHighlight={label}
            />
          )}
        </SecondaryOptionTitle>
        {labelIcon && (
          <Circle
            background={
              labelIconActive
                ? get(baseTheme, 'colors.secondary_background')
                : 'transparent'
            }
          />
        )}
      </SecondaryOptionTitleHolder>
    </SecondaryOption>
  )
}

CustomOption.defaultProps = {
  baseTheme: {},
  inputValue: null,
  isFocused: false,
}

CustomOption.propTypes = {
  baseTheme: PropTypes.object,
  data: PropTypes.object.isRequired,
  innerProps: PropTypes.object.isRequired,
  inputValue: PropTypes.any,
  isFocused: PropTypes.bool,
}

export default CustomOption
