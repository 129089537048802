import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { renderRoutes } from 'react-router-config'

import { PublicContainer } from './styles'

class Public extends PureComponent {
  state = {}

  render() {
    const { route } = this.props
    return <PublicContainer>{renderRoutes(route.routes)}</PublicContainer>
  }
}

Public.propTypes = {
  route: PropTypes.object.isRequired,
}

export default Public
