import colors from './colors'
import buttons from './buttons'

import './Styles/fonts.css'

import baseTheme from '../baseTheme'

const theme = {
  ...baseTheme,

  name: 'Main theme',

  colors,

  buttons,
}

export default theme
