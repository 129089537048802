import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { useBaseTheme } from 'Hooks'
import { _ } from 'Services/I18n'

import { clockGlyph } from 'Assets/Svg'

import { LabelsContainer, Duration, DurationIcon, Label } from '../styles'

function Labels({ fontSize, service, ...rest }) {
  const { secondaryColor, secondaryTextColor } = useBaseTheme()

  const duration = get(service, 'duration')
  const discountPercent = get(service, 'discount_percent')
  const locationType = get(service, 'location_type', 0)
  const hasLabels = duration || locationType === 1 || discountPercent

  if (!hasLabels) {
    return null
  }

  return (
    <LabelsContainer {...rest}>
      {duration && (
        <Duration fontSize={fontSize}>
          <DurationIcon color="#52525B" glyph={clockGlyph} />
          {duration}
        </Duration>
      )}

      {locationType === 1 && (
        <Label bgcolor={secondaryColor} color={secondaryTextColor} invert={1}>
          {_('common.online')}
        </Label>
      )}

      {discountPercent ? (
        <Label
          bgcolor="linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #E85815"
          color="#E85815"
          invert={0}
        >
          {Math.round(discountPercent)}% OFF
        </Label>
      ) : null}
    </LabelsContainer>
  )
}

Labels.defaultProps = {
  fontSize: 14,
}

Labels.propTypes = {
  fontSize: PropTypes.number,
  service: PropTypes.object.isRequired,
}

export default Labels
