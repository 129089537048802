import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { renderRoutes } from 'react-router-config'
import { createStructuredSelector } from 'reselect'

import { withAppContext } from 'Services/Context'

import isEmpty from 'lodash/isEmpty'

import {
  loadFeaturedMerchants,
  loadFeaturedCategories,
  loadFeaturedLocations,
} from 'Store/Actions/marketplace'

import {
  getFeaturedMerchants,
  getFeaturedCategories,
  getFeaturedLocations,
} from 'Store/Selectors/marketplace'

import { Header, Footer } from 'Components/Blocks'

import { RootContainer } from './styles'

class Root extends PureComponent {
  static fetchData({ dispatch }) {
    return Promise.all([
      dispatch(loadFeaturedCategories({ ssr: true })),
      dispatch(loadFeaturedLocations({ ssr: true })),
      dispatch(loadFeaturedMerchants({ ssr: true })),
    ])
  }

  componentDidMount() {
    const {
      featuredCategories,
      featuredLocations,
      featuredMerchants,
      onLoadFeaturedCategories,
      onLoadFeaturedLocations,
      onLoadFeaturedMerchants,
    } = this.props

    if (isEmpty(featuredMerchants)) {
      onLoadFeaturedMerchants()
    }
    if (isEmpty(featuredCategories)) {
      onLoadFeaturedCategories()
    }
    if (isEmpty(featuredLocations)) {
      onLoadFeaturedLocations()
    }
  }

  render() {
    const { route } = this.props

    return (
      <RootContainer>
        <Header />

        {renderRoutes(route.routes)}

        <Footer />
      </RootContainer>
    )
  }
}

Root.defaultProps = {
  featuredCategories: [],
  featuredMerchants: [],
  featuredLocations: [],
}

Root.propTypes = {
  featuredCategories: PropTypes.array,
  featuredLocations: PropTypes.array,
  featuredMerchants: PropTypes.array,
  route: PropTypes.object.isRequired,
  onLoadFeaturedCategories: PropTypes.func.isRequired,
  onLoadFeaturedLocations: PropTypes.func.isRequired,
  onLoadFeaturedMerchants: PropTypes.func.isRequired,
}

const selector = createStructuredSelector({
  featuredCategories: getFeaturedCategories,
  featuredMerchants: getFeaturedMerchants,
  featuredLocations: getFeaturedLocations,
})

const actions = {
  onLoadFeaturedCategories: loadFeaturedCategories,
  onLoadFeaturedLocations: loadFeaturedLocations,
  onLoadFeaturedMerchants: loadFeaturedMerchants,
}

export default withAppContext(connect(selector, actions)(Root))
