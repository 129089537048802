import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router'

import { PUBLIC_PATHS } from 'Constants/paths'

import { Container } from './styles'

function BannerImage({ bgUrl, children }) {
  const location = useLocation()
  const isAllCategoriesPage = location.pathname === PUBLIC_PATHS.CATEGORIES

  const bannerSize = isAllCategoriesPage ? 'half' : 'two_third'

  return (
    <Container bgurl={bgUrl} size={bannerSize}>
      {children}
    </Container>
  )
}

BannerImage.defaultProps = {
  bgUrl: null,
  children: null,
}

BannerImage.propTypes = {
  bgUrl: PropTypes.string,
  children: PropTypes.any,
}

export default BannerImage
