import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import {
  background,
  width,
  height,
  color,
  fontSize,
  space,
  minWidth,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import { mapToTheme } from 'styled-map'
import tag from 'clean-tag'

const roundedCss = ({ rounded }) =>
  rounded &&
  css`
    border-radius: 9999px;
  `

const Button = styled(tag.button).attrs({
  type: 'button',
})`
  height: 48px;
  position: relative;
  user-select: none;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  padding: 0 16px;
  display: inline-block;
  border: none;
  font-family: Inter, sans-serif;
  font-weight: bold;
  font-size: 16px;
  z-index: 0;
  flex-shrink: 0;
  color: ${mapToTheme('buttons.color')};
  border-radius: 0;
  background: ${mapToTheme('buttons.bg')};
  transition: all ${themeGet('transitionTime')} ease;

  svg {
    position: absolute;
    left: 8px;
    top: 9px;
  }

  &:disabled {
    opacity: 0.6;
    cursor: initial;
  }

  &:hover:enabled {
    background: ${mapToTheme('buttons.hover')};
    color: ${themeGet('colors.white')};
  }

  &:active:enabled {
    background: ${mapToTheme('buttons.hover')};
    color: ${themeGet('colors.white')};
  }

  ${roundedCss};

  ${width};
  ${height};
  ${color};
  ${fontSize};
  ${minWidth};
  ${space};
  ${background};
`

Button.propTypes = {
  centered: PropTypes.bool,
  shadow: PropTypes.bool,
}

Button.displayName = 'Button'

export default Button
