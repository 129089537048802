import styled, { css } from 'styled-components'
import { width, space, fontSize } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { searchGlyph, closeGlyph } from 'Assets/Svg'

import Select from 'react-select'

import Icon from '../Icon'
import Box from '../Box'

const borderRadiusCss = ({ roundedborder }) => {
  const borderRadius = roundedborder
    ? themeGet('borderRadius.2')
    : themeGet('borderRadius.1')

  const mobileBorderRadius = roundedborder ? '18px' : themeGet('borderRadius.1')

  return css`
    border-radius: ${borderRadius};
    &--menu-is-open {
      border-radius: ${borderRadius}${' '}${borderRadius} 0 0 !important;

      @media screen and (max-width: 769px) {
        border-radius: ${mobileBorderRadius}${' '}${mobileBorderRadius} 0 0 !important;
      }
    }
    .react-select {
      &__menu {
        border-radius: 0 0 ${borderRadius}${' '}${borderRadius} !important;
      }
    }
  `
}

const errorCss = props =>
  props.error &&
  css`
    border-color: ${themeGet('colors.error')};
  `

export const Container = styled(Box)`
  position: relative;
  width: 100%;
`

export const ClearIcon = styled(Icon).attrs({
  glyph: closeGlyph,
  size: 14,
  display: ['none', 'block'],
})`
  cursor: pointer;
  fill: ${themeGet('colors.gray')};
  position: absolute;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: -2px;
  z-index: 1;
`

export const SearchIcon = styled(Icon).attrs(props => ({
  glyph: props.customglyph ?? searchGlyph,
  size: 16,
  display: ['none', 'none', 'none', 'block'],
}))`
  fill: ${({ fill }) => fill || themeGet('colors.tealBlue')};
  position: absolute;
  left: 23px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`

export const StyledSelect = styled(Select).attrs({
  fontSize: ['14px', 2, '14px'],
})`
  border-radius: 4px;
  border: 0;
  background: ${themeGet('colors.input.bg.color')};
  font-family: ${themeGet('font')};

  &:active,
  &:focus-within {
    border-color: ${themeGet('colors.input.border.active')};
    background: ${themeGet('colors.input.bg.active')};
  }

  ${errorCss};
  ${borderRadiusCss};

  .react-select {
    &__container {
      margin: 0;
      width: 100%;
      outline: none;
      position: relative;
      appearance: none;
      flex-shrink: 0;
      color: ${themeGet('colors.input.font.color')};
    }

    &__input {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 33px;
      color: ${themeGet('colors.header')};
      font-size: 14px;
      font-weight: 500;

      > input {
        width: 100% !important; // override inline width
      }

      @media screen and (max-width: ${themeGet('breakpoints.0')}) {
        padding-left: 0;
      }
    }

    &__value-container {
      padding-left: 23px;
      padding-right: 16px;
      font-weight: 500;
      color: ${themeGet('colors.header')};

      > div {
        width: 100%;
      }
    }

    &__control {
      background-color: ${themeGet('colors.input.bg.color')};
      border-color: ${themeGet('colors.lightGrey')} !important;
      box-shadow: none;
      border-radius: 4px;
      color: ${themeGet('colors.input.font.placeholder')};

      ${borderRadiusCss};

      &--menu-is-open {
        svg {
          transform: rotate(180deg) !important;
        }
      }

      &--is-focused,
      &--is-focused:hover {
        outline-color: transparent;
      }
    }

    &__indicator-separator {
      background-color: transparent;
    }

    &__indicators {
      margin-right: 10px;

      svg {
        transform: rotate(0deg);
        transition: 0.2s ease transform;
      }
    }

    &__placeholder {
      margin-left: 36px;
      color: ${themeGet('colors.frenchGray')};
      font-size: 14px;
      font-weight: 500;
      transition: opacity 200ms;

      @media screen and (max-width: ${themeGet('breakpoints.2')}) {
        margin-left: 2px;
        padding-top: 0;
      }
    }

    &__menu {
      position: absolute;
      margin-top: 0;
      background-color: white;
      border-radius: 0;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      font-family: ${themeGet('font')};

      padding-bottom: 20px;

      &-list {
        color: ${themeGet('colors.input.font.color')};
      }
    }

    &__single-value {
      margin-left: 30px;
      padding-top: 2px;
    }

    &__option {
      cursor: pointer;
      color: #868788;
      font-size: 14px;
      font-weight: 500;

      &--is-focused,
      &--is-focused:active {
        color: white;
        background-color: ${themeGet('colors.input.select.hover')};
        background-color: ${props =>
          props.color ? props.color : themeGet('colors.tealBlue')};
      }

      &--is-selected,
      &--is-selected:active {
        color: white;
        background-color: ${themeGet('colors.input.select.active')};
        background-color: ${props =>
          props.color ? props.color : themeGet('colors.tealBlue')};
      }
    }
  }

  @media screen and (max-width: ${themeGet('breakpoints.2')}) {
    .react-select {
      &__single-value {
        margin-top: 1px;
      }
      &__control {
        box-shadow: unset;
      }
      &__single-value {
        margin-left: 0px;
        padding-top: 0;
      }
      &__placeholder {
        font-size: 14px;
      }
      &__value-container {
        padding-left: 23px;
      }
      &__input {
        padding-left: 0;

        input {
          margin: 0 auto;
        }
      }
    }
  }

  ${fontSize};
  ${space};
  ${width};
`

export default {}
