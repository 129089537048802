import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import LazyLoad from 'react-lazyload'

import { _ } from 'Services/I18n'

import {
  DescriptionContainer,
  Description as DescriptionHolder,
  LinkButton,
} from '../styles'

function Description({ description, showExpand, truncateLines, ...rest }) {
  if (!description) return null

  const [useDescriptionEllipsis, setUseDescriptionEllipsis] = useState(true)
  const [clamped, setClamped] = useState(false)

  const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis)

  const handleReadMore = e => {
    e.preventDefault()
    e.stopPropagation()

    setUseDescriptionEllipsis(!useDescriptionEllipsis)
  }

  const handleReflow = rleState => {
    setClamped(rleState.clamped)
  }

  const renderTruncatedDescription = useMemo(() => {
    return (
      <LazyLoad>
        <ResponsiveEllipsis
          basedOn="letters"
          maxLine={truncateLines}
          unsafeHTML={description}
          onReflow={handleReflow}
        />
      </LazyLoad>
    )
  }, [description, truncateLines])

  return (
    <DescriptionContainer className="description-container" {...rest}>
      {useDescriptionEllipsis ? (
        renderTruncatedDescription
      ) : (
        <DescriptionHolder dangerouslySetInnerHTML={{ __html: description }} />
      )}

      {showExpand && (
        <>
          {clamped && useDescriptionEllipsis ? (
            <LinkButton onClick={handleReadMore}>
              {_('common.showMore')}
            </LinkButton>
          ) : (
            <>
              {!useDescriptionEllipsis ? (
                <LinkButton onClick={handleReadMore}>
                  {_('common.showLess')}
                </LinkButton>
              ) : null}
            </>
          )}
        </>
      )}
    </DescriptionContainer>
  )
}

Description.defaultProps = {
  description: '',
  showExpand: true,
  truncateLines: 5,
}

Description.propTypes = {
  description: PropTypes.string,
  showExpand: PropTypes.bool,
  truncateLines: PropTypes.number,
}

export default Description
